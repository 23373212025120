import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { ArrowRight, Global, Notification } from "../../assets/images";
import { Switch } from "@mui/material";
import MainButton from "../../components/MainButton/MainButton";
import BottomSheet from "../../components/BottomSheet/BottomSheet";
import useToken from "../../hooks/useToken";
import useRole from "../../hooks/useRole";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import useUserId from "../../hooks/useUserId";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import { useNotify } from "../../hooks/useNotify";

let data = [
  { id: 1, title: "Русский", ids: "ru" },
  { id: 2, title: "O'zbekcha", ids: "uz" },
  { id: 3, title: "English", ids: "en" },
];

export default function Setting() {
  const [isChecked, setIsChecked] = useState(false);

  const [isClicked, setIsClicked] = useState(false);

  const [userId] = useUserId();

  const [token] = useToken();

  const notify = useNotify();

  const { t } = useTranslation();

  const [setToken] = useToken(true);

  const [setRole] = useRole(true);

  const [lang, setLang] = useState(
    data.find((item) => item.ids === window.localStorage.getItem("lang"))
  );

  const handleClick = (el, e) => {
    setLang(el);

    i18next.changeLanguage(el.ids);

    window.localStorage.setItem("lang", el.ids);
    axios
      .put(
        baseUrl + "/user/update",
        { id: userId, language: el.ids.toUpperCase() },
        {
          headers: {
            Authorization: "mme:" + token,
          },
        }
      )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));

    setIsClicked(false);
  };

  const handleLogout = () => {
    setToken();
    setRole();
    window.localStorage.clear();
    i18next.changeLanguage("uz");
  };

  const userjon = useFetch(baseUrl + "/user/" + userId);

  useEffect(() => {
    if (userjon) {
      setIsChecked(userjon?.notification);
    }
  }, [userjon]);

  const handleChange = (e) => {
    setIsChecked(e.target.checked);

    axios
      .put(
        baseUrl + "/user/update",
        { id: userId, notification: e.target.checked },
        {
          headers: {
            Authorization: "mme:" + token,
          },
        }
      )
      .then((res) => {
        if (res?.data?.data?.id && e.target.checked) {
          notify("Notification enabled", 200);
        } else {
          notify("Notification disabled", 200);
        }
      });
  };

  return (
    <div className="flex flex-col h-screen bg-white">
      <Header
        heading={t("profile.settings.heading")}
        icon={true}
        navPath="/profile"
      ></Header>
      <div className="grow flex-col flex justify-between pb-8 px-4 pt-2">
        <ul className="">
          <li
            className="flex justify-between items-center pt-8 pb-6 border-b border-[#D7E1EA] pr-3"
            onClick={() => setIsClicked(!isClicked)}
          >
            <div className="flex">
              <img className="mr-4" src={Global} alt="Just an icon" />{" "}
              <span className="text-textDark font-medium opacity-80">
                {t("profile.settings.lang")}
              </span>
            </div>
            <img src={ArrowRight} alt="" />
          </li>
          <li className="flex justify-between items-center pt-8 pb-6 border-b border-[#D7E1EA]">
            <div className="flex">
              <img className="mr-4" src={Notification} alt="Just an icon" />{" "}
              <span className="text-textDark font-medium opacity-80">
                {t("profile.settings.notification")}
              </span>
            </div>
            <Switch checked={isChecked || false} onChange={handleChange} />
          </li>
        </ul>
        <MainButton bgColor="#E24B4B" lang={lang} handleClick={handleLogout}>
          {t("profile.settings.btn")}
        </MainButton>
      </div>
      <BottomSheet
        lang={lang}
        handleClick={handleClick}
        data={data}
        isClicked={isClicked}
        setIsClicked={setIsClicked}
      ></BottomSheet>
    </div>
  );
}
