import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../baseUrl";
import useFetch from "../../hooks/useFetch";
import ButtonToggle from "../../components/ButtonToggle/ButtonToggle";
import useAlert from "../../hooks/useAlert";
import useToken from "../../hooks/useToken";
import useUserId from "../../hooks/useUserId";
import axios from "axios";
import MainInput from "../../components/MainInput/MainInput";
import MainButton from "../../components/MainButton/MainButton";
import {
  Calender,
  CallSvg,
  Report,
  Dollar,
  Clock,
  Users,
  Filter,
} from "../../assets/images";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { formatUzbekPhoneNumber } from "../../utils/formatNumber";
import { useTranslation } from "react-i18next";
import { formattedDate } from "../../utils/getDatePickerDate";
import DatePickerInput from "../../components/DatePicker/DatePicker";
import { getDatePickerDate } from "../../utils/getDatePickerDate";
import useLoading from "../../hooks/useLoading";

export default function PartnerPage() {
  const { id } = useParams();

  const navigate = useNavigate();

  const [partners, refetch] = useFetch(baseUrl + "/partner/debts/all", true);

  const [offset, setOffset] = useState(0);

  const [_, setIsLoading] = useLoading()

  const [start, setStart] = useState(formattedDate);
  const [end, setEnd] = useState(formattedDate);

  const { t } = useTranslation();

  const limit = 50;

  const histories = useFetch(
    baseUrl +
      `/partner/reports?partner_id=${id}&from=${getDatePickerDate(
        start
      )}&to=${getDatePickerDate(end)}&limit=${limit}&offset=${offset}`
  );

  const [isClicked, setIsClicked] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isFilterClicked, setIsFilterClicked] = useState(false);

  const [showAlert] = useAlert();

  const [userId] = useUserId();

  const [token] = useToken();

  let partner = partners.find((el) => el?.id === id);


  const handleSubmit = (e) => {
    e.preventDefault();

    const { money_debt } = e.target.elements;

    const newDebt = {
      user_id: userId,
      given_by_whom: "WAREHOUSE",
      partner_id: partner?.id,
      _money_: +money_debt.value,
    };

    if (money_debt.value) {
      setIsLoading(true)
      axios
        .post(baseUrl + "/partner/create-debt", newDebt, {
          method: "POST",
          headers: {
            Authorization: `mme:${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data?.data?.id) {
            setIsLoading(false)
            showAlert(
              "Muvafaqqiyatli!",
              "Qarz muvafaqqiyatli qo'shildi!",
              "success"
            );
            refetch();
            money_debt.value = null;
            setIsClicked(false);
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
        });
    }
  };

  const handleClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsFilterClicked(false);
    } else {
      setIsFilterClicked(true);
    }
  };

  const handleModalClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div className="bg-white flex flex-col gap-y-5 h-screen">
      <div
        className="pb-4 px-4 bg-white pt-10 relative"
        style={{ boxShadow: "0px 12px 14px rgba(209, 212, 226, 0.157261)" }}
      >
        <div className="text-center mb-10 relative">
          <span
            className="h-8 w-8 cursor-pointer rounded-full text-lg text-[#354B91]  top-1/2 -translate-y-1/2 left-1 border border-[#354B91] flex justify-center items-center absolute"
            onClick={() => navigate(-1)}
          >
            &#10094;
          </span>
          <h1 className="text-xl text-brendColor">
            <span>{partner?.partner_name}</span>
          </h1>

          <img
            className="absolute right-2 top-1.5 cursor-pointer"
            src={Filter}
            alt="Just an icon"
            onClick={() => setIsFilterClicked(true)}
          />
        </div>
      </div>
      <div className="px-4 grow bg-white">
        <ButtonToggle
          toggle1={"Hamkor haqida"}
          toggle2={"To'lov tarixi"}
          isClicked={isClicked}
          setIsClicked={setIsClicked}
        ></ButtonToggle>
        {!isClicked ? (
          <>
            <ul className="border border-[#E6E6EA] p-4 flex flex-col gap-y-6 rounded-main mt-6">
              <li className="flex items-center gap-x-2">
                <img className="brend_svg" src={Calender} alt="Just an icon" />
                <div className="flex flex-col gap-y-1">
                  <span className="text-textSilver">Kiritilgan sana:</span>
                  <Moment
                    className="font-semibold"
                    format="YYYY/MM/DD"
                  ></Moment>
                </div>
              </li>
              <li className="flex items-center gap-x-2">
                <img className="brend_svg" src={CallSvg} alt="Just an icon" />
                <div className="flex flex-col gap-y-1">
                  <span className="text-textSilver">Telefon raqami:</span>
                  <span className="font-semibold">
                    {formatUzbekPhoneNumber(partner?.partner_phone)}
                  </span>
                </div>
              </li>
              <li className="flex items-center gap-x-2">
                <img className="brend_svg" src={Report} alt="Just an icon" />
                <div className="flex flex-col gap-y-1">
                  <span className="text-textSilver">
                    Kelishilgan pul birligi:
                  </span>
                  <span className="font-semibold">
                    {partner?.selected_currency}
                  </span>
                </div>
              </li>
              <li className="flex items-center gap-x-2">
                <img className="brend_svg" src={Dollar} alt="Just an icon" />
                <div className="flex flex-col gap-y-1">
                  <span className="text-textSilver">Bizning qarzmiz:</span>
                  <span className="font-semibold">{partner?._money_}</span>
                </div>
              </li>
              <li className="flex items-center gap-x-2">
                <img className="brend_svg" src={Clock} alt="Just an icon" />
                <div className="flex flex-col gap-y-1">
                  <span className="text-textSilver">So’ngi to’lov:</span>
                  <span className="font-semibold">
                    {partner?.last_payment_data?.last_given_money}
                  </span>
                </div>
              </li>
              <li className="flex items-center gap-x-2">
                <img className="brend_svg" src={Users} alt="Just an icon" />
                <div className="flex flex-col gap-y-1">
                  <span className="text-textSilver">Kim to’lagan:</span>
                  <span className="font-semibold">{partner?.partner_name}</span>
                </div>
              </li>
            </ul>
            <div className="flex gap-x-4 mt-6">
              <button
                className="bg-brendColor py-3 w-1/2 rounded-md text-white"
                onClick={() => setIsModalOpen(true)}
              >
                Qarz qo'shish
              </button>
              <button
                className="bg-textSilver py-3 w-1/2 rounded-md text-white"
                onClick={() => navigate("/closePartnerDebt")}
              >
                Qarz yopish
              </button>
            </div>
          </>
        ) : (
          <ul className="flex flex-col gap-y-6 mt-6">
            {Object.entries(histories?.data || {}).length &&
              Object.entries(histories?.data || {}).map((el, id) => (
                <li key={id}>
                  <time className="font-medium text-mainText mb-2 block">
                    {el[0]}
                  </time>
                  <ul className="flex flex-col gap-y-2">
                    {el[1]?.length &&
                      el[1].map((it) => (
                        <li
                          className="p-4"
                          key={it?.id}
                          style={{
                            boxShadow:
                              "0px 3px 15px 0px rgba(13, 14, 43, 0.05)",
                          }}
                        >
                          <p className="mb-4">
                            <span className="text-textSilver font-medium mr-1">
                              Xodim:
                            </span>{" "}
                            <span className="font-medium">{it?.name}</span>
                          </p>
                          <div className="flex justify-between items-center">
                            <time className="text-sm text-textSilver font-medium">
                              {it?.time}
                            </time>
                            <span
                              className={`${
                                it?._money_ > 0
                                  ? "text-[#29C061]"
                                  : "text-[#DD3439]"
                              } text-lg font-medium`}
                            >
                              {it?._money_} {partner?.selected_currency}
                            </span>
                          </div>
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
          </ul>
        )}
      </div>

      <div
        className={`${
          isModalOpen
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed bottom-0 min-[500px]:left-1/2 left-0 ease-out min-[500px]:-translate-x-1/2 right-0 bg-black bg-opacity-30 max-w-[500px] w-full overscroll-none flex items-end z-50 max-h-screen transition delay-150 duration-300`}
        onClick={handleModalClick}
        id="modal_wrapper"
      >
        <form
          className={`${
            isModalOpen ? "translate-y-0" : "translate-y-full"
          } px-4 pt-6 pb-12 ease-in-out bg-white w-full transition-all delay-75 duration-300`}
          onSubmit={handleSubmit}
        >
          <h4 className="text-lg text-brendColor text-center mb-7">
            Qarz qo'shish
          </h4>
          <div className="flex justify-between items-center mb-4">
            <p className="text-base font-medium">
              <span className="text-textSilver  mr-1.5">Ism:</span>
              <span className=" text-textDark capitalize">
                {partner?.partner_name}
              </span>
            </p>
            <p className="text-base font-medium">
              <span className="text-textDark mr-1.5">{partner?._money_}</span>
              <span className="text-mainText">
                {partner?.selected_currency}
              </span>
            </p>
          </div>
          <MainInput
            type="number"
            margin={"16px"}
            name={"money_debt"}
          ></MainInput>
          <span className="text-sm font-medium text-textSilver block mb-20">
            Kiritilgan pulga e’tiborli bo’ling!
          </span>
          <MainButton bgColor={"#702DE3"}>Saqlash</MainButton>
        </form>
      </div>

      <div
        className={`${
          isFilterClicked
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed transition delay-150 duration-300 z-10 bottom-0 min-[500px]:left-1/2 min-[500px]:-translate-x-1/2 max-w-[500px] w-full left-0 right-0 flex justify-center items-end`}
        id="modal_wrapper"
        onClick={handleClick}
      >
        <div
          className={`${
            isFilterClicked ? "translate-y-0" : "translate-y-full"
          } bg-white w-full py-5 px-4 pt-6 transition-all delay-75 ease-in-out h-3/4 overflow-y-auto flex flex-col justify-between pb-3`}
        >
          <div className="flex flex-col justify-between">
            <p className="text-lg text-brendColor font-semibold text-center mb-8">
              {t("gipyurExpenses.filter.title")}
            </p>
            <div className="flex justify-between">
              <span className="grow text-textDark">
                {t("gipyurExpenses.filter.start")}
              </span>
              <span className="grow text-textDark">
                {t("gipyurExpenses.filter.end")}
              </span>
            </div>
            <DatePickerInput
              filter={true}
              start={start}
              end={end}
              setStart={setStart}
              setEnd={setEnd}
              formattedDate={formattedDate}
            ></DatePickerInput>
          </div>
          <MainButton handleClick={() => setIsClicked(false)} type={"button"}>
            {t("gipyurExpenses.filter.sort")}
          </MainButton>
        </div>
      </div>
    </div>
  );
}
