import { useEffect, useState } from "react";
import axios from "axios";
import useToken from "./useToken";

const useFetch = (url, only = false) => {
  const [token, setToken] = useToken();
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await axios.get(url, {
        method: "GET",
        headers: {
          Authorization: `mme:${token}`,
          "Content-Type": "applicaton/json",
        },
      });

      setData(res?.data);

      return true;
    } catch (error) {
      if (
        error.response.data.message === "Unauthorized" &&
        error.response.data.code === 401
      ) {
        window.localStorage.clear();
        setToken();
      }
    }
  };

  useEffect(() => {
    if (url) {
      fetchData();
    }
  }, [url]);

  return !only ? data : [data, fetchData];
};

export default useFetch;
