import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { CameraPh } from "../../assets/images";
import useToken from "../../hooks/useToken";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import MainInput from "../../components/MainInput/MainInput";
import MainButton from "../../components/MainButton/MainButton";
import useUserId from "../../hooks/useUserId";
import { useNavigate } from "react-router-dom";
import ButtonSheetSimple from "../../components/BottomSheet/BottomSheetSimple";
import useFetch from "../../hooks/useFetch";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

const shlefData = [
  { id: 1, title: 30, tag: "sm" },
  { id: 2, title: 60, tag: "sm" },
  { id: 3, title: 80, tag: "sm" },
  { id: 4, title: 100, tag: "sm" },
  { id: 5, title: 150, tag: "sm" },
  { id: 6, title: 200, tag: "sm" },
];

export default function AddDress() {
  const [token] = useToken();

  const [_, setIsLoading] = useLoading();

  const { t } = useTranslation();

  const [userId] = useUserId();

  const [showAlert] = useAlert();

  const navigate = useNavigate();

  const [images, setImages] = useState([]);

  const [selectedImages, setSelectedImages] = useState([]);

  const dressColors = useFetch(baseUrl + "/dress/colors");

  let validColors = dressColors?.map((el, id) => {
    return {
      id,
      title: el?.dress_color,
    };
  });

  const handleChange = (e) => {
    let files = e.target.files;

    setImages([...images, ...files]);

    let formData = new FormData();

    if (files?.length > 1) {
      for (let val of files) {
        formData.append("file", val);

        try {
          axios
            .post(baseUrl + "/file-upload", formData, {
              method: "POST",
              headers: {
                Authorization: `mme:${token}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res.status === 201) {
                if (res.data?.image?.length > 1) {
                  setSelectedImages([...selectedImages, ...res.data?.image]);
                } else {
                  setSelectedImages([...selectedImages, ...res.data?.image]);
                }
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      formData.append("file", e.target.files[0]);

      try {
        axios
          .post(baseUrl + "/file-upload", formData, {
            method: "POST",
            headers: {
              Authorization: `mme:${token}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status === 201) {
              setSelectedImages([...selectedImages, res.data?.image]);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      dress_name,
      dress_price,
      description,
      dress_shleft_size,
      dress_color,
    } = e.target.elements;

    let newDress = {
      created_by_user: userId,
      dress_image: selectedImages,
      dress_name: dress_name?.value,
      dress_price: +dress_price?.value,
      description: description?.value || "",
      dress_shleft_size: +dress_shleft_size.value,
      dress_color: dress_color.value,
    };

    if (Object.values(newDress).length >= 5) {
      setIsLoading(true);
      axios
        .post(baseUrl + "/dress/create", newDress, {
          method: "POST",
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res.data?.data?.id) {
            setIsLoading(false);
            showAlert(
              "Muvafaqqiyatli !",
              "Yangi ko'ylak qo'shildi.",
              "success"
            );
            navigate(-1);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          showAlert("Xatolik !", "Qaytadan urinib ko'ring.", "error");
        });
    }
  };

  return (
    <div className="flex flex-col bg-white h-screen">
      <Header
        heading={t("pages.addNewDress")}
        navPath={-1}
        icon={true}
      ></Header>
      <form
        className="pt-6 px-4 flex-grow flex flex-col justify-between pb-7"
        onSubmit={handleSubmit}
      >
        <div className="">
          <div className="mb-4">
            <span className="text-textDark mb-2 block">
              {t("bookingPage.form.addDressImage")}
            </span>
            <div className="flex gap-2 items-center flex-wrap">
              {images.length > 0 ? (
                images.map((el, id) => (
                  <div
                    className="w-20 h-[70px] border-dashed border rounded-md border-[#D7E1EA] flex justify-center items-center"
                    key={id}
                  >
                    <img
                      className="w-full h-full object-cover rounded-md"
                      width={80}
                      height={70}
                      src={URL.createObjectURL(el)}
                      alt="Just an icon"
                    />
                  </div>
                ))
              ) : (
                <div className="w-20 h-[70px] border-dashed border rounded-md border-[#D7E1EA] flex justify-center items-center">
                  <img src={CameraPh} alt="Just an icon" />
                </div>
              )}
              <label className="w-20 h-[70px] border-dashed border rounded-md border-[#D7E1EA] flex justify-center items-center">
                <input
                  className="hidden"
                  type="file"
                  onChange={handleChange}
                  multiple
                  accept="image/*"
                />
                <span className="text-3xl text-brendColor">+</span>
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between mb-4 gap-x-4">
            <MainInput
              type="text"
              title={t("bookingPage.form.dressName")}
              name={"dress_name"}
              required={true}
            ></MainInput>
            <MainInput
              type="number"
              title={t("addNewProduct.productPrice")}
              name={"dress_price"}
              required={true}
            ></MainInput>
          </div>
          <div className="flex items-center justify-between mb-4 gap-x-4">
            <ButtonSheetSimple
              title={t("ordersPage.card.shleft")}
              select={true}
              data={shlefData}
              name1={"dress_shleft_size"}
            ></ButtonSheetSimple>
            <ButtonSheetSimple
              title={t("ordersPage.card.color")}
              select={true}
              data={validColors}
              name1={"dress_color"}
            ></ButtonSheetSimple>
          </div>
          <label className="">
            <span className="text-textSilver block mb-1.5">
              {t("bookingPage.form.dressDesc")}
            </span>
            <textarea
              className="w-full max-h-[140px] h-full outline-none p-3.5 border border-[#D7E1EA] rounded-md"
              name="description"
            ></textarea>
          </label>
        </div>
        <MainButton type={"submit"}>{t("submit")}</MainButton>
      </form>
    </div>
  );
}
