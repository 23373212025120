import React from "react";
import Header from "../../components/Header/Header";
import MainInput from "../../components/MainInput/MainInput";
import useRole from "../../hooks/useRole";
import MainButton from "../../components/MainButton/MainButton";
import useUserId from "../../hooks/useUserId";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

export default function EditProfile() {
  const [userRole] = useRole();

  const [userId] = useUserId();

  const [token] = useToken();

  const [_, setIsLoading] = useLoading();

  const [showAlert] = useAlert();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const user = useFetch(baseUrl + "/user/" + userId);

  const handleSubmit = (e) => {
    e.preventDefault();

    let { name, surname, phone } = e.target.elements;

    let updatedUser = {
      id: userId,
      language: null,
      name: name?.value || user?.name,
      surname: surname?.value || user?.surname,
      phone: phone?.value || user?.phone,
    };

    if (
      (name.value !== user?.name ||
        surname.value !== user?.surname ||
        phone.value !== user?.phone) &&
      name.value
    ) {
      setIsLoading(true);
      axios
        .put(baseUrl + "/user/update", updatedUser, {
          method: "PUT",
          headers: {
            Authorization: `mme:${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data?.data?.id) {
            setIsLoading(false);
            showAlert(
              "Muvaffaqiyatli!",
              "Jarayon muvaffaqiyatli yakunlandi!",
              "success"
            );
            navigate("/profile");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          showAlert("Xatolik !", "Xatolik yuz berdi!", "error");
        });
    } else {
      setIsLoading(false);
      showAlert("Ogohlantirish!", "Qaytadan tekshirib ko'ring", "warning");
    }
  };

  return (
    <div className="h-screen flex flex-col">
      <Header
        icon={true}
        heading={t("profile.edit.heading")}
        navPath="/profile"
      ></Header>
      <form
        className="px-4 pt-5 bg-white grow flex flex-col justify-between pb-8"
        onSubmit={handleSubmit}
      >
        <div>
          <MainInput
            title={t("profile.edit.name")}
            margin={"20px"}
            name={"name"}
            value={user?.name}
          ></MainInput>
          <MainInput
            title={t("profile.edit.surname")}
            margin={"20px"}
            name={"surname"}
            value={user?.surname}
          ></MainInput>
          <MainInput
            title={t("profile.edit.phone")}
            margin={"20px"}
            name={"phone"}
            value={user?.phone}
            type="tel"
            placeholder={"9989*******"}
          ></MainInput>
          <MainInput
            title={t("profile.edit.position")}
            type="tel"
            value={userRole?.role}
            readOnly={true}
          ></MainInput>
        </div>

        <MainButton type={"submit"}>{t("submit")}</MainButton>
      </form>
    </div>
  );
}
