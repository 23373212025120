import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/normalize.css";
import "./index.css";
import "./i18n.js";
import App from "./App";
import { Provider as ToastProvider } from "./context/toastContext";
import { Provider as AutenticationProvider } from "./context/Autentication";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AutenticationProvider>
      <ToastProvider>
        <App />
      </ToastProvider>
    </AutenticationProvider>
  </BrowserRouter>
);
