import React from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import useRole from "../../hooks/useRole";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { baseUrl } from "../../baseUrl";
import { useTranslation } from "react-i18next";

export default function Debtor2({ data, handleClick }) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [role] = useRole();

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };
  return (
    <li
      className="w-full pt-4 pb-6 px-4 rounded-main bg-white"
      key={data?.id}
      style={{ boxShadow: "0px 3px 15px rgba(13, 14, 43, 0.05)" }}
      onClick={(e) => {
        handleClick(e, data);
        navigate(`sale5050_${data?.id}_${role?.status === 1 ? "btn" : ""}`);
      }}
    >
      <div className="flex items-center">
        <LazyLoadImage
          className="w-16 h-16 object-cover rounded-full mr-3.5"
          src={baseUrl + data?.salon_image}
          alt="Just an icon"
          onError={handleImageError}
        />
        <div>
          <p>
            <span className="text-base text-textSilver font-medium mr-2.5">
              {t("debtorsPage.salon")}:
            </span>
            <span className="text-base text-textDark capitalize">
              {data?.title}
            </span>
          </p>
          <p>
            <span className="text-base text-textSilver font-medium mr-2.5">
              {t("debtorsPage.bride")}:
            </span>
            <span className="text-base text-textDark">
              {data?.bride_name}
              <span className="text-mainText font-medium text-base ml-1.5">
                USD
              </span>
            </span>
          </p>
          <p>
            <span className="text-base text-textSilver font-medium mr-2.5">
              {t("debtorsPage.paymentDate")}:
            </span>
            <span className="text-base text-textDark">
              <Moment format="YYYY/MM/DD">{data?.date_gives_debt}</Moment>
            </span>
          </p>
        </div>
      </div>
      <span className="h-px bg-[#E6E6EA] w-full my-2 block"></span>
      <div className="flex justify-between items-center">
        <p>
          <span className="text-base text-textSilver font-medium mr-2.5">
            {t("debtorsPage.bride")}:
          </span>
          <span className="text-base text-textDark capitalize">
            {data?.bride_debt}
          </span>
        </p>
        <p>
          <span className="text-base text-textSilver font-medium mr-2.5">
            Salon:
          </span>
          <span className="text-base text-textDark capitalize">
            {data?.salon_debt}
          </span>
        </p>
      </div>
    </li>
  );
}
