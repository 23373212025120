import React from "react";
import Header from "../../components/Header/Header";
import ReportsList from "../../components/ReportsList/ReportsList";
import { ShoppingBag, OrdersList } from "../../assets/images";
import { useTranslation } from "react-i18next";

export default function SellGipyur() {
  const { t } = useTranslation();
  const data = [
    {
      id: 1,
      title: t("gipyurSelling.simpleSelling.heading"),
      icon: ShoppingBag,
      path: "/sellSimple",
    },
    {
      id: 2,
      title: t("gipyurSelling.sellingForDebt.heading"),
      icon: OrdersList,
      path: "/sellForDebt",
    },
  ];
  return (
    <div className="bg-white flex flex-col h-screen">
      <Header
        heading={t("pages.gipyurSelling")}
        icon={true}
        navPath={-1}
      ></Header>
      <div className="px-4 flex-grow">
        <ReportsList data={data}></ReportsList>
      </div>
    </div>
  );
}
