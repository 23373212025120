import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useTranslation } from "react-i18next";
import { Doughnut } from "react-chartjs-2";
import { formatNumber } from "../../utils/formatNumber";

ChartJS.register(ArcElement, Tooltip, Legend);

function DoughnutChart({ chartData, status, total }) {
  const { t } = useTranslation();

  const data = {
    labels: [],
    datasets: [
      {
        label: [],
        data: [...chartData.map((el) => el.count)],
        backgroundColor: [...chartData.map((el) => el.color)],
        borderColor: [...chartData.map((el) => el.color)],
        borderWidth: 0,
        cutout: "75%",
      },
    ],
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const {
        ctx,
        chartArea: { top, width, height },
      } = chart;

      ctx.save();
      ctx.font = "bolder 18px san-serif";
      ctx.fillStyle = "#090A2D";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        status === "salon" ? t("home.total") : t("home.totalOrders"),
        width / 2,
        height / 2
      );
      ctx.restore();

      ctx.font = "bold 24px san-serif";
      ctx.fillStyle = "#702DE3";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(formatNumber(total), width / 2, height / 2 + top + 25);
    },
  };

  return (
    <Doughnut
      key={total}
      data={data}
      width={300}
      height={300}
      plugins={[textCenter]}
    ></Doughnut>
  );
}

export default DoughnutChart;
