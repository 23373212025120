const changeBorderColor = (el) => (el.style.borderColor = "red");
export function validateInput(target, input1, input2, callback) {
  let valSalon = +input1.element.value;
  let valKelin = +input2.element.value;
  const changeColor = (el) => (el.element.style.borderColor = "red");

  if (!valKelin && !valSalon) {
    changeColor(input1);
    changeColor(input2);
    callback(
      "Ogohlantirish",
      "Salon va Kelin ko'ylak uchun pul berishmadi!",
      "warning"
    );
  }

  if (target !== valSalon + valKelin) {
    callback(
      "Ogohlantirish",
      `Salon va kelin tomonidan beriladigan pul ko'ylak narxiga teng bo'lishi shart... \n Berilishi kerak: ${target}, \n Berildi: ${
        valSalon + valKelin
      }`,
      "warning"
    );
    changeColor(input1);
    changeColor(input2);
    return true;
  }

  return false;
}

export const validateInputWithCurrency = (
  target,
  usd,
  uzs,
  currency,
  callback
) => {
  if (usd.value && uzs.value) {
    let comparision = +usd.value + uzs.value / currency;

    if (comparision > target) {
      usd.style.borderColor = "red";
      uzs.style.borderColor = "red";
      callback(
        "Ogohlantirish !",
        `Belgilangan narxdan ortiqcha pul miqdorlari kiritild ! \n Belgilangan: ${target} usd; \n Kiritildi: ${comparision.toFixed(
          5
        )}`,
        "warning"
      );
      return true;
    } else if (comparision < target) {
      usd.style.borderColor = "red";
      uzs.style.borderColor = "red";
      callback(
        "Ogohlantirish !",
        `Belgilangan narxdan kam pul miqdorlari kiritild ! \n Belgilangan: ${target} usd; \n Kiritildi: ${comparision.toFixed(
          5
        )}`,
        "warning"
      );
      return true;
    }
  }

  if (usd.value && !uzs.value) {
    if (usd.value > target) {
      usd.style.borderColor = "red";
      callback(
        "Ogohlantirish !",
        `Belgilangan narxdan ko'p pul miqdorlari kiritild ! \n Belgilangan: ${target} usd; \n Kiritildi: ${usd.value}`,
        "warning"
      );
      return true;
    } else if (usd.value < target) {
      usd.style.borderColor = "red";
      callback(
        "Ogohlantirish !",
        `Belgilangan narxdan kam pul miqdorlari kiritild ! \n Belgilangan: ${target} usd; \n Kiritildi: ${usd.value}`,
        "warning"
      );
      return true;
    }
  }
  if (!usd.value && uzs.value) {
    if (uzs.value / currency > target) {
      uzs.style.borderColor = "red";
      callback(
        "Ogohlantirish !",
        `Belgilangan narxdan ko'p pul miqdorlari kiritild ! \n Belgilangan: ${target} usd; \n Kiritildi: ${
          uzs.value / currency
        }`,
        "warning"
      );
      return true;
    } else if (uzs.value < target) {
      uzs.style.borderColor = "red";
      callback(
        "Ogohlantirish !",
        `Belgilangan narxdan kam pul miqdorlari kiritild ! \n Belgilangan: ${target} usd; \n Kiritildi: ${
          uzs.value / currency
        }`,
        "warning"
      );
      return true;
    }
  }
  return false;
};

export function checkInputsEquality(target, usd, uzs, currency, callback) {
  if (usd.value && uzs.value) {
    let comparision = +usd.value + +uzs.value / currency;
    if (comparision > target) {
      callback(
        "Ogohlantirish!",
        `Ortiqcha summa kiritildi ! \n Kiritilishi kerak: ${target} usd, \n Kiritildi: ${comparision} usd , \n Ortiqcha: ${
          comparision - target
        } usd`,
        "warning"
      );
      usd.style.borderColor = "red";
      uzs.style.borderColor = "red";
      return true;
    } else if (comparision < target) {
      callback(
        "Ogohlantirish!",
        `Kiritilgan summa yetarli emas ! \n Kiritilishi kerak: ${target} usd, \n Kiritildi: ${comparision} usd, \n Yetishmayapti: ${
          target - comparision
        } usd`,
        "warning"
      );
      usd.style.borderColor = "red";
      uzs.style.borderColor = "red";
      return true;
    } else {
      return false;
    }
  }
  if (usd.value && !uzs.value) {
    if (+usd?.value > target) {
      callback(
        "Ogohlantirish!",
        `Ortiqcha summa kiritildi ! \n Kiritilishi kerak: ${target} usd, \n Kiritildi: ${
          usd.value
        } usd , \n Ortiqcha: ${usd.value - target} usd`,
        "warning"
      );
      usd.style.borderColor = "red";
      return true;
    } else if (+usd?.value < target) {
      callback(
        "Ogohlantirish!",
        `Kiritilgan summa yetarli emas ! \n Kiritilishi kerak: ${target} usd, \n Kiritildi: ${
          usd.value
        } usd, \n Yetishmayapti: ${target - usd.value} usd`,
        "warning"
      );
      usd.style.borderColor = "red";
      return true;
    } else {
      return false;
    }
  }

  if (!usd.value && uzs.value) {
    if (+uzs?.value / currency > target) {
      callback(
        "Ogohlantirish!",
        `Ortiqcha summa kiritildi ! \n Kiritilishi kerak: ${target} usd, \n Kiritildi: ${
          +uzs?.value / currency
        } usd, \n Ortiqcha: ${+uzs?.value / currency - target} usd`,
        "warning"
      );
      uzs.style.borderColor = "red";
      return true;
    } else if (+uzs?.value / currency < target) {
      callback(
        "Ogohlantirish!",
        `Kiritilgan summa yetarli emas ! \n Kiritilishi kerak: ${target} usd, \n Kiritildi: ${
          +uzs?.value / currency
        } usd, \n Yetishmayapti: ${target - +uzs?.value / currency} usd`,
        "warning"
      );
      uzs.style.borderColor = "red";
      return true;
    } else {
      return false;
    }
  }

  return false;
}

export function checkInputForDebt(target, usd, uzs, currency, callback) {
  let comparision = +usd.value + uzs.value / currency;

  if (!usd.value && !uzs.value) {
    callback(
      "Ogohlantirish",
      "Berilishi kerak bo'lgan pul kiritilmadi",
      "warning"
    );
    changeBorderColor(usd);
    changeBorderColor(uzs);
    return true;
  }

  if (comparision > target) {
    callback(
      "Ogohlantirish",
      `Ortiqcha pul kiritilyapti. Berilishi kerak: ${target}; \n Kiritildi${comparision}`
    );
    changeBorderColor(usd);
    changeBorderColor(uzs);
    return true;
  }

  return false;
}
