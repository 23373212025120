import React from "react";
import Header from "../../components/Header/Header";
import { ArrowRight, Dress } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

export default function AddSalonOrDress() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <>
      <Header
        heading={"M'alumot kiritish"}
        navPath="/fiftyFifty"
        icon={true}
      ></Header>
      <ul className="px-4 bg-white h-full pt-6 flex flex-col gap-y-6">
        <li className="">
          <p className="text-textDark mb-2 text-lg">{t("other.enterDress")}</p>
          <div
            className="border border-[#E6E6EA] p-7 flex items-center justify-between rounded-main bg-white"
            onClick={() => navigate("/addDress")}
          >
            <div className="flex items-center">
              <span className="w-11 h-11 border border-[#E6E6EA]  flex justify-center items-center rounded-md">
                <LazyLoadImage
                  className="brend_svg"
                  src={Dress}
                  alt="Just an icon"
                ></LazyLoadImage>
              </span>
              <span className=" text-base ml-7 w-3/4 text-textSilver">
                {t("other.newDress")}
              </span>
            </div>
            <LazyLoadImage src={ArrowRight} alt="arrow icon"></LazyLoadImage>
          </div>
        </li>
        <li className="">
          <p className="text-textDark mb-2 text-lg">{t("other.addSalon")}</p>
          <div
            className="border border-[#E6E6EA] p-7 flex items-center justify-between rounded-main bg-white"
            onClick={() => navigate("/addNewSalon")}
          >
            <div className="flex items-center">
              <span className="w-11 h-11 border border-[#E6E6EA]  flex justify-center items-center rounded-md">
                <LazyLoadImage
                  className="brend_svg"
                  src={Dress}
                  alt="Just an icon"
                ></LazyLoadImage>
              </span>
              <span className="text-base ml-7 w-3/4 text-textSilver">
                {t("other.newSalon")}
              </span>
            </div>
            <LazyLoadImage src={ArrowRight} alt="arrow icon"></LazyLoadImage>
          </div>
        </li>
      </ul>
    </>
  );
}
