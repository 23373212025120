import React, { useEffect, useState } from "react";
import { Search, CloseIcon, DownArrow } from "../../assets/images";
import { baseUrl } from "../../baseUrl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { searchData } from "../../utils/SearchData";
import { useTranslation } from "react-i18next";
import useRole from "../../hooks/useRole";

export default function GipyurButtonSheet({
  data = [],
  setData = () => {},
  variant,
}) {
  const { t } = useTranslation();

  const [role] = useRole();

  const [isClicked, setIsClicked] = useState(false);

  const [selected, setSelected] = useState({});

  const [value, setValue] = useState("");

  const handleClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked(false);
    }
  };

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  useEffect(() => {
    setSelected(data[0]);
    setData(data[0]);
  }, [data]);

  return (
    <>
      <div className="w-full " onClick={() => setIsClicked(true)}>
        <span className="text-textDark mb-1.5 block">
          {t("gipyurSelling.simpleSelling.form.chooseGipyur")}
        </span>
        <div className="flex justify-between items-center border border-[#E6E6EA] px-4 py-3 rounded-main w-full">
          {variant === "code" ? (
            <span>{selected?.gipyur_code}</span>
          ) : (
            <>
              <span className="text-textSilver">{selected?.gipyur_code}</span>
              {role?.status !== 5 ? (
                <span className="text-textSilver">
                  {selected?.gipyur_percent}%
                </span>
              ) : (
                ""
              )}
            </>
          )}
          <img src={DownArrow} alt="just an icon" />
        </div>
      </div>
      <div
        className={`${
          isClicked
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed transition  duration-300 delay-75 z-10 bottom-0 min-[500px]:left-1/2 min-[500px]:-translate-x-1/2 max-w-[500px] w-full left-0 right-0 flex justify-center items-end`}
        id="modal_wrapper"
        onClick={handleClick}
      >
        <div
          className={`bg-white w-full h-3/4 px-4 flex flex-col py-6 ${
            isClicked ? "translate-y-0" : "translate-y-full"
          } transition-all ease-in-out overflow-y-auto`}
        >
          <p className="text-center text-black font-medium text-lg mb-6">
            {t("gipyurSelling.simpleSelling.form.chooseGipyur")}
          </p>

          <label className="relative block mb-6">
            <img
              className="absolute left-3 top-1/2 -translate-y-1/2"
              src={Search}
              alt="Just an icon"
            />
            <input
              className="border border-[#AFBACA] outline-none py-3 block w-full rounded-md px-12"
              type="text"
              value={value}
              placeholder={t("search")}
              onChange={(e) => setValue(e.target.value)}
            />
            <img
              className="absolute right-3 top-1/2 -translate-y-1/2"
              src={CloseIcon}
              alt="Just an icon"
              onClick={() => setValue("")}
            />
          </label>
          <ul className="grid grid-cols-2 gap-4">
            {data?.length &&
              searchData(
                value,
                data?.map((el) => {
                  el.title = el?.gipyur_code;
                  return el;
                })
              ).map((el) => (
                <li
                  className="bg-white rounded-main pb-3 h-full"
                  onClick={() => {
                    setData(el);
                    setSelected(el);
                    setIsClicked(false);
                  }}
                  key={el?.id}
                >
                  <LazyLoadImage
                    className="rounded-main mb-3 w-full max-h-[120px] h-full"
                    src={baseUrl + el?.gipyur_image}
                    alt="Gipyur info card pic"
                    onError={handleImageError}
                  ></LazyLoadImage>
                  <span className="text-textDark font-semibold block px-3">
                    # {el?.gipyur_code}
                  </span>
                  <ul className="flex flex-col gap-y-0.5 px-3">
                    <li className="text-textDark">
                      Do'konda: <span className="font-bold">{el?.in_shop}</span>
                    </li>
                    <li className="text-textDark">
                      Omborda:{" "}
                      <span className="font-bold">{el?.gipyur_metr}</span>
                    </li>
                    <li className="text-textDark">
                      Rangi:{" "}
                      <span className="font-bold">{el?.gipyur_color}</span>
                    </li>
                  </ul>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
}
