import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Layout({ children }) {
  const { pathname } = useLocation();

  const { t } = useTranslation();

  const headings = [
    { id: 1, path: "/", heading: "LaStoria" },
    { id: 2, path: "/orders", heading: "Buyurtmalar" },
    { id: 3, path: "/report", heading: t("pages.reportPart") },
    { id: 4, path: "/profile", heading: t("profile.heading") },
    { id: 5, path: "/statistics", heading: "LaStoria" },
  ];
  return (
    <>
      {headings.map((el) => {
        return pathname !== el.path ? null : (
          <Header
            heading={headings.find((el) => el.path === pathname)?.heading}
            user={pathname === "/" || pathname === "/statistics" || false}
            search={pathname === "/orders" || false}
            profile={pathname === "/profile" || false}
            icon={pathname === "/statistics"}
            key={el.id}
          ></Header>
        );
      })}
      <main>
        <Routes>{children}</Routes>
      </main>
      <Footer></Footer>
    </>
  );
}
