import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import OrderCard from "../../components/OrderCard/OrderCard";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import { useTranslation } from "react-i18next";

export default function RejectedOrders() {
  const { t } = useTranslation();

  const [offset, setOffset] = useState(0);

  const [data, setData] = useState([]);

  let limit = 15;

  const data1 = useFetch(
    baseUrl +
      `/dress-order/rejected/all?limit=${limit}&offset=${limit * offset}`
  );

  useEffect(() => {
    if (data1?.data?.length) {
      setData([...data, ...data1?.data]);
    }
  }, [data1?.data]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (
        scrollTop + clientHeight >= scrollHeight &&
        data1?.count >= offset * limit
      ) {
        setOffset(offset + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offset, data1?.count]);

  return (
    <>
      <Header
        heading={t("pages.rejectedOrders")}
        icon={true}
        navPath="/report"
      ></Header>
      <ul className="flex flex-col gap-y-6 px-4 pt-4 bg-white h-full pb-6">
        {data?.length &&
          data.map((el) => (
            <OrderCard
              id={el?.id}
              key={el?.id}
              salon_name={el?.salon_name}
              dress_name={el?.dress_name}
              image={el?.dress_image}
              order_number={el?.order_number}
            ></OrderCard>
          ))}
      </ul>
    </>
  );
}
