import React from "react";
import Header from "../../components/Header/Header";
import ReportsList from "../../components/ReportsList/ReportsList";
import { House, Products, Report, Dollar } from "../../assets/images";
import useRole from "../../hooks/useRole";
import { useTranslation } from "react-i18next";

export default function DirectorReport() {
  const [role] = useRole();

  const { t } = useTranslation();

  const data = [
    {
      id: 1,
      title: t("director.shopReport"),
      icon: House,
      path: "salon-details",
    },
    {
      id: 2,
      title: t("director.storeReport"),
      icon: Products,
      path: "warehouse-details",
    },
  ];

  const data1 = [
    {
      id: 1,
      title: t("pages.viewReport"),
      icon: Report,
      path: "manager-details",
    },
    {
      id: 2,
      title: t("pages.debtorsList"),
      icon: Dollar,
      path: "/listOfDebtors",
    },
  ];

  if (role?.status === 5) {
    data1.push({
      id: 3,
      title: t("gipyurExpenses.heading"),
      icon: Dollar,
      path: "/addExpenses",
    });
  }

  return (
    <>
      <Header heading={"LaStoria"} user={true}></Header>
      <div className="px-4">
        <ReportsList
          data={role?.status === 4 || role?.status === 5 ? data1 : data}
        ></ReportsList>
      </div>
    </>
  );
}
