import React, { useEffect, useState } from "react";
import { ArrangeSquare } from "../../assets/images";
import { useTranslation } from "react-i18next";

export default function CurrencyCalculator({ currency }) {
  const [isClicked, setIsClicked] = useState(false);

  const { t } = useTranslation();

  const [value, setValue] = useState(1);

  const [value1, setValue1] = useState("");

  useEffect(() => {
    if (currency) {
      setValue1(currency * value);
    }
  }, [currency]);

  const handleUSDInputChange = (e) => {
    setValue(e.target.value);
    setValue1(e.target.value * currency);
  };

  const handleUZSInputChange = (e) => {
    setValue1(e.target.value);
    setValue(e.target.value / currency);
  };

  return (
    <div className="">
      <span className="text-base text-textDark mb-2 block">
        {t("closePartnersDebt.form.currency")}
      </span>
      <div
        className={`${
          isClicked ? "flex flex-row-reverse" : "flex"
        } gap-x-1 items-center`}
      >
        <label className="relative w-1/2">
          <input
            className="pl-3 w-full pr-12 text-base py-3 rounded-md text-textSilver border border-[#D7E1EA] outline-none "
            type="number"
            readOnly={isClicked}
            value={value}
            onChange={handleUSDInputChange}
          />
          <span className="absolute right-3  top-1/2 -translate-y-1/2 text-lg text-mainText font-semibold">
            USD
          </span>
        </label>
        <img
          className="w-6 h-6 object-contain"
          src={ArrangeSquare}
          alt="Just an icon"
          width={24}
          height={24}
          onClick={() => setIsClicked(!isClicked)}
        />
        <label className="relative w-1/2">
          <input
            className="pl-3 w-full pr-12 py-3 text-base rounded-md text-textSilver border border-[#D7E1EA] outline-none "
            type="number"
            readOnly={!isClicked}
            value={value1}
            onChange={handleUZSInputChange}
          />
          <span className="absolute right-3 top-1/2 -translate-y-1/2 text-lg text-mainText font-semibold">
            UZS
          </span>
        </label>
      </div>
    </div>
  );
}
