import React, { useEffect, useState } from "react";
import { DownArrow } from "../../assets/images";
import { useNavigate } from "react-router-dom";

export default function ButtonSheetSimple({
  data = [],
  select = false,
  placeholder,
  title,
  name,
  name1,
  setData = () => {},
  defval = true,
  value,
  button = false,
  btnTitle,
  btnPath,
}) {
  const [isClicked, setIsClicked] = useState(false);

  const [inputVal, setInputVal] = useState(placeholder);

  const navigate = useNavigate();

  const handleClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked(false);
    }
  };

  useEffect(() => {
    if (isClicked) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isClicked]);

  return (
    <>
      {select ? (
        <label>
          <span className="text-textDark mb-1.5 block">{title}</span>
          <span className="relative block w-full">
            <input
              className="rounded-md border border-[#D7E1EA] w-full py-3 px-3.5 outline-none text-textSilver capitalize"
              type="text"
              placeholder={placeholder}
              readOnly
              value={
                inputVal?.title ||
                value?.title ||
                (defval &&
                  (data[2]?.title ? data[2]?.title : data[0]?.title)) ||
                ""
              }
              onClick={() => setIsClicked(!isClicked)}
              name={name1}
            />
            <input
              className="hidden"
              defaultValue={inputVal?.id || ""}
              type="text"
              name={name}
            />
            <img
              className="absolute right-5 top-1/2 -translate-y-1/2"
              src={DownArrow}
              alt="Just an icon"
            />
          </span>
        </label>
      ) : (
        ""
      )}
      <div
        className={`${
          isClicked
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed transition  duration-300 delay-75 z-10 bottom-0 min-[500px]:left-1/2 min-[500px]:-translate-x-1/2 max-w-[500px] w-full left-0 right-0 flex justify-center items-end`}
        id="modal_wrapper"
        onClick={handleClick}
      >
        <ul
          className={`bg-white w-full h-3/4 px-4 flex flex-col py-8 ${
            isClicked ? "translate-y-0" : "translate-y-full"
          } transition-all ease-in-out overflow-y-auto`}
        >
          {data.map((el) => (
            <li
              className="py-3 border-b border-[#D7E1EA] flex justify-between items-center"
              key={el?.id}
              onClick={() => {
                setInputVal(el);
                setIsClicked(false);
                setData(el);
              }}
            >
              <p className="font-medium text-lg uppercase text-mainText">
                {el?.title} {el?.tag}
              </p>{" "}
              <span
                className={`w-6 h-6 rounded-full border border-[#AFBACA] flex justify-center items-center ${
                  el?.id === inputVal?.id ||
                  el.title === (inputVal?.title || value?.title)
                    ? "bg-[#702DE3]"
                    : ""
                }`}
                style={{
                  borderColor:
                    el?.id === defval &&
                    el?.id === (inputVal?.id || data[2]?.id || data[0]?.id)
                      ? "#702DE3"
                      : "",
                }}
              >
                <span
                  className={`block w-4 h-4 rounded-full ${
                    el?.id === inputVal?.id ? "bg-[#702DE3]" : ""
                  }`}
                  style={{
                    backgroundColor:
                      defval &&
                      el?.id === (inputVal?.id || data[2]?.id || data[0]?.id)
                        ? "#702DE3"
                        : "",
                  }}
                ></span>
              </span>
            </li>
          ))}
          <li className="mt-auto">
            {button ? (
              <button
                className="bg-[#702DE3] rounded-md text-white py-3.5 block text-center w-full mt-6"
                onClick={() => navigate(btnPath)}
              >
                {btnTitle}
              </button>
            ) : (
              ""
            )}
          </li>
        </ul>
      </div>
    </>
  );
}
