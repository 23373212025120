import React, { useState } from "react";

const Context = React.createContext();

function Provider({ children }) {
  const [token, setToken] = React.useState(
    JSON.parse(window.localStorage.getItem("token")) || false
  );

  const [userId, setUserId] = React.useState(
    JSON.parse(window.localStorage.getItem("userId")) || false
  );

  const [userRole, setUserRole] = useState(
    JSON.parse(window.localStorage.getItem("role")) || false
  );

  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    if (token) {
      window.localStorage.setItem("token", JSON.stringify(token));
    } else {
      window.localStorage.removeItem("token");
    }
  }, [token]);

  React.useEffect(() => {
    if (userId) {
      window.localStorage.setItem("userId", JSON.stringify(userId));
    } else {
      window.localStorage.removeItem("userId");
    }
  }, [userId]);

  React.useEffect(() => {
    if (Object.keys(userRole || {}).length) {
      window.localStorage.setItem("role", JSON.stringify(userRole));
    } else {
      window.localStorage.removeItem("role");
    }
  }, [userRole]);

  const value = {
    token,
    setToken,
    userId,
    setUserId,
    userRole,
    setUserRole,
    isLoading,
    setIsLoading,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export { Context, Provider };
