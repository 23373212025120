import React, { useEffect, useState, useMemo } from "react";
import ButtonSheetImg from "../BottomSheet/ButtonSheetImg";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import ButtonSheetSimple from "../BottomSheet/BottomSheetSimple";
import MainInput from "../MainInput/MainInput";
import MainButton from "../MainButton/MainButton";

const shlefData = [
  { id: 1, title: 30, tag: "sm" },
  { id: 2, title: 60, tag: "sm" },
  { id: 3, title: 80, tag: "sm" },
  { id: 4, title: 100, tag: "sm" },
  { id: 5, title: 150, tag: "sm" },
  { id: 6, title: 200, tag: "sm" },
];

export default function DressCard({
  setDressCount,
  dressCount,
  setDresses,
  dressess,
}) {
  const dresses = useFetch(baseUrl + "/dress/all");

  const dressColors = useFetch(baseUrl + "/dress/colors");

  const [shleft, setShleft] = useState(shlefData[0]);

  const [color, setColor] = useState();

  const [dress, setDress] = useState({});

  const [price, setPrice] = useState();

  let dressData = dresses?.map((el) => {
    return {
      id: el?.id,
      price: el?.dress_price,
      title: el?.dress_name,
      created_at: el?.created_at,
      image: el?.dress_image,
      shleft_size: el?.dress_shleft_size,
      dress_color: el?.dress_color,
    };
  });

  useEffect(() => {
    setPrice(dress?.price);
    setColor(dressColors?.[0]);
  }, [dress, dressColors]);

  let validColors = dressColors?.map((el, id) => {
    return {
      id,
      title: el?.dress_color,
    };
  });

  const handleClick = (id) => {
    if (dressCount === 0) {
      setDressCount(0);
    } else {
      setDressCount(dressCount - 1);
      setDresses(dressess?.filter((el) => el?.dress_id !== id));
    }
  };

  const newDress = {
    dress_name: dress?.title,
    dress_id: dress?.id,
    dress_color: dress?.dress_color,
    shleft_size: dress?.shleft_size,
    base_price: dress?.price,
    price: price,
  };

  const handleChange = (e, id) => {
    if (id) {
      console.log(dressess);
      const another = dressess.map((el) => {
        if (el.dress_id === id) {
          el.price = +e.target.value;
        }
        return el;
      });

      setDresses(another);
    }
  };

  useEffect(() => {
    if (color) {
      newDress.dress_color = color?.title || color?.dress_color;
    }

    // if(shleft){
    //   newDress.shleft_size = shleft
    // }

    if (Object.values(newDress).every((el) => el !== undefined)) {
      if (dressess.length === dressCount) {
        setDresses([newDress]);
      } else {
        setDresses([...dressess, newDress]);
      }
    }
  }, [dress, color, shleft, price]);

  return (
    <div
      className="py-4 px-2.5 bg-white rounded-md flex flex-col gap-y-3"
      style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)" }}
    >
      <ButtonSheetImg
        type={"dress"}
        placeholder={"Ko'ylak tanlang"}
        data={dressData}
        select={true}
        title={"Ko'ylak nomi"}
        width={"165px"}
        height={"120px"}
        name={"dress_id"}
        name1={"dress_name"}
        setDress={setDress}
        btn={true}
        btnTitle={"Ko'ylak qo'shish"}
        navPath={"/addDress"}
      />
      <div className="flex gap-x-4 justify-between items-center">
        <ButtonSheetSimple
          title={"Shleft o’lchami"}
          select={true}
          name={"shleft_size"}
          data={shlefData}
          setData={setShleft}
          value={{ title: dress?.shleft_size }}
        />
        <ButtonSheetSimple
          title={"Ko’ylak rangi"}
          select={true}
          name={"dress_color"}
          data={validColors}
          setData={setColor}
          value={{ title: dress?.dress_color } || validColors[0]}
        />
      </div>
      <MainInput
        value={dress?.price || 0}
        // readOnly={true}
        title={"Ko’ylak narxi"}
        type="number"
        currency={"usd"}
        handleChange={(e) => handleChange(e, dress?.id)}
      />
      <MainButton
        bgColor="rgba(114, 128, 157, 0.4)"
        color={"#72809D"}
        type={"button"}
        handleClick={() => handleClick(dress?.id)}
      >
        O’chirish
      </MainButton>
    </div>
  );
}
