import React, { useState } from "react";
import { Search, CloseIcon } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import { useTranslation } from "react-i18next";
import DressCheckbox from "../../components/DressCheckbox/DressCheckbox";
import MainButton from "../../components/MainButton/MainButton";
import axios from "axios";
import useToken from "../../hooks/useToken";
import useAlert from "../../hooks/useAlert";
import { searchData } from "../../utils/SearchData";

export default function DressesList() {
  const [token] = useToken();

  const [showAlert] = useAlert();

  const [isSearch, setIsSearch] = useState(false);

  const [inputVal, setInputVal] = useState("");

  const [data, setData] = useState({ in_stock: [], out_stock: [] });

  const dresses = useFetch(baseUrl + "/dress/all");

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleClick = () => {
    if (data.in_stock.length || data.out_stock.length) {
      axios
        .put(baseUrl + "/dress/in-stock", data, {
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res?.data?.data?.id) {
            showAlert(
              "Muvaffaqiyatli!",
              "Kiritilgan o'zgarishlar muvaffaqiyatli saqlandi!",
              "success"
            );
          }
        })
        .catch((err) => showAlert("Xatolik", "Xatolik yuz berdi !", "error"));
    }
  };

  return (
    <>
      <div
        className="pb-4 px-4 bg-white pt-10"
        style={{ boxShadow: "0px 12px 14px rgba(209, 212, 226, 0.157261)" }}
      >
        <div className="text-center mb-10 relative">
          <span
            className="h-8 w-8 rounded-full text-lg text-[#354B91]  top-1/2 -translate-y-1/2 left-6 border border-[#354B91] flex justify-center items-center absolute"
            onClick={(e) => {
              navigate(-1);
            }}
          >
            &#10094;
          </span>
          <h1 className="text-xl text-[#702DE3]">
            <span>{t("chooseDress")}</span>
          </h1>
          <img
            className="absolute right-2 top-0"
            src={Search}
            alt="Just an icon"
            onClick={() => setIsSearch(!isSearch)}
          />
        </div>

        {isSearch ? (
          <label className="w-full relative">
            <input
              className="bg-[#FCFCFC] border border-[#D7E1EA] w-full rounded-md p-3.5 outline-none text-sm text-textSilver"
              onChange={(e) => setInputVal(e.target.value)}
              type="text"
              value={inputVal}
            />
            <img
              className="absolute right-4 top-1/2 -translate-y-1/2"
              src={CloseIcon}
              alt="Just an icon"
              onClick={() => setInputVal("")}
            />
          </label>
        ) : (
          ""
        )}
      </div>

      <div className="px-4 bg-[#F8F5F5] pb-4">
        <ul className=" pt-8 grid grid-cols-2 gap-4 mb-5">
          {dresses?.length &&
            searchData(
              inputVal,
              dresses?.map((el) => {
                el.title = el.dress_name;
                return el;
              })
            ).map((el) => (
              <DressCheckbox
                key={el?.id}
                el={el}
                setData={setData}
                data={data}
              ></DressCheckbox>
            ))}
        </ul>
        <MainButton type="button" handleClick={handleClick}>
          Saqlash
        </MainButton>
      </div>
    </>
  );
}
