import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import "./input.css";
import { Calender } from "../../assets/images";
import {
  formattedDate,
  getDatePickerDate,
} from "../../utils/getDatePickerDate";

export default function InputDate({
  value,
  mobile = true,
  name,
  defVal = true,
}) {
  const [inputVal, setInputVal] = React.useState();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          "DatePicker",
          "MobileDatePicker",
          "DesktopDatePicker",
          "StaticDatePicker",
        ]}
      >
        {mobile ? (
          <DemoItem>
            <label className="relative w-full block">
              <MobileDatePicker
                defaultValue={defVal ? dayjs(formattedDate) : ""}
                className="date_picker"
                onChange={(e) => setInputVal(getDatePickerDate(e.$d))}
              />
              <input
                className="hidden"
                type="text"
                name={name}
                defaultValue={inputVal}
              />
              <img
                className="absolute top-1/3 -translate-y-1/3 right-4"
                src={Calender}
                alt="Just an icon"
              />
            </label>
          </DemoItem>
        ) : (
          <DemoItem>
            <DesktopDatePicker
              defaultValue={dayjs("2022-04-17")}
              value={dayjs(value)}
            />
          </DemoItem>
        )}
      </DemoContainer>
    </LocalizationProvider>
  );
}
