import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { CameraPh, Location } from "../../assets/images";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import useToken from "../../hooks/useToken";
import MainInput from "../../components/MainInput/MainInput";
import LoginInput from "../../components/MainInput/LoginInput";
import MainButton from "../../components/MainButton/MainButton";
import useUserId from "../../hooks/useUserId";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

export default function AddNewSalon() {
  const [token] = useToken();

  const [_, setIsLoading] = useLoading();

  const { t } = useTranslation();

  const [userId] = useUserId();

  const [showAlert] = useAlert();

  const [selectedImages, setSelectedImages] = useState("");

  const [images, setImages] = useState("");

  const navigate = useNavigate();

  const langtitude = 41.272986;

  const longtitude = 69.207828;

  const handleChange = (e) => {
    setImages(URL.createObjectURL(e.target.files[0]));

    let formData = new FormData();

    formData.append("file", e.target.files[0]);

    try {
      axios
        .post(baseUrl + "/file-upload", formData, {
          method: "POST",
          headers: {
            Authorization: `mme:${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 201) {
            setSelectedImages(res.data?.image);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { salon_name, customer_name, phone_number, description } =
      e.target.elements;

    let newSalon = {
      image: selectedImages,
      salon_name: salon_name?.value,
      customer_name: customer_name?.value,
      customer_phone: "998" + phone_number?.value,
      salon_address: "Chilonzor",
      salon_lat: longtitude,
      salon_lng: langtitude,
      created_by_user: userId,
      description: description?.value,
    };

    if (Object.values(newSalon).length >= 8) {
      setIsLoading(true);
      axios
        .post(baseUrl + "/salon/create", newSalon, {
          method: "POST",
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res.data?.data?.id) {
            setIsLoading(false);
            showAlert(
              "Muvafaqqiyatli",
              "Jarayon muvafaqqiyatli yakunalndi...",
              "success"
            );
            navigate(-1);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          showAlert("Xatolik !", "Xatolik yuz berdi !", "error");
        });
    }
  };

  return (
    <>
      <Header
        heading={t("salonPage.heading")}
        icon={true}
        navPath={-1}
      ></Header>
      <form
        className="bg-white px-4 pt-6 pb-4 h-screen flex flex-col justify-between overflow-auto"
        onSubmit={handleSubmit}
      >
        <div className="mb-20">
          <div className="mb-4">
            <span className="text-textDark mb-2 block">
              {t("salonPage.form.image")}
            </span>
            <div className="flex gap-2 items-center flex-wrap">
              <label className="w-20 h-[70px] border-dashed border rounded-md border-[#D7E1EA] flex justify-center items-center">
                <input
                  className="hidden"
                  type="file"
                  onChange={handleChange}
                  accept="image/*"
                />

                {images ? (
                  <div className="w-20 h-[70px] border-dashed border rounded-md border-[#D7E1EA] flex justify-center items-center">
                    <img
                      className="w-full h-full object-cover rounded-md"
                      width={80}
                      height={70}
                      src={images}
                      alt="Just an icon"
                    />
                  </div>
                ) : (
                  <div className="w-20 h-[70px] border-dashed border rounded-md border-[#D7E1EA] flex justify-center items-center">
                    <img src={CameraPh} alt="Just an icon" />
                  </div>
                )}
              </label>
            </div>
          </div>
          <MainInput
            type="text"
            name={"salon_name"}
            title={t("bookingPage.form.salonName")}
            // placeholder={"Salon nomini kiriting"}
            margin={"16px"}
          ></MainInput>
          <MainInput
            type="text"
            name={"customer_name"}
            title={t("salonPage.form.name")}
            // placeholder={"Salonchi ismini kiriting"}
            margin={"16px"}
          ></MainInput>
          <span className="text-textDark mb-1.5 block">{t("login.label")}</span>
          <div>
            <LoginInput
              variant="mainInput"
              type="tel"
              name={"phone_number"}
            ></LoginInput>
          </div>
          <label className="block my-4">
            <span className="text-textDark block mb-1.5">
              {t("salonPage.form.address")}
            </span>
            <span className="relative">
              <img
                className="absolute top-1/2 -translate-y-1/2 left-4 "
                src={Location}
                alt="Just an location icon"
              />
              <input
                className="border border-[#D7E1EA] text-textSilver p-3.5 rounded-md text-base w-full pl-14 outline-slate-500"
                type="text"
                placeholder="Yakkasaroy"
                readOnly
              />
            </span>
          </label>
          <label className="">
            <span className="text-textSilver block mb-1.5">
              {t("bookingPage.form.dressDesc")}
            </span>
            <textarea
              className="w-full max-h-[140px] h-full outline-none p-3.5 border border-[#D7E1EA] rounded-md"
              name="description"
            ></textarea>
          </label>
        </div>

        <MainButton type={"submit"}>{t("submit")}</MainButton>
      </form>
    </>
  );
}
