import React, { useState } from "react";
import { ProductCode } from "../../assets/images";
import { formatNumberWithSpace } from "../../utils/formatNumber";

export default function MainInput({
  title,
  type = "text",
  placeholder,
  name,
  width,
  handleClick,
  margin,
  code,
  required,
  value,
  styles,
  readOnly,
  currency,
  handleChange = () => {},
}) {
  const [val, setVal] = useState("");
  return (
    <label className="block" style={{ marginBottom: margin }}>
      <span className="mb-2 text-base text-textDark block">{title}</span>
      <span className="flex relative">
        <input
          className={`border border-[#D7E1EA] px-4 py-3 text-textDark placeholder:text-textSilver w-full bg-white rounded-md outline-none ${
            styles ? styles : ""
          }`}
          type={type}
          placeholder={placeholder}
          name={name}
          style={{ width: width }}
          onClick={handleClick}
          required={required}
          readOnly={readOnly}
          // defaultValue={value || ""}
          defaultValue={val || value || ""}
          onChange={(e) => {
            setVal(e?.target?.value);
            handleChange();
          }}
          onFocus={(e) => (e.target.style.borderColor = "#D7E1EA")}
          // maxLength={type === "tel" ? 13 : ""}
        />
        {code ? (
          <img className="ml-5 shrink-0" src={ProductCode} alt="Just an icon" />
        ) : (
          ""
        )}
        {currency ? (
          <span className="absolute text-mainText font-medium top-1/2 -translate-y-1/2 right-4 uppercase">
            {currency}
          </span>
        ) : (
          ""
        )}
      </span>
    </label>
  );
}
