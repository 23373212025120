import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { Uploader } from "../../assets/images";
import MainInput from "../../components/MainInput/MainInput";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import ButtonSheetSimple from "../../components/BottomSheet/BottomSheetSimple";
import MainButton from "../../components/MainButton/MainButton";
import useToken from "../../hooks/useToken";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

export default function AddGipyur() {
  const [imgUrl, setImgUrl] = useState("");

  const [image, setImage] = useState("");

  const [token] = useToken();

  const { t } = useTranslation();

  const [_, setIsLoading] = useLoading();

  const [showAlert] = useAlert();

  const navigate = useNavigate();

  const gipyurColors = useFetch(baseUrl + "/gipyur/colors");

  const handleSubmit = (e) => {
    e.preventDefault();

    const elements = e.target.elements;

    let { warning_limit } = e.target.elements;

    let newData = {
      gipyur_image: image,
    };

    for (let el of elements) {
      if (el.name && el.value) {
        newData[el.name] = el.value;
      }

      if (!isNaN(el?.value) && el.value && el.name) {
        newData[el.name] = Number(el.value);
      }
    }

    newData.warning_limit = warning_limit?.value;
    if (Object.values(newData).length >= 6) {
      setIsLoading(true);
      axios
        .post(baseUrl + "/gipyur", newData, {
          method: "POST",
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res?.data?.data?.id) {
            setIsLoading(false);
            showAlert(
              "Muvaffaqiyatli !",
              "Jarayon muvaffaqiyatli yakunlandi !",
              "success"
            );
            navigate("/gipyurInfo");
          } else {
            setIsLoading(false);
            console.log(res);
            showAlert("Xatolik !", res?.data?.error?.errorMessage, "error");
          }
        })
        .catch((err) => {});
    }
  };

  const handleChange = (e) => {
    setImgUrl(URL.createObjectURL(e.target.files[0] || {}));

    let formData = new FormData();

    formData.append("file", e.target.files[0]);

    axios
      .post(baseUrl + "/file-upload", formData, {
        method: "POST",
        headers: {
          Authorization: "mme:" + token,
        },
      })
      .then((res) => setImage(res?.data?.image))
      .catch((err) => console.log(err));
  };

  return (
    <div className="bg-white h-screen flex flex-col">
      <Header
        navPath="/gipyurInfo"
        icon={true}
        heading={t("gipyurInfo.form.addGipyur")}
      ></Header>
      <form
        className="px-4 flex flex-col justify-between grow pb-7 gap-y-4 bg-white"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-4">
          <div className="px-10 mt-6 mb-6">
            <label className="border-dashed relative border-[#E6E6EA] w-full flex justify-center items-center py-9 flex-col border">
              <img src={Uploader} alt="Just an icon" />
              <span className="uppercase text-textSilver mt-4">
                {t("gipyurInfo.form.chooseImage")}
              </span>
              <input
                className="hidden"
                type="file"
                onChange={handleChange}
                accept="image/x-png,image/gif,image/jpeg,image/webp,image/svg"
              />
              {imgUrl ? (
                <img
                  className="absolute top-0 bottom-0 right-0 left-0 rounded-main w-full h-full"
                  src={imgUrl}
                  alt="gipyur pic"
                />
              ) : (
                ""
              )}
            </label>
          </div>
          <MainInput
            title={t("gipyurInfo.form.price")}
            currency={"usd"}
            name={"gipyur_price"}
            type="number"
          ></MainInput>
          <ButtonSheetSimple
            title={t("gipyurInfo.form.color")}
            select={true}
            name1={"gipyur_color"}
            button={true}
            btnTitle={t("gipyurInfo.addNewColor")}
            btnPath={"/gipyurColor"}
            data={gipyurColors.map((el) => {
              el.title = el?.gipyur_color;
              return el;
            })}
          ></ButtonSheetSimple>
          <MainInput
            name={"gipyur_code"}
            title={t("gipyurInfo.form.code")}
            type="text"
          ></MainInput>
          <MainInput
            name={"gipyur_metr"}
            title={t("gipyurInfo.form.metrCome")}
            type="number"
            currency={"m"}
          ></MainInput>
          <div className="flex justify-between gap-x-3">
            <MainInput
              name={"gipyur_percent"}
              type="number"
              title={t("gipyurInfo.form.percent")}
              currency={"%"}
            ></MainInput>
            <MainInput
              name={"warning_limit"}
              type="number"
              title={t("gipyurInfo.form.warning")}
              currency={"m"}
            ></MainInput>
          </div>
        </div>
        <MainButton type={"submit"}>{t("submit")}</MainButton>
      </form>
    </div>
  );
}
