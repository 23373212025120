import React from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../../baseUrl";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

export default function OrderCard({
  id,
  dress_name,
  delivery_date,
  order_number,
  salon_name,
  image,
}) {
  const { t } = useTranslation();

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  return (
    <li
      className="w-full p-3 border  rounded-main"
      style={{ boxShadow: " 0px 3px 15px rgba(13, 14, 43, 0.05)" }}
    >
      <Link className="flex justify-between items-center" to={"orders_" + id}>
        <ul className="flex flex-col gap-y-2.5">
          <li>
            <span className="text-textSilver text-base font-medium">
              Salon:
            </span>{" "}
            <span className="font-medium">{salon_name}</span>
          </li>
          <li>
            <span className="text-textSilver text-base font-medium">
              {t("debtorsPage.name")}:
            </span>{" "}
            <span className="text-base text-textDark font-medium">
              {dress_name}
            </span>
          </li>
          {delivery_date ? (
            <li>
              <span className="text-textSilver text-base font-medium">
                {t("date")}:
              </span>{" "}
              <span className="font-medium">
                <Moment format="YYYY/MM/DD">{delivery_date}</Moment>
              </span>
            </li>
          ) : (
            ""
          )}
          <li>
            <span className="text-textSilver text-base font-medium">
              {t("ordersPage.card.order")} №:
            </span>{" "}
            <span>{order_number}</span>
          </li>
        </ul>
        <img
          className="w-[91px] h-[106px] object-contain"
          src={baseUrl + image}
          alt={dress_name}
          width={91}
          height={106}
          onError={handleImageError}
        />
      </Link>
    </li>
  );
}
