import React from "react";
import Header from "../../components/Header/Header";
import MainInput from "../../components/MainInput/MainInput";
import MainButton from "../../components/MainButton/MainButton";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import useToken from "../../hooks/useToken";
import { useNavigate } from "react-router-dom";
import useRole from "../../hooks/useRole";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

export default function Expenses() {
  const [token] = useToken();

  const [role] = useRole();

  const { t } = useTranslation();

  const [_, setIsLoading] = useLoading();

  const [showAlert] = useAlert();

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const { expenseName, expenseUsd, expenseUzs } = e.target.elements;

    let newExpense = {
      expense_name: expenseName.value,
      expense_usd: +expenseUsd.value || 0,
      expense_uzs: +expenseUzs.value || 0,
      added_by: role?.role,
    };

    if (expenseName.value && (expenseUsd.value || expenseUzs.value)) {
      setIsLoading(true);
      axios
        .post(baseUrl + "/expense/create", newExpense, {
          method: "POST",
          headers: {
            Authorization: `mme:${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data?.data?.id) {
            setIsLoading(false);
            showAlert(
              "Muvafaqqiyatli!",
              "Jarayon muvafaqqiyatli tugallandi...!",
              "success"
            );
            navigate("/report");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          showAlert(
            "Xatolik !",
            "Xatolik yuz berdi, qaytadan urinib ko'ring!",
            "error"
          );
        });
    } else {
      showAlert(
        "Ogohlantirish",
        "Kerakli ma'lumotlar to'ldirilmadi !!!",
        "warning"
      );
    }
  };
  return (
    <div className="bg-white h-screen flex flex-col overflow-y-hidden">
      <Header
        icon={true}
        heading={t("pages.addExpenses")}
        navPath="/report"
      ></Header>
      <form
        className="pt-5 px-4 bg-white flex flex-col justify-between pb-4 flex-grow"
        onSubmit={handleSubmit}
      >
        <div className="">
          <MainInput
            title={t("expenses.expenseName")}
            styles={"text-lg"}
            type={"text"}
            margin={"20px"}
            name={"expenseName"}
            required={true}
          ></MainInput>
          <div className="">
            <span className="text-textDark text-base mb-2">
              {t("expenses.expenseType")}
            </span>
            <div className="flex gap-x-2">
              <label className="relative grow">
                <span className="absolute bottom-3 right-3.5 text-mainText text-lg font-semibold">
                  USD
                </span>
                <MainInput
                  styles={"pr-14 text-lg text-mainText"}
                  type="number"
                  name={"expenseUsd"}
                  currency={"usd"}
                ></MainInput>
              </label>
              <label className="relative grow">
                <span className="absolute bottom-3 right-3.5 text-mainText text-lg font-semibold">
                  UZS
                </span>
                <MainInput
                  styles={"pr-14 text-lg text-mainText"}
                  type="number"
                  name={"expenseUzs"}
                  currency={"uzs"}
                ></MainInput>
              </label>
            </div>
          </div>
        </div>
        <MainButton bgColor={"#702DE3"} type={"submit"}>
          {t("submit")}
        </MainButton>
      </form>
    </div>
  );
}
