import React, { useEffect } from "react";
import MainInput from "../MainInput/MainInput";
import InputDate from "../MainInput/InputDate";
import MainButton from "../MainButton/MainButton";
import { PlusIcon } from "../../assets/images";
import { getMuiDate } from "../../utils/getMuiDate";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import useToken from "../../hooks/useToken";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

export default function AddProducts({
  product_price,
  product_name,
  created_at,
  isClicked,
  setIsClicked,
  product_id,
  measure,
  refetch,
}) {
  const [token] = useToken();

  const { t } = useTranslation();

  const [showAlert] = useAlert();

  const [_, setIsLoading] = useLoading();

  const handleSubmit = (e) => {
    e.preventDefault();
    let { product_count1, product_price1 } = e.target.elements;
    let date = document.querySelector(".MuiInputBase-input");
    let newProduct = {
      id: product_id,
      product_count: +product_count1.value,
      product_price: +product_price1.value,
      created_at: getMuiDate(date.value),
    };

    if (Object.values(newProduct).length > 3) {
      setIsLoading(true);
      axios
        .post(baseUrl + "/product/add", newProduct, {
          method: "POST",
          headers: {
            Authorization: `mme:${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data?.data?.id) {
            setIsLoading(false);
            refetch();
            showAlert(
              "Muvafaqqiyatli!",
              "Jarayon muvafaqqiyatli yakunlandi!",
              "success"
            );
            setIsClicked(null);
            product_count1.value = null;
            product_price1.value = null;
          }
        });
    } else {
      setIsLoading(false);
      showAlert("Ogohlantirish", "Forma to'liq to'ldirilmadi!", "warning");
    }
  };

  const handleClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked(null);
    } else {
      setIsClicked("add");
    }
  };

  useEffect(() => {
    if (isClicked) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isClicked]);

  return (
    <div
      className={`${
        isClicked === "add"
          ? "top-0 bg-black bg-opacity-20"
          : "top-full bg-white bg-opacity-0"
      } fixed bottom-0 min-[500px]:left-1/2 left-0 min-[500px]:-translate-x-1/2 w-full right-0 bg-black bg-opacity-30 overscroll-none flex items-end z-50 max-h-screen max-w-[500px] transition delay-150 duration-300`}
      id="modal_wrapper"
      onClick={handleClick}
    >
      <form
        className={`${
          isClicked === "add"
            ? "translate-y-0 opacity-100"
            : "translate-y-full opacity-0"
        } px-4 pt-6 pb-7 bg-white w-full transition-all delay-75 duration-300`}
        onSubmit={handleSubmit}
      >
        <h4 className="text-brendColor text-center text-lg font-semibold mb-6">
          {t("warehouseProducts.addProduct")}
        </h4>
        <p className="text-mainText text-lg font-semibold mb-4 capitalize">
          {product_name}
        </p>
        <div className="flex gap-x-4 mb-6">
          <span className="relative">
            <MainInput
              type={"number"}
              name={"product_count1"}
              title={t("warehouseProducts.quantity")}
              required={true}
            ></MainInput>
            <span className="text-mainText text-lg absolute bottom-[15%] right-3 lowercase">
              {measure}
            </span>
          </span>
          <span className="relative">
            <MainInput
              type={"number"}
              name={"product_price1"}
              title={t("warehouseProducts.price")}
              value={product_price}
              required={true}
            ></MainInput>
            <span className="text-mainText text-lg absolute bottom-[15%] right-3 uppercase">
              usd
            </span>
          </span>
        </div>
        <label className="mb-14 block">
          <span className="text-textDark text-base mb-2">
            {t("warehouseProducts.received")}
          </span>
          <InputDate mobile={false} value={created_at}></InputDate>
        </label>
        <MainButton type={"submit"} bgColor={"#702DE3"} icon={PlusIcon}>
          {t("warehouseProducts.addStore")}
        </MainButton>
      </form>
    </div>
  );
}
