import { createContext } from "react";

import { toast } from "react-toastify";

const Context = createContext();

function Provider({ children }) {
  const notify = (message, status) => {
    if (status === 200) {
      return toast.success(message);
    }

    if (status === 400) {
      return toast.error(message);
    }

    if (status === 300) {
      return toast.warning(message);
    }
  };
  return <Context.Provider value={notify}>{children}</Context.Provider>;
}

export { Provider, Context };
