import React, { useState, useEffect } from "react";
import { Filter } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import BottomSheet from "../../components/BottomSheet/BottomSheet";
import { Search, CloseIcon } from "../../assets/images";
import { baseUrl } from "../../baseUrl";
import Moment from "react-moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useFetch from "../../hooks/useFetch";
import { useTranslation } from "react-i18next";
import { searchData } from "../../utils/SearchData";

const filterData = [
  { id: 1, title: "Barchasi" },
  { id: 2, title: "Do'kon", status: "DO'KON" },
  { id: 3, title: "LaStoria", status: "LASTORIA" },
];

export default function GipyurHistory() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [isClicked, setIsClicked] = useState(false);

  const [search, setSearch] = useState(filterData[0]);

  const [offset, setOffset] = useState(0);

  const [value, setValue] = useState("");

  const [data, setData] = useState([]);

  const limit = 6;

  const gipyurs = useFetch(
    baseUrl + `/distribution/all?limit=${limit}&offset=${limit * offset}`
  );

  useEffect(() => {
    if (gipyurs?.data?.length) {
      setData([...data, ...gipyurs?.data]);
    }
  }, [gipyurs?.data]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (
        scrollTop + clientHeight >= scrollHeight &&
        gipyurs?.count >= offset * limit
      ) {
        setOffset(offset + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offset, gipyurs?.count]);

  const handleClick = (el, e) => {
    setSearch(el);
    setIsClicked(false);
  };

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  return (
    <div className="flex flex-col h-screen bg-[#F8F5F5]">
      <div
        className="pb-4 px-4 bg-white pt-10 relative"
        style={{ boxShadow: "0px 12px 14px rgba(209, 212, 226, 0.157261)" }}
      >
        <div className="text-center mb-10 relative">
          <span
            className="h-8 w-8 cursor-pointer rounded-full text-lg text-[#354B91]  top-1/2 -translate-y-1/2 left-1 border border-[#354B91] flex justify-center items-center absolute"
            onClick={() => navigate(-1)}
          >
            &#10094;
          </span>
          <h1 className="text-xl text-brendColor">
            <span>{t("gipyurDistribution.distributionHistory")}</span>
          </h1>

          <img
            className="absolute right-2 top-1.5 cursor-pointer"
            src={Filter}
            alt="Just an icon"
            onClick={() => setIsClicked(true)}
          />
        </div>
      </div>
      <div className="px-4 pt-6">
        <label className="relative block mb-6">
          <img
            className="absolute left-3 top-1/2 -translate-y-1/2"
            src={Search}
            alt="Just an icon"
          />
          <input
            className="border border-[#AFBACA] outline-none py-3 block w-full rounded-md px-12"
            type="text"
            value={value}
            placeholder={t("search")}
            onChange={(e) => setValue(e.target.value)}
          />
          {value ? (
            <img
              className="absolute right-3 top-1/2 -translate-y-1/2"
              src={CloseIcon}
              alt="Just an icon"
              onClick={() => setValue("")}
            />
          ) : (
            ""
          )}
        </label>
        <ul className="grid grid-cols-2 gap-4">
          {data?.length > 0 &&
            searchData(
              value,
              data?.map((el) => {
                el.title = el?.gipyur_code;
                return el;
              })
            )?.map((el) => (
              <li
                className="bg-white rounded-main pb-3 h-full"
                key={el?.id}
                // onClick={() => navigate("/gipyurInfo/" + el?.id)}
              >
                <LazyLoadImage
                  className="rounded-main mb-3 w-full max-h-[120px] h-full"
                  src={baseUrl + el?.gipyur_image}
                  alt="Gipyur info card pic"
                  onError={handleImageError}
                ></LazyLoadImage>
                <span className="text-textDark font-semibold block px-3">
                  # {el?.gipyur_code}
                </span>
                <ul className="flex flex-col gap-y-0.5 px-3">
                  <li className="text-textDark">
                    <span className="capitalize">{el?.distributed_place}</span>:{" "}
                    <span className="font-bold">{el?.gipyur_metr}</span>
                  </li>
                  <li className="text-textDark">
                    Omborda:{" "}
                    <span className="font-bold">
                      <Moment format="YYYY/MM/DD">{el?.created_at}</Moment>
                    </span>
                  </li>
                  <li className="text-textDark">
                    Soat: <span className="font-bold">{el?.time}</span>
                  </li>
                </ul>
              </li>
            ))}
        </ul>
      </div>

      <BottomSheet
        isClicked={isClicked}
        setIsClicked={setIsClicked}
        data={filterData}
        handleClick={handleClick}
        lang={search}
      ></BottomSheet>
    </div>
  );
}
