import React, { useState } from "react";
import Header from "../Header/Header";
import MainButton from "../MainButton/MainButton";
import MainInput from "../MainInput/MainInput";
import SelectBox from "../SelectBox/SelectBox";
import { EditPen, Warning, DeleteIcon, Camera } from "../../assets/images";
import { baseUrl } from "../../baseUrl";
import axios from "axios";
import useToken from "../../hooks/useToken";
import useFetch from "../../hooks/useFetch";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

export default function EditProduct({
  product_id,
  productName,
  product_image,
  product_type,
  productCode,
  productWarning,
  isClicked,
  setIsClicked,
  refetch,
}) {
  const [token] = useToken();

  const [showAlert] = useAlert();

  const [_, setIsLoading] = useLoading();

  const [imgUrl, setImgUrl] = useState(
    product_image ? baseUrl + product_image : "" || ""
  );

  const { t } = useTranslation();

  const [image, setImage] = useState(null);

  const [categoryId, setCategoryId] = useState();

  const categories = useFetch(baseUrl + "/product-category/all");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (e) => {
    let formData = new FormData();
    if (e.target.files[0]) {
      setImgUrl(URL.createObjectURL(e.target?.files[0] || {}));
      formData.append("file", e.target.files[0]);

      try {
        axios
          .post(baseUrl + "/file-upload", formData, {
            method: "POST",
            headers: {
              Authorization: `mme:${token}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            setImage(res?.data.image);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let { product_name, product_code, product_warning } = e.target.elements;

    let updatedProduct = {
      id: product_id,
      product_image: image ? image : product_image,
      product_name: product_name.value,
      warehouse_product_category_id: categoryId || product_type,
      product_code: product_code.value,
      product_count_warning_limit: +product_warning.value,
    };

    if (
      updatedProduct.product_name !== productName ||
      updatedProduct.product_code !== productCode ||
      updatedProduct.product_count_warning_limit !== productWarning ||
      image
        ? image !== product_image
        : false || categoryId
        ? categoryId !== product_type
        : false
    ) {
      setIsLoading(true);
      axios
        .put(baseUrl + "/product/update", updatedProduct, {
          method: "PUT",
          headers: {
            Authorization: `mme:${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            showAlert(
              "Muvafaqqiyatli!",
              "Jarayon muvafaqqiyatli tugallandi!",
              "success"
            );
            setIsLoading(false);
            refetch();
            setImgUrl();
            setIsClicked(null);
          }
        });
    } else {
      setIsLoading(false);
      showAlert("Ogohlantirish!", "Hech narsa o'zgarmadi!", "warning");
    }
  };

  const handleClick = (e) => {
    setIsClicked(null);
  };

  const handleDeleteClick = () => {
    axios
      .delete(baseUrl + `/product/one?id=${product_id}`, {
        method: "PUT",
        headers: {
          Authorization: `mme:${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          showAlert(
            "Muvafaqqiyatli!",
            "Obyekt muvafaqqiyatli olib tashlandi!",
            "success"
          );
          refetch();
          setIsModalOpen(false);
          setIsClicked(null);
        }
      })
      .catch((error) => {
        showAlert("Xatolik!", "Qaytadan urinib ko'ring!", "error");
      });
  };

  return (
    <div
      className={`${
        isClicked === "edit"
          ? "top-0 translate-y-0"
          : "-top-full -translate-y-full"
      } fixed bottom-0 overflow-y-auto right-0 left-0 h-full bg-white min-[500px]:left-1/2 min-[500px]:-translate-x-1/2 max-w-[500px] w-full duration-500 transition-all`}
    >
      <Header
        heading={t("warehouseProducts.editProduct.heading")}
        icon={true}
        navPath="/warehouseProducts"
        handleEditClick={handleClick}
      ></Header>
      <form
        className="bg-white pt-3 px-4 relative pb-5"
        onSubmit={handleSubmit}
      >
        <p className="text-base text-textDark mb-2.5 text-left">
          {t("warehouseProducts.editProduct.changeImage")}
        </p>
        <label className="w-36 h-36 block mx-auto mb-5">
          <span className="border-dashed border-2 border-[#E6E6EA] w-36 h-36 flex justify-center items-center mx-auto relative">
            <input
              className="hidden"
              type="file"
              name="productImage"
              onChange={handleChange}
              accept=".png, .jpg, .jpeg, .webp"
            />
            <img className="object-cover" src={Camera} alt="" />
            {imgUrl?.length || product_image ? (
              <>
                <img
                  className="absolute top-0 bottom-0 right-0 left-0 w-full h-full object-cover"
                  src={
                    imgUrl && !product_image
                      ? imgUrl
                      : !imgUrl && product_image
                      ? baseUrl + product_image
                      : imgUrl
                  }
                  alt=""
                />
                <span
                  className="absolute right-0.5 top-0.5 text-white w-5 h-5 text-3xl leading-4"
                  onClick={() => {
                    setImgUrl({});
                    setImage(null);
                  }}
                >
                  &#9746;
                </span>
              </>
            ) : (
              ""
            )}
          </span>
        </label>
        <MainInput
          title={t("warehouseProducts.editProduct.choosenProduct")}
          margin={"20px"}
          name={"product_name"}
          type={"text"}
          value={productName}
        ></MainInput>
        <SelectBox
          data={categories}
          label={t("addNewProduct.productType")}
          margin={"20px"}
          name={"productType"}
          optionHeading={"Mahsulot turi"}
          setCategoryId={setCategoryId}
          newValue={product_type}
        ></SelectBox>
        <MainInput
          title={t("addNewProduct.productCode")}
          placeholder={"Xf1234567812we34"}
          margin={"20px"}
          code={true}
          type={"text"}
          value={productCode}
          name={"product_code"}
        ></MainInput>
        <MainInput
          title={t("addNewProduct.warningLimit")}
          placeholder={"Limit"}
          type={"number"}
          name={"product_warning"}
          value={productWarning}
        ></MainInput>
        <div className="flex gap-x-7 mt-10">
          <MainButton
            bgColor={"#E24B4B"}
            icon={DeleteIcon}
            type={"button"}
            handleClick={() => setIsModalOpen(!isModalOpen)}
          >
            {t("warehouseProducts.delete")}
          </MainButton>
          <MainButton bgColor={"#702DE3"} icon={EditPen} type={"submit"}>
            {t("warehouseProducts.edit")}
          </MainButton>
        </div>
      </form>

      {isModalOpen ? (
        <div className="fixed top-0 right-0 left-0 bottom-0 bg-black px-4 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white w-full px-4 py-6 rounded-main">
            <img
              className="block mx-auto mb-6"
              src={Warning}
              alt="warning icon"
            />
            <p className="text-center mb-6 text-base text-textDark max-w-[220px] mx-auto">
              {t("warehouseProducts.modalText")}
            </p>
            <div className="flex w-full gap-x-4">
              <MainButton
                bgColor={"#72809D"}
                handleClick={() => setIsModalOpen(false)}
              >
                {t("warehouseProducts.cancel")}
              </MainButton>
              <MainButton bgColor={"#702DE3"} handleClick={handleDeleteClick}>
                {t("warehouseProducts.confirm")}
              </MainButton>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
