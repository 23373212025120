import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import useFetch from "../../hooks/useFetch";
import MainButton from "../../components/MainButton/MainButton";
import { baseUrl } from "../../baseUrl";
import { DownArrow } from "../../assets/images";
import CurrencyCalculator from "../../components/CurrencyCalculator/CurrencyCalculator";
import { Search, CloseIcon, Profile } from "../../assets/images";
import useUserId from "../../hooks/useUserId";
import Moment from "react-moment";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useNavigate } from "react-router-dom";
import useRole from "../../hooks/useRole";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

export default function ClosePartnerDebt() {
  const [role] = useRole();

  const { t } = useTranslation();

  const [showAlert] = useAlert();

  const [_, setIsLoading] = useLoading();

  const [partner, setPartner] = useState();

  const inputRef1 = useRef(null);

  const inputRef2 = useRef(null);

  const [isClicked, setIsClicked] = useState(false);

  const [value, setValue] = useState("");

  const [userId] = useUserId();

  const [token] = useToken();

  const navigate = useNavigate();

  const partners = useFetch(baseUrl + "/partner/debts/all");

  useEffect(() => {
    if (partners?.length) {
      setPartner(partners[0]);
    }
  }, [partners]);

  const currency = useFetch(baseUrl + "/other/central-bank-usd-rate");

  const handleClick = (e) => {
    if (e.target.id === "modal_wrapper" || e.target.id === "clicker") {
      setIsClicked(false);
    } else {
      setIsClicked(true);
    }
  };

  function searchProduct(inputValue, data) {
    let regex = new RegExp(
      `[\\p{Latin}\\p{Cyrillic}]*${inputValue}[\\p{Latin}\\p{Cyrillic}]*`,
      "gi"
    );
    const filterInput = data?.filter((product) =>
      product?.partner_name.match(regex)
    );
    return filterInput;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let { usd, uzs } = e.target.elements;

    let isAmountValid =
      +usd.value + uzs.value / currency?.usd_rate + partner?._money_;

    if (isAmountValid > 0) {
      return alert(
        `Qarzdorlikdan ortiqcha summa kiritildi ! \n Ortiqcha: ${isAmountValid}`
      );
    }

    let debt = {
      user_id: userId,
      given_by_whom: role?.role,
      id: partner?.id,
      money_usd: +usd.value || 0,
      money_uzs: +uzs.value || 0,
      central_bank_usd_course: currency?.usd_rate,
      partner_debt: partner?._money_,
      selected_currency: partner?.selected_currency,
    };

    if (usd.value || uzs.value) {
      setIsLoading(true);
      axios
        .post(baseUrl + "/partner/give-money", debt, {
          method: "POST",
          headers: {
            Authorization: `mme:${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data?.data?.id) {
            setIsLoading(false);
            showAlert(
              "Muvafaqqiyatli!",
              "Jarayon muvafaqqiyatli tugallandi...!",
              "success"
            );
            navigate("/report");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          showAlert(
            "Xatolik !",
            "Xatolik yuz berdi, qaytadan urinib ko'ring!",
            "error"
          );
        });
    }
  };

  return (
    <>
      <div className="flex flex-col h-screen">
        <Header
          icon={true}
          heading={t("pages.closePartnersDebt")}
          navPath={-1}
        ></Header>
        <form
          className="pt-5 bg-white px-4 flex flex-col grow justify-between pb-4"
          onSubmit={handleSubmit}
        >
          <div>
            <div
              className="text-base mb-5"
              onClick={() => setIsClicked(!isClicked)}
            >
              <span className="text-textDark mb-1.5 block">
                {t("closePartnersDebt.form.name")}
              </span>
              <div className="text-textSilver flex justify-between items-center text-base capitalize border border-[#D7E1EA] p-4 rounded-md">
                <span>{partner?.partner_name}</span>
                <span>{partner?._money_}</span>
                <img src={DownArrow} alt="Just an icon" />
              </div>
            </div>
            <CurrencyCalculator
              currency={currency?.usd_rate}
            ></CurrencyCalculator>
            <div className="mt-4">
              <span className="text-base text-textDark mb-1.5 block">
                {t("closePartnersDebt.form.quantity")}
              </span>
              <div className="w-full flex items-center gap-x-2">
                <label className="relative w-1/2">
                  <input
                    className="pl-3 w-full pr-14 text-base py-4 rounded-md text-textSilver border border-[#D7E1EA] outline-none "
                    type="number"
                    name="usd"
                    ref={inputRef1}
                  />
                  <span className="absolute right-3  top-1/2 -translate-y-1/2 text-lg text-mainText font-semibold">
                    USD
                  </span>
                </label>
                <label className="relative w-1/2">
                  <input
                    className="pl-3 w-full pr-14 py-4 text-base rounded-md text-textSilver border border-[#D7E1EA] outline-none "
                    type="number"
                    name="uzs"
                    ref={inputRef2}
                  />
                  <span className="absolute right-3 top-1/2 -translate-y-1/2 text-lg text-mainText font-semibold">
                    UZS
                  </span>
                </label>
              </div>
            </div>
          </div>
          <MainButton type={"submit"}>{t("submit")}</MainButton>
        </form>
      </div>

      <div
        className={`${
          isClicked
            ? "top-0 bg-black bg-opacity-25"
            : "top-full bg-white bg-opacity-0"
        } fixed transition  duration-300 delay-75 z-10 bottom-0 min-[500px]:left-1/2 min-[500px]:-translate-x-1/2 max-w-[500px] w-full left-0 right-0 flex justify-center items-end`}
        id="modal_wrapper"
        onClick={handleClick}
      >
        <div
          className={`${
            isClicked ? "translate-y-0" : "translate-y-full"
          } bg-white w-full flex flex-col py-5 px-4 transition-all delay-75 ease-in-out h-3/4`}
        >
          <label className="relative block mb-6">
            <img
              className="absolute left-3 top-1/2 -translate-y-1/2"
              src={Search}
              alt="Just an icon"
            />
            <input
              className="border border-[#AFBACA] outline-none py-3 block w-full rounded-md px-12"
              type="text"
              value={value}
              placeholder={t("search")}
              onChange={(e) => setValue(e.target.value)}
            />
            <img
              className="absolute right-3 top-1/2 -translate-y-1/2"
              src={CloseIcon}
              alt="Just an icon"
              onClick={() => setValue("")}
            />
          </label>
          <ul className="flex flex-col gap-y-2.5 overflow-y-auto">
            {partners?.length > 0 &&
              searchProduct(value, partners).map((el) => (
                <li
                  className="w-full py-2.5 px-3 flex items-center rounded-main cursor-pointer relative border border-[#E6E6EA]"
                  key={el?.id}
                  style={{ boxShadow: "0px 3px 15px rgba(13, 14, 43, 0.05)" }}
                  onClick={() => {
                    setPartner(el);
                  }}
                  name="item"
                >
                  <span
                    className="absolute top-0 right-0 bottom-0 left-0 z-50"
                    id="clicker"
                  ></span>
                  <span
                    className="w-16 h-16 rounded-full flex justify-center items-center mr-3.5"
                    style={{ boxShadow: "0px 3px 10px rgba(13, 14, 43, 0.11)" }}
                  >
                    <img
                      className="w-6 h-7 object-contain"
                      src={Profile}
                      alt="Just an icon"
                    />
                  </span>
                  <div>
                    <p>
                      <span className="text-base text-textSilver font-medium mr-2.5">
                        {t("profile.message.name")}:
                      </span>
                      <span className="text-base text-textDark capitalize">
                        {el?.partner_name}
                      </span>
                    </p>
                    <p>
                      <span className="text-base text-textSilver font-medium mr-2.5">
                        {t("home.debt")}:
                      </span>
                      <span className="text-base text-textDark">
                        {el?._money_}
                        <span className="text-mainText font-medium text-base ml-1.5">
                          USD
                        </span>
                      </span>
                    </p>
                    <p>
                      <span className="text-base text-textSilver font-medium mr-2.5">
                        {t("addNewPartner.date")}:
                      </span>
                      <span className="text-base text-textDark">
                        <Moment format="YYYY/MM/DD">{el?.created_at}</Moment>
                      </span>
                    </p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
}
