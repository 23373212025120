import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import ButtonToggle from "../../components/ButtonToggle/ButtonToggle";
import {
  Dress,
  Eraser,
  Calender,
  Dye,
  Dollar,
  GivenMoney,
  Agreement,
} from "../../assets/images";
import { getDate } from "../../utils/getDate";
import useRole from "../../hooks/useRole";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";

export default function AnOrderPage() {
  const { id } = useParams();

  const { t } = useTranslation();

  const [role] = useRole();

  const [token] = useToken();

  const [showAlert] = useAlert();

  const navigate = useNavigate();

  let path = id.split("_")[0];

  let url = id.split("_")[1];

  const [isClicked, setIsClicked] = useState(false);

  const [selectedImg, setSelectedImg] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const order = useFetch(baseUrl + "/dress-order/" + (path || id));

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  const handleClick = (e) => {
    if (e.target.name === "accept") {
      let updated = {
        id: path,
        accept: true,
        reject_description: "",
      };
      axios
        .put(baseUrl + "/dress-order/accept", updated, {
          method: "PUT",
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setIsModalOpen(false);
          }
        })
        .catch((err) =>
          showAlert("Xatolik!", "Xatolik yuz berdi...", "success")
        );
    }
    if (e.target.name === "reject") {
      setIsModalOpen(true);
    }

    if (e.target.id === "modal_wrapper") {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }

    if (e.target.id === "close") {
      setIsModalOpen(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { description } = e.target.elements;

    let updated = {
      id: path,
      accept: false,
      reject_description: description.value || "",
    };

    axios
      .put(baseUrl + "/dress-order/accept", updated, {
        headers: {
          Authorization: "mme:" + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          showAlert("Muvafaqqiyatli!", "Buyurtma rad etildi", "success");
          navigate("/orders");
        }
      })
      .catch((err) => showAlert("Xatolik!", "Xatolik yuz berdi!", "error"));
  };

  const handleReady = () => {
    axios
      .put(
        baseUrl + "/dress-order/ready",
        { id, ready: true },
        {
          headers: {
            Authorization: "mme:" + token,
          },
        }
      )
      .then((res) => {
        if (res?.data?.data?.id) {
          showAlert(
            "Muvaqqiyaltli!",
            "Jarayon muvafaqqiyatli yakunlandi !",
            "success"
          );
        }
      })
      .catch((err) => console.log(err));

    navigate("/orders");
  };

  return (
    <div className="flex flex-col bg-white h-screen">
      <Header
        heading={t("ordersPage.card.order") + " " + "№ " + order?.order_number}
        icon={true}
        navPath={url === "allSold" ? "/allSold" : "/ordersList"}
      ></Header>
      <div className="px-4 pt-6">
        <ButtonToggle
          isClicked={isClicked}
          setIsClicked={setIsClicked}
          toggle1={t("ordersPage.card.aboutOrder")}
          toggle2={t("ordersPage.card.images")}
        ></ButtonToggle>
      </div>
      {!isClicked ? (
        <div className="bg-white pb-7">
          <ul className="flex flex-col gap-y-2 px-4 pt-6 mb-9">
            <li className="flex gap-x-2 items-center border-b border-[#E6E6EA] py-3">
              <img src={Dress} alt="Just an icon" />
              <div className="flex flex-col">
                <span className="text-sm text-textSilver mb-1">
                  {t("ordersPage.card.dressName")}
                </span>
                <span className="text-textDark font-medium text-xs">
                  {order?.dress_name}
                </span>
              </div>
            </li>
            <li className="flex gap-x-2 items-center border-b border-[#E6E6EA] py-3">
              <img src={Eraser} alt="Just an icon" />
              <div className="flex flex-col">
                <span className="text-sm text-textSilver mb-1">
                  {t("ordersPage.card.shleft")}
                </span>
                <span className="text-textDark font-medium text-xs">
                  {order?.shleft_size}
                </span>
              </div>
            </li>
            <li className="flex gap-x-2 items-center border-b border-[#E6E6EA] py-3">
              <img src={Dye} alt="Just an icon" />
              <div className="flex flex-col">
                <span className="text-sm text-textSilver mb-1">
                  {t("ordersPage.card.color")}
                </span>
                <span className="text-textDark font-medium text-xs">
                  {order?.dress_color}
                </span>
              </div>
            </li>
            <li className="flex gap-x-2 items-center border-b border-[#E6E6EA] py-3">
              <img src={Dollar} alt="Just an icon" />
              <div className="flex flex-col">
                <span className="text-sm text-textSilver mb-1">
                  {t("ordersPage.card.price")}
                </span>
                <span className="text-textDark font-medium text-xs">
                  {order?.price}
                </span>
              </div>
            </li>
            <li className="flex gap-x-2 items-center border-b border-[#E6E6EA] py-3">
              <img src={GivenMoney} alt="Just an icon" />
              <div className="flex flex-col">
                <span className="text-sm text-textSilver mb-1">
                  {t("ordersPage.card.money")}
                </span>
                <span className="text-textDark font-medium text-xs">
                  {order?.money_salon_usd} USD
                </span>
                <span className="text-textDark font-medium text-xs">
                  {order?.money_salon_uzs} UZS
                </span>
              </div>
            </li>
            <li className="flex gap-x-2 items-center border-b border-[#E6E6EA] py-3">
              <img src={Agreement} alt="Just an icon" />
              <div className="flex flex-col">
                <span className="text-sm text-textSilver mb-1">
                  {t("ordersPage.card.delivery")}
                </span>
                <span className="text-textDark font-medium text-xs">
                  {order?.must_be_delivered ? "Ha" : "Yo'q"}
                </span>
              </div>
            </li>
            <li className="flex gap-x-2 items-center border-b border-[#E6E6EA] py-3">
              <img
                src={Calender}
                alt="Just an icon"
                style={{
                  filter:
                    "invert(38%) sepia(100%) saturate(4400%) hue-rotate(245deg)",
                }}
              />
              <div className="flex flex-col">
                <span className="text-sm text-textSilver mb-1">
                  {t("ordersPage.card.date")}
                </span>
                <span className="text-textDark font-medium text-xs">
                  {getDate(order?.delivery_date)}
                </span>
              </div>
            </li>
          </ul>
          <div className="px-4 bg-white pb-10">
            <span className="font-medium text-textSilver block mb-4 border-b border-[#E6E6EA] pb-3">
              {t("ordersPage.card.desc")}
            </span>
            <p>{order?.description}</p>
          </div>
          {role?.status === 2 && !order?.accepted ? (
            <div className="flex justify-between gap-x-4 px-4 mt-5">
              <button
                className="rounded-md p-3.5 text-whitish text-base w-full flex items-center justify-center bg-[#E24B4B]"
                onClick={handleClick}
                name="reject"
              >
                Rad etish
              </button>
              <button
                className="rounded-md p-3.5 text-whitish text-base w-full flex items-center bg-[#702DE3] justify-center"
                onClick={handleClick}
                name="accept"
              >
                Qabul qilish
              </button>
            </div>
          ) : role?.status === 2 && order?.accepted ? (
            <div className="px-4">
              <button
                className="rounded-md p-3.5 text-whitish text-base w-full flex items-center bg-[#702DE3] justify-center"
                onClick={handleReady}
              >
                Jarayon tugallandi
              </button>
            </div>
          ) : (
            ""
          )}

          {isModalOpen ? (
            <div
              className={`${
                isModalOpen
                  ? "top-0 bg-black bg-opacity-20"
                  : "top-full bg-white bg-opacity-0"
              } fixed transition duration-300 delay-75 z-10 bottom-0 min-[500px]:left-1/2 min-[500px]:-translate-x-1/2 max-w-[500px] w-full left-0 right-0 flex justify-center items-end`}
              id="modal_wrapper"
              onClick={handleClick}
            >
              <form
                className={`bg-white w-full px-4 flex flex-col py-8 ${
                  isModalOpen ? "translate-y-0" : "translate-y-full"
                } transition-all duration-300 ease-in-out`}
                onSubmit={handleSubmit}
              >
                <label>
                  <span className="block mb-2 text-textSilver">
                    Rad etish to'g'risida izoh qoldiring...!
                  </span>
                  <textarea
                    className="w-full min-h-[150px] py-3.5 px-3 block mb-5 border-[#D7E1EA] border"
                    name="description"
                  ></textarea>
                  <div className="flex justify-between items-center gap-x-4">
                    <button
                      className="rounded-md p-3.5 text-whitish text-base w-full flex items-center justify-center bg-[#E24B4B]"
                      type="button"
                      id="close"
                    >
                      Ortga qaytish
                    </button>
                    <button
                      className="rounded-md p-3.5 text-whitish text-base w-full flex items-center bg-[#702DE3] justify-center"
                      type="submit"
                    >
                      Yuborish
                    </button>
                  </div>
                </label>
              </form>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <ul className="bg-white px-4 pt-6 grid grid-cols-2 [&>*:nth-child(3n)]:col-span-2 gap-4">
          {order?.order_dress_images?.length > 0 &&
            order?.order_dress_images.map((el, id) => (
              <li
                className="rounded-main w-[163] h-[198] col-span-1"
                key={id}
                onClick={() => setSelectedImg(baseUrl + el)}
              >
                <img
                  className="object-cover w-full h-full rounded-main"
                  src={baseUrl + el}
                  alt="ordered products pics"
                  width={163}
                  height={198}
                  onError={handleImageError}
                />
              </li>
            ))}
        </ul>
      )}

      <div
        className={`${
          selectedImg ? "scale-100" : "scale-0"
        } fixed bottom-0 right-0 flex left-0 top-0 bg-black ease-out bg-opacity-30 justify-center items-center px-4 transition duration-300`}
        onClick={() => setSelectedImg()}
      >
        <div className={` w-full h-auto `}>
          <img
            className="w-full h-auto rounded-main"
            src={selectedImg}
            alt="pic"
          />
        </div>
      </div>
    </div>
  );
}
