import React from "react";

import { Context } from "../context/Autentication";

const useRole = (setterOnly = false) => {
  const ctx = React.useContext(Context);
  return setterOnly ? [ctx.setUserRole] : [ctx.userRole, ctx.setUserRole];
};

export default useRole;
