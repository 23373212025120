import React from "react";
import Header from "../../components/Header/Header";
import ReportCard from "../../components/ReportCard/ReportCard";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import useRole from "../../hooks/useRole";
import { GivenMoney, Receipt } from "../../assets/images";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/formatNumber";

export default function ViewReport() {
  const [role] = useRole();

  const { t } = useTranslation();

  const data = useFetch(
    baseUrl +
      (role?.status === 2
        ? "/report/warehouse-details"
        : "/report/salon-details")
  );

  return (
    <>
      <Header
        heading={t("pages.viewReport")}
        icon={true}
        navPath="/report"
      ></Header>
      <div className="bg-white pb-7 h-full">
        <div className="px-4 pt-6 bg-white">
          {role?.status === 2 ? (
            <div className="rounded-main border border-[#C0C7D4] py-4 flex items-center px-7">
              <img
                className="w-8 h-8 mr-3"
                src={GivenMoney}
                alt="just an icon"
                width={32}
                height={32}
              />
              <div className="flex flex-col gap-y-4">
                <p className="text-textDark text-base">
                  Tasdiqlangan buyurtma:
                </p>
                <span className="font-semibold text-base">
                  {data?.ready_orders_count}{" "}
                  <span style={{ color: "#354B91" }}>
                    {t("director.count")}
                  </span>
                </span>
              </div>
            </div>
          ) : (
            <ul className="rounded-main border border-[#C0C7D4] py-4 flex flex-col gap-y-4 px-7">
              <li className="flex gap-x-3 items-center">
                <img
                  className="shrink-0 object-contain w-8 h-8"
                  src={GivenMoney}
                  alt="Just an icon"
                />
                <div className="flex flex-col gap-y-1.5 font-medium">
                  <span className="text-textSilver">
                    {t("home.seller.todaysTrade")}:
                  </span>
                  <span className="text-textDark">
                    {formatNumber(data?.todays_sale?.todays_sale_usd)} USD
                  </span>
                  <span className="text-textDark">
                    {formatNumber(data?.todays_sale?.todays_sale_uzs)} UZS
                  </span>
                </div>
              </li>
              <li className="flex gap-x-3 items-center">
                <img
                  className="shrink-0 object-contain w-8 h-8"
                  src={GivenMoney}
                  alt="Just an icon"
                />
                <div className="flex flex-col gap-y-1.5 font-medium">
                  <span className="text-textSilver">
                    {t("home.seller.cashMoney")}:
                  </span>
                  <span className="text-textDark">
                    {formatNumber(data?.todays_cash?.todays_cash_usd)} USD
                  </span>
                  <span className="text-textDark">
                    {formatNumber(data?.todays_cash?.todays_cash_uzs)} UZS
                  </span>
                </div>
              </li>
              <li className="flex gap-x-3 items-center">
                <img
                  className="shrink-0 object-contain w-8 h-8"
                  src={Receipt}
                  alt="Just an icon"
                />
                <div className="flex flex-col gap-y-1.5 font-medium">
                  <span className="text-textSilver">{t("home.debt")}:</span>
                  <span className="text-textDark">
                    {formatNumber(data?.debt_usd)} USD
                  </span>
                </div>
              </li>
            </ul>
          )}
          {data?.reports?.length &&
            data?.reports.map(
              (el, i) =>
                el?.reports?.length > 0 && (
                  <div className="" key={i}>
                    <h4 className=" text-xl text-textDark text-center font-medium mb-6 mt-8">
                      {el.title}
                    </h4>
                    <ul className="flex flex-col gap-y-4">
                      {el?.reports?.length &&
                        el?.reports.map((it, i) => (
                          <li key={i}>
                            <ReportCard
                              name={it?.name_}
                              price_usd={it?.usd}
                              price_uzs={it?.uzs}
                              time={it?.time_}
                            ></ReportCard>
                          </li>
                        ))}
                    </ul>
                  </div>
                )
            )}
        </div>
      </div>
    </>
  );
}
