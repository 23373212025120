import React, { useRef } from "react";
import { CallSvg } from "../../assets/images";

export default function LoginInput({
  type,
  placeholder,
  width,
  name,
  title,
  variant,
  required = false,
  handleChange,
}) {
  const spanRef = useRef(null);

  const handleFocus = (e) => {
    spanRef.current.style.color = "#000";
    e.target.style.borderColor = "#D7E1EA";
  };
  return (
    <label className="relative">
      <span
        className="absolute -top-7 block text-base text-white"
        style={{ color: variant === "mainInput" ? "#000" : "" }}
      >
        {title}
      </span>
      <input
        className={`block w-full py-3 px-4 placeholder:text-[#C0C7D4] text-base rounded-md outline-none ${
          variant === "mainInput" ? "border border-[#D7E1EA]" : ""
        }`}
        type={type}
        name={name}
        placeholder={placeholder}
        onFocus={handleFocus}
        onChange={handleChange}
        maxLength={type === "tel" ? 9 : ""}
        required={required}
        style={{
          width: width ? width : "",
          paddingLeft: type === "tel" ? "100px" : "",
          letterSpacing: type === "tel" ? "0.025em" : "",
        }}
      />
      {type === "tel" ? (
        <span className="flex absolute left-0 top-0 h-full">
          <span
            className={`w-12 bg-[#EEF2FF] rounded-md flex justify-center items-center ${
              variant === "mainInput" ? "border border-[#D7E1EA]" : ""
            }`}
          >
            <img className="" src={CallSvg} alt="call icon" />
          </span>
          <span
            className="ml-3 text-base my-auto text-[#C0C7D4] tracking-wide"
            ref={spanRef}
          >
            +998
          </span>
        </span>
      ) : (
        ""
      )}
    </label>
  );
}
