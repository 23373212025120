import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { Clock } from "../../assets/images";
import GipyurButtonSheet from "../../components/BottomSheet/GipyurButtonSheet";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import CurrencyCalculator from "../../components/CurrencyCalculator/CurrencyCalculator";
import MainInput from "../../components/MainInput/MainInput";
import ButtonSheetSimple from "../../components/BottomSheet/BottomSheetSimple";
import MainButton from "../../components/MainButton/MainButton";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useToken from "../../hooks/useToken";
import { useNotify } from "../../hooks/useNotify";
import { useNavigate } from "react-router-dom";
import PartnersButtonSheet from "../../components/BottomSheet/PartnersButtonSheet";
import { checkInputForDebt } from "../../utils/validateInput";
import axios from "axios";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

const places = [
  { id: 1, title: "Ombor", status: "gipyur_metr" },
  { id: 2, title: "Do'kon", status: "in_shop" },
];

export default function SellSimple() {
  const { t } = useTranslation();

  const gipyurs = useFetch(baseUrl + "/gipyur/all");

  const clients = useFetch(baseUrl + "/gipyur-client/all");

  const currency = useFetch(baseUrl + "/other/central-bank-usd-rate");

  const [_, setIsLoading] = useLoading();

  const notify = useNotify();

  const [showAlert] = useAlert();

  const navigate = useNavigate();

  const [token] = useToken();

  const [data, setData] = useState();

  const [sellFrom, setSellFrom] = useState(places[0]);

  const [client, setClient] = useState({});

  const [value, setValue] = useState();

  const [metr, setMetr] = useState();

  const [debt, setDebt] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();

    const elements = e.target.elements;

    const { cash_usd, cash_uzs, gipyur_metr, total_price } = e.target.elements;

    if (+data?.[sellFrom.status]) {
      if (+data?.[sellFrom.status] < +gipyur_metr.value) {
        alert(`${sellFrom.title}da yetarli gipyur mavjud emas!`);
        return notify("Qaytadan tekshiring", 300);
      }
    } else {
      return notify(`${sellFrom.title}da gipyur yo'q !`, 300);
    }

    let gipyurData = {
      gipyur_id: data?.id,
      gipyur_price: +data?.gipyur_price,
      gipyur_code: data?.gipyur_code,
      usd_rate: currency?.usd_rate,
      gipyur_client_id: client?.id,
    };

    for (let el of elements) {
      if (el.name) {
        gipyurData[el.name] = el.value || 0;
      }
      if (!isNaN(el?.value) && el.value && el.name) {
        gipyurData[el.name] = Number(el.value);
      }
    }

    gipyurData.sold_from = gipyurData?.sold_from.toUpperCase();

    if (!gipyurData?.cash_usd) {
      gipyurData.cash_usd = 0;
    }

    if (!gipyurData?.cash_uzs) {
      gipyurData.cash_uzs = 0;
    }

    if (
      checkInputForDebt(
        total_price.value,
        cash_usd,
        cash_uzs,
        currency?.usd_rate,
        showAlert
      )
    ) {
      return showAlert("Xatolik!", "Xatolik yuz berdi!", "error");
    } else {
      if (Object.values(gipyurData).length >= 9) {
        setIsLoading(true);
        axios
          .post(baseUrl + "/gipyur-sale/create", gipyurData, {
            method: "POST",
            headers: {
              Authorization: "mme:" + token,
            },
          })
          .then((res) => {
            if (res?.data?.data?.id) {
              setIsLoading(false);
              showAlert(
                "Muvaffaqiyatli !",
                "Jarayon muvaffaqiyatli tuggalandi...",
                "success"
              );
              navigate(-1);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            showAlert("Xatolik!", "Xatolik yuz berdi!", "error");
          });
      } else {
        showAlert("Ogohlantirish!", "Formani to'liq to'ldiring !", "warning");
      }
    }
  };

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  const handleChange = (e) => {
    let elVal = e?.target.value;
    let elName = e?.target.name;

    if (elName === "gipyur_metr") {
      setMetr(elVal);
      setValue(data?.gipyur_price * elVal);
    }

    if (elName === "gipyur_sold_price") {
      setValue(elVal * metr);
    }

    if (elName === "cash_usd") {
      if (!elVal) {
        return setDebt(value);
      }
      if (debt) {
        return setDebt(debt - elVal);
      } else if (elVal) {
        return setDebt(value - elVal);
      }
    }

    if (elName === "cash_uzs") {
      if (!elVal) {
        return setDebt(value);
      }
      if (debt) {
        return setDebt(debt - elVal / currency?.usd_rate);
      } else if (elVal) {
        return setDebt(value - elVal / currency?.usd_rate);
      }
    }
  };

  return (
    <div className="bg-white h-screen flex flex-col">
      <div className="relative">
        <Header
          heading={t("gipyurSelling.sellingForDebt.heading")}
          icon={true}
          navPath={-1}
        ></Header>
        <img
          className="absolute top-1/2 -translate-y-1/2 right-9 w-7 h-7"
          src={Clock}
          alt="Just an icon"
          onClick={() => navigate("/saleHistory")}
        />
      </div>
      <form
        className="px-4 pt-6 pb-4 flex-grow flex flex-col justify-between gap-y-6 bg-white"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-6">
          <div className="relative h-40 w-full bg-[#C0C7D4] flex justify-center items-center rounded-main">
            {data?.gipyur_image ? (
              <LazyLoadImage
                className="absolute top-0 bottom-0 left-0 right-0 w-full h-full rounded-main"
                src={baseUrl + data?.gipyur_image}
                alt="gipyur pic"
                onError={handleImageError}
              />
            ) : (
              <p className="text-[#354B91]">Gipyur rasmi</p>
            )}
          </div>
          <GipyurButtonSheet
            data={gipyurs}
            setData={setData}
          ></GipyurButtonSheet>
          <PartnersButtonSheet
            data={clients}
            setData={setClient}
          ></PartnersButtonSheet>
          <CurrencyCalculator
            currency={currency?.usd_rate}
          ></CurrencyCalculator>
          <div className="flex justify-between">
            <MainInput
              title={t("gipyurSelling.simpleSelling.form.salePrice")}
              name={"gipyur_sold_price"}
              type="number"
              currency={"usd"}
              value={data?.gipyur_price}
              handleChange={handleChange}
            ></MainInput>
            <MainInput
              title={t("gipyurSelling.simpleSelling.form.saleMetr")}
              name={"gipyur_metr"}
              type="number"
              currency={"m"}
              value={""}
              handleChange={handleChange}
            ></MainInput>
          </div>
          <MainInput
            title={t("gipyurSelling.simpleSelling.form.agreed")}
            name={"total_price"}
            type="number"
            currency={"usd"}
            value={value || ""}
            handleChange={handleChange}
          ></MainInput>
          <div className="">
            <span className="text-textDark block mb-1.5">
              {t("gipyurSelling.simpleSelling.form.takenMoney")}
            </span>
            <div className="flex justify-between items-center gap-x-2.5">
              <MainInput
                currency={"usd"}
                type="number"
                name={"cash_usd"}
                handleChange={handleChange}
              ></MainInput>
              <MainInput
                currency={"uzs"}
                type="number"
                name={"cash_uzs"}
                handleChange={handleChange}
              ></MainInput>
            </div>
          </div>
          <p className="text-textDark text-xl flex justify-between items-center">
            <span>Qolgan qarz:</span> <span>{debt}</span>
          </p>
          <ButtonSheetSimple
            title={t("gipyurSelling.simpleSelling.form.whereSold")}
            name1={"sold_from"}
            select={true}
            data={places}
            setData={setSellFrom}
          ></ButtonSheetSimple>
        </div>
        <MainButton type={"submit"}>{t("submit")}</MainButton>
      </form>
    </div>
  );
}
