import Swal from "sweetalert2";

const useAlert = () => {
  const showAlert = (title, text, status) => {
    Swal.fire(title, text, status);
  };

  const showConfirmation = (title, text, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Code to handle the confirmation action
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  return [showAlert, showConfirmation];
};

export default useAlert;
