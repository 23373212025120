import React, { useEffect, useState } from "react";
import DatePickerInput from "../components/DatePicker/DatePicker";
import DoughnutChart from "../components/Doughnut/Doughnut";
import Statistics from "../components/Statistics/Statistics";
import useFetch from "../hooks/useFetch";
import { baseUrl } from "../baseUrl";
import { Dollar } from "../assets/images";
import { getDatePickerDate } from "../utils/getDatePickerDate";
import useRole from "../hooks/useRole";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useUserId from "../hooks/useUserId";
import i18next from "i18next";
import { formatNumber } from "../utils/formatNumber";

export default function Home() {
  let today = new Date();

  let year = today.getFullYear();

  let month = String(today.getMonth() + 1).padStart(2, "0");

  let day = String(today.getDate()).padStart(2, "0");

  let formattedDate = `${year}-${month}-${day}`;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [userId] = useUserId();

  let salonData = [
    {
      id: 1,
      title: t("footer.orders"),
      color: "#1890FF",
      status: "orders",
      usd: {
        count: 100,
        label: "usd",
      },
      uzs: {
        count: 1000,
        label: "uzs",
      },
    },
    {
      id: 2,
      title: t("pages.simpleSale"),
      color: "#52C41A",
      status: "simple",
      usd: {
        count: 100,
        label: "usd",
      },
      uzs: {
        count: 1000,
        label: "uzs",
      },
    },
    {
      id: 3,
      title: t("pages.fiftyFifty"),
      color: "#830085",
      status: "simple50",
      usd: {
        count: 100,
        label: "usd",
      },
      uzs: {
        count: 1000,
        label: "uzs",
      },
    },
    {
      id: 4,
      title: t("home.seller.accessory"),
      color: "#72809D",
      status: "accessory",
      usd: {
        count: 100,
        label: "usd",
      },
      uzs: {
        count: 1000,
        label: "uzs",
      },
    },
  ];

  let warehouseData = [
    {
      id: 1,
      title: t("home.store.notApproved"),
      status: "not_accepted",
      color: "#830085",
    },
    {
      id: 2,
      title: t("home.store.approved"),
      status: "accepted",
      color: "#52C41A",
    },
    {
      id: 3,
      title: t("home.store.rejected"),
      status: "rejected",
      color: "#E24B4B",
    },
  ];

  const [start, setStart] = useState();

  const [end, setEnd] = useState();

  const [role] = useRole();

  let salon = role?.status === 3 || false;

  let url = salon ? "/report/salon-general" : "/report/warehouse-general";

  const data = useFetch(
    baseUrl +
      `${url}?from=${getDatePickerDate(start) || formattedDate}&to=${
        getDatePickerDate(end) || formattedDate
      }`
  );

  warehouseData = warehouseData.map((el) => {
    el.count = data?.[el.status];
    return el;
  });

  if (salon) {
    salonData = salonData.map((el) => {
      el.usd.count = data?.[el.status]?.usd || 0;
      el.uzs.count = data?.[el.status]?.uzs || 0;
      el.count = el?.uzs?.count / data?.usd_rate + +el?.usd?.count;
      return el;
    });
  }

  const user = useFetch(baseUrl + "/user/" + userId);

  useEffect(() => {
    if (user?.selected_language) {
      window.localStorage.setItem(
        "lang",
        user?.selected_language.toLowerCase()
      );
      i18next.changeLanguage(user?.selected_language);
    }
  }, [user?.selected_language]);

  return (
    <div className="max-w-[500px] w-full mx-auto overflow-y-auto bg-white pb-10 flex flex-col h-full">
      {role?.status === 2 || role?.status === 3 ? (
        <div className="px-4 bg-whit pt-6">
          <p
            className="text-center text-lg font-semibold mb-4"
            style={{
              color: "rgba(1, 0, 36, 0.6)",
              borderBottom: salon ? "1px solid #E6E6EA" : "",
              paddingBottom: salon ? "16px" : "",
            }}
          >
            {!salon ? t("home.daily") : t("home.heading")}
          </p>

          {salon ? (
            <div
              className="flex items-center w-full border border-[#E6E6EA] py-4 px-5 rounded-main mb-5"
              onClick={() => navigate("/debtors", { state: { comeBack: "/" } })}
            >
              <img className="mr-4" src={Dollar} alt="Just an icon" />
              <div className="flex flex-col gap-y-2">
                <span className="text-textSilver block">{t("home.debt")}</span>
                <span className="flex items-center gap-x-1.5">
                  <span className="text-textDark font-semibold">
                    {formatNumber(Number(data?.debts).toFixed(2))}
                  </span>{" "}
                  <span className="text-mainText font-semibold">USD</span>
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          <DatePickerInput
            start={start}
            end={end}
            setStart={setStart}
            setEnd={setEnd}
            formattedDate={formattedDate}
          ></DatePickerInput>
          <div className="w-72 h-72 mx-auto mb-4">
            <DoughnutChart
              chartData={salon ? salonData : warehouseData}
              status={salon ? "salon" : "ware"}
              total={salon ? data?.total_in_usd : data?.orders_count}
            ></DoughnutChart>
          </div>

          {salon ? (
            <div className="border border-[#E6E6EA] p-4 rounded-main mb-5 flex flex-col gap-y-1">
              <div className="flex flex-col gap-y-1 mb-5">
                <span className="text-textSilver block">Kutilayotgan pul</span>
                <span className="font-semibold text-mainText">
                  {data?.pending_money} USD
                </span>
              </div>
              <span className="text-textSilver block">Naqd pul</span>
              <span className="font-semibold text-mainText">
                {data?.cash?.uzs || 0} UZS
              </span>
              <span className="font-semibold text-mainText">
                {data?.cash?.usd || 0} USD
              </span>
            </div>
          ) : (
            ""
          )}

          {salon ? (
            <Statistics
              data={salonData}
              status={"salon"}
              cash={data?.cash}
            ></Statistics>
          ) : (
            <Statistics data={warehouseData}></Statistics>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center flex-grow">
          <div className="flex flex-col gap-y-9">
            <h2 className="text-textSilver  text-4xl">{t("placeholder")}</h2>
          </div>
        </div>
      )}
    </div>
  );
}
