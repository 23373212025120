import React from "react";
import Header from "../../components/Header/Header";
import MainButton from "../../components/MainButton/MainButton";
import MainInput from "../../components/MainInput/MainInput";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import useToken from "../../hooks/useToken";
import { useNotify } from "../../hooks/useNotify";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useLoading from "../../hooks/useLoading";

export default function GipyurColor() {
  const [token] = useToken();

  const notify = useNotify();

  const [_, setIsLoading] = useLoading();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();

    const { gipyur_color } = e.target.elements;

    if (gipyur_color.value) {
      if (pathname === "/addNewCategory") {
        setIsLoading(true);
        axios
          .post(
            baseUrl + "/product-category/create",
            { name: gipyur_color.value },
            {
              method: "POST",
              headers: {
                Authorization: "mme:" + token,
              },
            }
          )
          .then((res) => {
            if (res?.data?.data?.id) {
              setIsLoading(false);
              notify("Successfully !", 200);
              navigate(-1);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            notify("Xatolik !", 400);
          });
      } else {
        setIsLoading(true);
        axios
          .post(
            baseUrl + "/gipyur/color",
            { gipyur_color_name: gipyur_color.value },
            {
              method: "POST",
              headers: {
                Authorization: "mme:" + token,
              },
            }
          )
          .then((res) => {
            if (res?.data?.data?.id) {
              setIsLoading(false);
              notify("Successfully !", 200);
              navigate(-1);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            notify("Xatolik !", 400);
          });
      }
    }
  };
  return (
    <div className="bg-white h-screen flex flex-col">
      <Header
        heading={
          pathname === "/addNewCategory"
            ? "Yangi tur kiritish"
            : "Yangi rang kiritish"
        }
        icon={true}
        navPath={-1}
      ></Header>

      <form
        className="flex-grow flex flex-col justify-between px-4 pt-6 pb-7"
        onSubmit={handleSubmit}
      >
        <MainInput
          title={
            pathname === "/addNewCategory"
              ? "Mahsulot turini kiriting"
              : "Rang nomini kiriting"
          }
          name={"gipyur_color"}
        ></MainInput>
        <MainButton type={"submit"}>Saqlash</MainButton>
      </form>
    </div>
  );
}
