import React from "react";

export default function ButtonToggle({
  setIsClicked,
  isClicked,
  toggle1,
  toggle2,
}) {
  return (
    <ul className="w-full border border-[#E6E6EA] flex justify-between p-1 rounded-md">
      <li
        className={`py-2.5 text-center grow w-1/2  rounded-md ${
          !isClicked ? "border-[0.5px] border-[#72809D] bg-[#EEF2FF]" : ""
        }`}
        onClick={() => setIsClicked(false)}
      >
        {toggle1}
      </li>
      <li
        className={`py-2.5 text-center grow w-1/2  rounded-md ${
          isClicked ? "border-[0.5px] border-[#72809D] bg-[#EEF2FF]" : ""
        }`}
        onClick={() => setIsClicked(true)}
      >
        {toggle2}
      </li>
    </ul>
  );
}
