import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import ButtonSheetImg from "../../components/BottomSheet/ButtonSheetImg";
import ButtonSheetSimple from "../../components/BottomSheet/BottomSheetSimple";
import MainInput from "../../components/MainInput/MainInput";
import CurrencyCalculator from "../../components/CurrencyCalculator/CurrencyCalculator";
import InputDate from "../../components/MainInput/InputDate";
import MainButton from "../../components/MainButton/MainButton";
import { formattedDate } from "../../utils/getDatePickerDate";
import useUserId from "../../hooks/useUserId";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useNavigate } from "react-router-dom";
import { validateInput, checkInputForDebt } from "../../utils/validateInput";
import { useNotify } from "../../hooks/useNotify";
import { AddSquare } from "../../assets/images";
import useAlert from "../../hooks/useAlert";
import { useTranslation } from "react-i18next";
import useLoading from "../../hooks/useLoading";

const shlefData = [
  { id: 1, title: 30, tag: "sm" },
  { id: 2, title: 60, tag: "sm" },
  { id: 3, title: 80, tag: "sm" },
  { id: 4, title: 100, tag: "sm" },
  { id: 5, title: 150, tag: "sm" },
  { id: 6, title: 200, tag: "sm" },
];

export default function SaleFiftyFifty() {
  const dresses = useFetch(baseUrl + "/dress/all");

  const dressColors = useFetch(baseUrl + "/dress/colors");

  const currency = useFetch(baseUrl + "/other/central-bank-usd-rate");

  const { t } = useTranslation();

  const [token] = useToken();

  const [userId] = useUserId();

  const [showAlert] = useAlert();

  const [_, setIsLoading] = useLoading();

  const [dress, setDress] = useState({});

  const [shleft, setShleft] = useState(shlefData[0]);

  const [color, setColor] = useState();

  const [havePassport, setHavePassport] = useState(false);

  const [isDelivered, setIsDelivered] = useState(false);

  const salons = useFetch(baseUrl + "/salon/all");

  const navigate = useNavigate();

  let salonData = salons?.map((el) => {
    return { id: el?.id, title: el?.salon_name, image: el?.salon_image };
  });

  let validColors = dressColors?.map((el, id) => {
    return {
      id,
      title: el?.dress_color,
    };
  });

  let dressData = dresses?.map((el) => {
    return {
      id: el?.id,
      price: el?.dress_price,
      title: el?.dress_name,
      created_at: el?.created_at,
      image: el?.dress_image,
      shleft_size: el?.dress_shleft_size,
      dress_color: el?.dress_color,
      created_by_user: userId,
    };
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    let elements = e.target.elements;

    const {
      bride_must_pay,
      salon_must_pay,
      price,
      money_bride_usd,
      money_bride_uzs,
      money_salon_usd,
      money_salon_uzs,
    } = elements;

    if (
      validateInput(
        +price.value,
        { title: "Salon bergan pul", element: salon_must_pay },
        { title: "Kelin bergan pul", element: bride_must_pay },
        showAlert
      ) ||
      checkInputForDebt(
        +bride_must_pay.value,
        money_bride_usd,
        money_bride_uzs,
        currency?.usd_rate,
        showAlert
      ) ||
      checkInputForDebt(
        +salon_must_pay.value,
        money_salon_usd,
        money_salon_uzs,
        currency?.usd_rate,
        showAlert
      )
    ) {
      return true;
    } else {
      let newData = {
        dress_id: dress?.id,
        dress_name: dress?.title || validColors[0]?.title,
        dress_color: color?.title,
        shleft_size: shleft?.title,
        base_price: dress?.price,
        bride_passport_taken: havePassport,
        bride_must_be_delivered: isDelivered,
        delivery_date: formattedDate,
        created_by_user: userId,
        date_gives_debt: formattedDate,
        central_bank_usd_course: currency?.usd_rate,
        money_bride_usd: money_bride_usd.value || 0,
        money_bride_uzs: money_bride_uzs.value || 0,
        money_salon_usd: money_salon_usd.value || 0,
        money_salon_uzs: money_salon_uzs.value || 0,
      };

      for (let el of elements) {
        if (el.value && el.name) {
          newData[el.name] = el.value;
        }
        if (!isNaN(el?.value) && el.value && el.name) {
          newData[el.name] = Number(el.value) || 0;
        }
      }

      if (color) {
        newData.dress_color = color.title;
      }

      if (
        Object.keys(newData).length >= 16 &&
        newData.dress_id &&
        newData?.customer_salon_id
      ) {
        try {
          setIsLoading(true);
          axios
            .post(baseUrl + "/sale5050/create", newData, {
              method: "POST",
              headers: {
                Authorization: "mme:" + token,
              },
            })
            .then((res) => {
              if (res.data?.data?.id) {
                setIsLoading(false);
                showAlert(
                  "Muvafaqqiyatli",
                  "Forma muvafaqqiyatli to'ldirildi!",
                  "success"
                );
                navigate("/sell");
              }
            })
            .catch((err) => {
              setIsLoading(false);
              showAlert(
                "Xatolik!",
                "Ma'lumotlar yuklashda xatolik yuz berdi, qaytadan urinib ko'ring!",
                "error"
              );
            });
        } catch (error) {
          setIsLoading(false);
          showAlert(
            "Xatolik!",
            "Ma'lumotlar yuklashda xatolik yuz berdi, qaytadan urinib ko'ring!",
            "error"
          );
        }
      } else {
        setIsLoading(false);
        showAlert(
          "Ogohlantirish!",
          "Ma'lumotlar to'liq kiritilmadi!",
          "warning"
        );
      }
    }
  };

  useEffect(() => {
    setColor({ title: dressColors?.[0]?.dress_color });
  }, [dressColors]);

  return (
    <>
      <div className="relative">
        <Header
          heading={t("pages.fiftyFifty")}
          icon={true}
          navPath="/sell"
        ></Header>
        <img
          className="absolute top-1/2 -translate-y-1/2 right-9 w-6 h-6"
          src={AddSquare}
          alt="Just an icon"
          onClick={() => navigate("/addSalonOrDress")}
        />
      </div>

      <form
        className="bg-white px-4 flex flex-col gap-y-4 pt-5 pb-7"
        onSubmit={handleSubmit}
      >
        <ButtonSheetImg
          type={"dress"}
          placeholder={t("bookingPage.form.dressChoice")}
          data={dressData}
          select={true}
          title={t("bookingPage.form.dressName")}
          width={"165px"}
          height={"120px"}
          setDress={setDress}
          btn={true}
          btnTitle={t("simpleSale.addDress")}
          navPath={"/addDress"}
        ></ButtonSheetImg>
        <div className="flex gap-x-4 justify-between items-center">
          <ButtonSheetSimple
            title={t("bookingPage.form.shleftSize")}
            select={true}
            data={shlefData}
            setData={setShleft}
            value={{ title: dress?.shleft_size }}
          ></ButtonSheetSimple>
          <ButtonSheetSimple
            title={t("bookingPage.form.dressColor")}
            select={true}
            data={validColors}
            setData={setColor}
            value={{ title: dress?.dress_color }}
          ></ButtonSheetSimple>
        </div>
        <div className="flex justify-between items-center gap-x-4">
          <MainInput
            title={t("bookingPage.form.dressPrice")}
            name={"price"}
            type="number"
            value={dress?.price}
            currency={"usd"}
          ></MainInput>
          <MainInput
            title={t("simpleSale.brideName")}
            name={"bride_name"}
            required={true}
          ></MainInput>
        </div>
        <div className="flex justify-between items-center gap-x-4">
          <MainInput
            title={"Kelin beradigan pul"}
            currency={"usd"}
            name={"bride_must_pay"}
            type="number"
          ></MainInput>
          <MainInput
            title={"Salon beradigan pul"}
            currency={"usd"}
            name={"salon_must_pay"}
            type="number"
          ></MainInput>
        </div>

        <CurrencyCalculator currency={currency?.usd_rate}></CurrencyCalculator>
        <div>
          <span className="mb-1.5 text-textDark block">
            {t("simpleSale.brideMoney")}
          </span>
          <div className="flex items-center gap-x-4 justify-between">
            <MainInput
              currency={"usd"}
              name={"money_bride_usd"}
              // value={"0"}
            ></MainInput>
            <MainInput
              currency={"uzs"}
              name={"money_bride_uzs"}
              // value={"0"}
            ></MainInput>
          </div>
        </div>
        <div className="flex justify-between gap-x-4">
          <label>
            <span className="mb-1.5 text-textDark block">
              {t("simpleSale.bridePassport")}
            </span>
            <span className="flex gap-x-2 items-center">
              <input
                className="w-5 h-5"
                type="checkbox"
                onChange={() => setHavePassport(!havePassport)}
              />
              <span className="text-textSilver">{t("simpleSale.yes")}</span>
            </span>
          </label>
          <label>
            <span className="mb-1.5 text-textDark block">
              {t("simpleSale.deliveryToBride")}
            </span>
            <span className="flex gap-x-2 items-center">
              <input
                className="w-5 h-5"
                type="checkbox"
                onChange={() => setIsDelivered(!isDelivered)}
              />
              <span className="text-textSilver">{t("simpleSale.yes")}</span>
            </span>
          </label>
        </div>
        <label>
          <span className="text-textDark  block">
            {t("simpleSale.dressReleaseForBride")}
          </span>
          <InputDate name={"delivery_date"}></InputDate>
        </label>
        <ButtonSheetImg
          placeholder={t("bookingPage.form.salonChoice")}
          data={salonData}
          select={true}
          title={t("bookingPage.form.salonName")}
          width={"165px"}
          height={"120px"}
          name={"customer_salon_id"}
          btn={true}
          btnTitle={t("simpleSale.addSalon")}
          navPath={"/addNewSalon"}
        ></ButtonSheetImg>
        <div>
          <span className="mb-1.5 text-textDark block">
            {t("simpleSale.salonMoney")}
          </span>
          <div className="flex items-center gap-x-4 justify-between">
            <MainInput
              currency={"usd"}
              name={"money_salon_usd"}
              type="number"
            ></MainInput>
            <MainInput
              currency={"uzs"}
              name={"money_salon_uzs"}
              type="number"
            ></MainInput>
          </div>
        </div>
        <label>
          <span className="text-textDark  block">
            {t("simpleSale.salonGiveBackDate")}
          </span>
          <InputDate name={"date_gives_debt"}></InputDate>
        </label>
        <label className="mb-12">
          <span className="text-textSilver block mb-1.5">
            {t("bookingPage.form.dressDesc")}
          </span>
          <textarea
            className="w-full max-h-[140px] h-full outline-none p-3.5 border border-[#D7E1EA] rounded-md"
            name="description"
          ></textarea>
        </label>

        <MainButton type={"submit"}>{t("submit")}</MainButton>
      </form>
    </>
  );
}
