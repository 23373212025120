import React, { useState } from "react";
import Header from "../../components/Header/Header";
import MainInput from "../../components/MainInput/MainInput";
import LoginInput from "../../components/MainInput/LoginInput";
import MainButton from "../../components/MainButton/MainButton";
import useUserId from "../../hooks/useUserId";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import useToken from "../../hooks/useToken";
import { useNavigate } from "react-router-dom";
import useRole from "../../hooks/useRole";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

export default function AddPartner() {
  const [currency, setCurrency] = useState("USD");

  const { t } = useTranslation();

  const [role] = useRole();

  const [_, setIsLoading] = useLoading();

  const [userId] = useUserId();

  const [token] = useToken();

  const [showAlert] = useAlert();

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    let { partner_name, phone_number, money } = e.target.elements;

    if (phone_number.value[0] === "+") {
      phone_number.value = phone_number.value.replace("+", "").trim();
    }

    const newPartner = {
      user_id: userId,
      given_by_whom: role?.role,
      _money_: +money?.value,
      partner_name: partner_name?.value,
      partner_phone: "998" + phone_number?.value,
      selected_currency: currency,
    };

    if (money.value && phone_number.value && partner_name.value) {
      setIsLoading(true);
      axios
        .post(baseUrl + "/partner/create", newPartner, {
          method: "POST",
          headers: {
            Authorization: `mme:${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (!res.data?.data) {
            setIsLoading(false);
            showAlert("Xatolik!", res.data?.error?.errorMessage, "error");
            navigate(role?.status === 1 ? "/partnersList" : "/report");
          } else {
            setIsLoading(false);
            showAlert(
              "Muvafaqqiyatli!",
              "Yangi hamkor muvafaqqiyatli qo'shildi!",
              "success"
            );
            navigate(role?.status === 1 ? "/partnersList" : "/report");
          }
        })
        .catch((err) =>
          showAlert("Xatolik !", "Xatolik yuz berdi...", "error")
        );
    } else {
      showAlert(
        "Ogohlantirish !",
        "Ma'lumotlar to'liq kiritilmadi.",
        "warning"
      );
    }
  };

  return (
    <div className="bg-white flex flex-col h-screen">
      <Header
        heading={t("addNewPartner.heading")}
        icon={true}
        navPath="/partnersList"
      ></Header>
      <form
        className="px-4  pt-5 flex-grow flex flex-col pb-4 justify-between"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-5">
          <MainInput
            title={t("addNewPartner.form.name")}
            name={"partner_name"}
            placeholder={"Ism"}
            margin={"20px"}
          />
          <LoginInput
            title={t("addNewPartner.form.phone")}
            type="tel"
            name={"phone_number"}
            variant={"mainInput"}
          />

          <div className="flex gap-x-4 items-center w-full mt-4">
            <label className="w-full">
              <span
                className="p-3.5 text-center block font-semibold text-lg text-mainText border rounded-md border-[#E6E6EA]"
                style={{
                  backgroundColor:
                    currency === "USD" ? "rgba(230, 230, 234, 0.8)" : "",
                }}
                onClick={() => setCurrency("USD")}
              >
                USD
              </span>
            </label>
            <label className="w-full">
              <span
                className="p-3.5 text-center block font-semibold text-lg text-mainText border rounded-md border-[#E6E6EA]"
                style={{
                  backgroundColor:
                    currency === "UZS" ? "rgba(230, 230, 234, 0.8)" : "",
                }}
                onClick={() => setCurrency("UZS")}
              >
                UZS
              </span>
            </label>
          </div>
          <MainInput
            title={t("addNewPartner.form.debt")}
            placeholder={"0000"}
            type="number"
            name={"money"}
          ></MainInput>
        </div>
        <MainButton bgColor={"#702DE3"} type={"submit"}>
          {t("submit")}
        </MainButton>
      </form>
    </div>
  );
}
