import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import useLoading from "../../hooks/useLoading";

export default function Loading() {
  const [isLoading, setIsLoading] = useLoading();

  return (
    <>
      {isLoading ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-black bg-opacity-20 max-w-[500px] w-full mx-auto">
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
