import React, { useState } from "react";
import Header from "../../components/Header/Header";
import MainInput from "../../components/MainInput/MainInput";
import ButtonSheetSimple from "../../components/BottomSheet/BottomSheetSimple";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import LoginInput from "../../components/MainInput/LoginInput";
import MainButton from "../../components/MainButton/MainButton";
import useToken from "../../hooks/useToken";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNotify } from "../../hooks/useNotify";
import { useTranslation } from "react-i18next";
import useLoading from "../../hooks/useLoading";

export default function AddEmployee() {
  const { id } = useParams();

  const { t } = useTranslation();

  const [role, setRole] = useState({});

  const notify = useNotify();

  const [_, setIsLoading] = useLoading();

  const employeeRoles = useFetch(baseUrl + "/employee/roles");

  const user = useFetch(id ? baseUrl + "/user/" + id : "");

  const foundRole = employeeRoles?.find((el) => el?.role === user?.role);

  const [token] = useToken();

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, phone } = e.target.elements;

    let newEmployee = {
      name: name.value,
      phone: phone.value,
      role_id: role?.id || foundRole?.id,
    };

    if (id) {
      if (
        user.name !== name.value ||
        user.phone !== phone.value ||
        (role?.id ? role?.id !== foundRole?.id : false)
      ) {
        newEmployee.id = id;
        setIsLoading(true);
        return axios
          .put(baseUrl + "/employee/data", newEmployee, {
            method: "POST",
            headers: {
              Authorization: "mme:" + token,
            },
          })
          .then((res) => {
            if (res.data.id) {
              setIsLoading(false);
              notify("Successfully!", 200);
              navigate("/employeeList");
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    }

    if ((name.value && phone.value, role?.id)) {
      setIsLoading(true);
      axios
        .post(baseUrl + "/employee/create", newEmployee, {
          method: "POST",
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            setIsLoading(false);
            notify("Successfully!", 200);
            navigate("/controlEmployee");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          notify("Xatolik !", 400);
        });
    }
  };

  return (
    <>
      <Header
        heading={
          !id
            ? t("director.addEmployee")
            : t("profile.control.workersList.edit")
        }
        icon={true}
        navPath={id ? "/employeeList" : "/controlEmployee"}
      ></Header>
      <form
        className="bg-white px-4 pt-6 flex flex-col justify-between pb-7 h-full"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-4">
          <MainInput
            title={t("profile.control.workersList.editPage.name")}
            name={"name"}
            required={true}
            value={user?.name}
          ></MainInput>
          <ButtonSheetSimple
            title={t("profile.control.workersList.editPage.position")}
            defval={false}
            setData={setRole}
            select={true}
            value={employeeRoles
              ?.map((el) => {
                el.title = el.role;
                return el;
              })
              .find((el) => el?.role === user?.role)}
            data={employeeRoles?.map((el) => {
              el.title = el.role;
              return el;
            })}
          ></ButtonSheetSimple>

          <div className="flex flex-col gap-y-1.5">
            <span>{t("profile.control.workersList.editPage.phone")}</span>
            {id ? (
              <MainInput name={"phone"} value={user?.phone}></MainInput>
            ) : (
              <LoginInput
                type={"tel"}
                name={"phone"}
                required={true}
              ></LoginInput>
            )}
          </div>
        </div>
        <MainButton type={"submit"}>{t("submit")}</MainButton>
      </form>
    </>
  );
}
