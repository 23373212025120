import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { Category, MainPage, Profile, Chart } from "../../assets/images";
import "./footer.css";
import useRole from "../../hooks/useRole";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  let data = [
    { id: 1, icon: MainPage, title: t("footer.main"), to: "/" },
    { id: 2, icon: Category, title: t("footer.orders"), to: "orders" },
    { id: 5, icon: Category, title: t("footer.selling"), to: "sell" },
    { id: 3, icon: Chart, title: t("footer.report"), to: "report" },
    { id: 4, icon: Profile, title: t("footer.profile"), to: "profile" },
  ];

  const [role] = useRole();

  const makeValidData = useCallback(
    (status, data) => {
      if (status === 1 || status === 5) {
        return data.map((el) => {
          if (el.to === "orders") {
            el.title = t("footer.statistics");
            el.to = "statistics";
            return el;
          }

          if (el.to === "report") {
            el.to = "directorReport";
            return el;
          }

          return el;
        });
      }

      if (status === 2) {
        return data.map((el) => {
          if (el.to === "statistics") {
            el.title = t("footer.orders");
            el.to = "orders";
            return el;
          }
          return el;
        });
      }

      if (status === 3) {
        return data.map((el) => {
          if (el.to === "directorReport") {
            el.to = "report";
            return el;
          }
          return el;
        });
      }

      if (status === 4) {
        return data.map((el) => {
          if (el.to === "sell") {
            el.title = t("footer.sections");
            el.to = "report";
            return el;
          }

          if (el.title === t("footer.report")) {
            el.to = "directorReport";
            return el;
          }
          return el;
        });
      }
    },
    [role?.status, data, t]
  );

  return (
    <ul
      className="flex justify-between items-center px-8 pt-3 pb-4 bg-white max-w-[500px] mx-auto fixed bottom-0 right-0 left-0 "
      style={{ boxShadow: "0px 4px 15px rgba(13, 14, 43, 0.1)" }}
    >
      {makeValidData(role?.status, data)?.map((el) => {
        return (role?.status === 3 || role?.status === 4) &&
          (el.to === "orders" ||
            el?.to === "statistics") ? null : role?.status === 2 &&
          el.to === "sell" ? null : (role?.status === 1 ||
            role?.status === 5) &&
          el.to === "sell" ? null : (
          <li className="flex flex-col items-center" key={el.id}>
            <NavLink
              className="footer__link flex flex-col items-center"
              to={el.to}
            >
              <LazyLoadImage
                className="mb-2 w-6 h-6 object-contain"
                src={el.icon}
                alt={el.title}
                width={24}
                height={24}
              ></LazyLoadImage>

              <span className="text-xs font-medium">{el.title}</span>
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
}
