import React from "react";
import Header from "../../components/Header/Header";
import MainInput from "../../components/MainInput/MainInput";
import CurrencyCalculator from "../../components/CurrencyCalculator/CurrencyCalculator";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import MainButton from "../../components/MainButton/MainButton";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useUserId from "../../hooks/useUserId";
import useToken from "../../hooks/useToken";
import useLoading from "../../hooks/useLoading";

export default function Accessory() {
  const currency = useFetch(baseUrl + "/other/central-bank-usd-rate");

  const { t } = useTranslation();

  const [token] = useToken();

  const [_, setIsLoading] = useLoading();

  const [showAlert] = useAlert();

  const [userId] = useUserId();

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    const { accessory_name, usd, uzs } = e.target.elements;

    let newAccessory = {
      accessory_name: accessory_name.value.trim(),
      accessory_money_usd: +usd.value.trim() || 0,
      accessory_money_uzs: +uzs.value.trim() || 0,
      central_bank_usd_course: currency?.usd_rate,
      created_by_user: userId,
    };

    if (
      accessory_name.value &&
      currency?.usd_rate &&
      (usd.value || uzs.value)
    ) {
      setIsLoading(true);
      axios
        .post(baseUrl + "/accessory/create", newAccessory, {
          method: "POST",
          headers: {
            Authorization: `mme:${token}`,
          },
        })
        .then((res) => {
          if (res.data?.data?.id) {
            setIsLoading(false);
            showAlert(
              "Muvafaqqiyatli!",
              "Aksessuar muvafaqqiyatli sotildi !",
              "success"
            );
            navigate("/sell");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          showAlert("Xatolik!", "Qaytadan urinib ko'ring!", "error");
        });
    }
  };

  return (
    <>
      <Header
        icon={true}
        navPath="/sell"
        heading={t("pages.accessory")}
      ></Header>

      <form
        className="bg-white px-4 pt-6 h-full flex flex-col justify-between pb-4"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-6">
          <MainInput
            name={"accessory_name"}
            title={t("accessoryName")}
            required={true}
          ></MainInput>
          <CurrencyCalculator
            currency={currency?.usd_rate}
          ></CurrencyCalculator>
          <div className="">
            <span className="text-base text-textDark mb-2 block">
              {t("closePartnersDebt.form.quantity")}
            </span>
            <div className="w-full flex items-center gap-x-2">
              <label className="relative w-1/2">
                <input
                  className="pl-3 w-full pr-14 text-base py-4 rounded-md text-textSilver border border-[#D7E1EA] outline-none "
                  type="number"
                  name="usd"
                />
                <span className="absolute right-3  top-1/2 -translate-y-1/2 text-lg text-mainText font-semibold">
                  USD
                </span>
              </label>
              <label className="relative w-1/2">
                <input
                  className="pl-3 w-full pr-14 py-4 text-base rounded-md text-textSilver border border-[#D7E1EA] outline-none "
                  type="number"
                  name="uzs"
                />
                <span className="absolute right-3 top-1/2 -translate-y-1/2 text-lg text-mainText font-semibold">
                  UZS
                </span>
              </label>
            </div>
          </div>
        </div>
        <MainButton type={"submit"}>{t("submit")}</MainButton>
      </form>
    </>
  );
}
