import React from "react";
import { baseUrl } from "../../baseUrl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function GipyurCard({
  image,
  code,
  inShop,
  metr,
  color,
  id,
  history = false,
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  return (
    <li
      className="bg-white rounded-main pb-3 h-full"
      onClick={() => (history ? {} : navigate("/gipyurInfo/" + id))}
    >
      <LazyLoadImage
        className="rounded-main mb-3 w-full max-h-[120px] h-full"
        src={baseUrl + image}
        alt="Gipyur info card pic"
        onError={handleImageError}
      ></LazyLoadImage>
      <span className="text-textDark font-semibold block px-3"># {code}</span>
      <ul className="flex flex-col gap-y-0.5 px-3">
        <li className="text-textDark">
          {t("gipyurInfo.card.shop")}:{" "}
          <span className="font-bold">{inShop}</span>
        </li>
        <li className="text-textDark">
          {t("gipyurInfo.card.warehouse")}:{" "}
          <span className="font-bold">{metr}</span>
        </li>
        <li className="text-textDark">
          {t("gipyurInfo.card.color")}:{" "}
          <span className="font-bold">{color}</span>
        </li>
      </ul>
    </li>
  );
}
