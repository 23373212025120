import React from "react";
import Header from "../../components/Header/Header";
import ReportsList from "../../components/ReportsList/ReportsList";
import { Profile, AddUserCircle } from "../../assets/images";

const data = [
  {
    id: 1,
    title: "Sotuvchilar ro’yxati",
    icon: Profile,
    path: "/employeeList",
  },
  {
    id: 2,
    title: "Xodim qo’shish",
    icon: AddUserCircle,
    path: "/addEmployee",
  },
];

export default function ControlSellers() {
  return (
    <div className="flex flex-col bg-[#F8F5F5] h-screen">
      <Header
        icon={true}
        heading={"Sotuvchilarni boshqarish"}
        navPath="/profile"
      ></Header>
      <div className="flex-grow px-4">
        <ReportsList data={data}></ReportsList>
      </div>
    </div>
  );
}
