import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import Header from "../../components/Header/Header";
import ButtonToggle from "../../components/ButtonToggle/ButtonToggle";
import Moment from "react-moment";
import {
  Dye,
  Dress,
  Agreement,
  Eraser,
  Dollar,
  Expense,
  Calender,
} from "../../assets/images";
import { useTranslation } from "react-i18next";

export default function RejectedOrdersPage() {
  const { id } = useParams();

  const { t } = useTranslation();

  let path = id.split("_")[0];

  let ids = id.split("_")[1];

  const [isClicked, setIsClicked] = useState(false);

  const [haveImage, setHaveImage] = useState("");

  const [isImageOpen, setIsImageOpen] = useState(false);

  const data = useFetch(baseUrl + `/dress-order/${ids}`);

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  let mockData = [
    {
      id: 1,
      title: t("ordersPage.card.dressName"),
      icon: Dress,
      value: data?.dress_name,
    },
    {
      id: 2,
      title: t("ordersPage.card.shleft"),
      icon: Eraser,
      value: data?.shleft_size,
    },
    {
      id: 3,
      title: t("ordersPage.card.color"),
      icon: Dye,
      value: data?.dress_color,
    },
    {
      id: 4,
      title: t("ordersPage.card.price"),
      icon: Dollar,
      value: data?.price,
    },
    {
      id: 5,
      title: t("ordersPage.card.money"),
      icon: Expense,
      value: [data?.money_salon_usd, data?.money_salon_uzs],
    },
    {
      id: 6,
      title: t("ordersPage.card.delivery"),
      icon: Agreement,
      value: data?.must_be_delivered ? "Ha" : "Yo'q",
    },
    {
      id: 7,
      title: t("bookingPage.form.finishedDate"),
      icon: Calender,
      value: <Moment format="YYYY/MM/DD">{data?.delivery_date}</Moment>,
    },
  ];

  return (
    <div className="flex flex-col h-screen bg-white">
      <Header
        icon={true}
        navPath="/rejectedOrders"
        heading={`${t("ordersPage.card.order")} № ${data?.order_number}`}
      ></Header>
      <div className="grow bg-white pb-7">
        <div className="bg-white pt-8 px-4">
          <ButtonToggle
            setIsClicked={setIsClicked}
            isClicked={isClicked}
            toggle1={t("ordersPage.card.aboutOrder")}
            toggle2={t("ordersPage.card.images")}
          ></ButtonToggle>
        </div>
        {!isClicked ? (
          <ul className=" px-4 rounded-b-main">
            {mockData.length > 0 &&
              mockData.map((el) => (
                <li
                  className="flex gap-x-3 items-center border-b border-[#EEEFEF] py-3 pt-7"
                  key={el.id}
                >
                  <img
                    className="w-6 h-7 shrink-0 object-contain brend_svg"
                    src={el.icon}
                    alt="Just an icon"
                    width={18}
                    height={19}
                  />
                  <span className="flex flex-col gap-y-0">
                    <span className="text-base text-textSilver">
                      {el.title}
                    </span>
                    {!Array.isArray(el.value) ? (
                      <span className="text-base text-textDark font-semibold">
                        {el.value}
                      </span>
                    ) : (
                      <>
                        <span className="text-base text-textDark font-semibold">
                          {el.value[0]}
                        </span>
                        <span className="text-base text-textDark font-semibold">
                          {el.value[1]}
                        </span>
                      </>
                    )}
                  </span>
                </li>
              ))}
            <li className="gap-x-3 items-center w-full py-3 pt-7">
              <span className="text-base text-textSilver w-full block border-b pb-2 border-[#EEEFEF] mb-3">
                {t("addNewProduct.comment")}
              </span>
              <span className="text-base text-textDark">
                {data?.reject_description}
              </span>
            </li>
          </ul>
        ) : (
          <ul className="flex gap-4 px-4 mt-7">
            {data?.order_dress_images?.length &&
              data?.order_dress_images.map((el, id) => {
                return (
                  <li className="w-1/2" key={id}>
                    <img
                      className="rounded-main w-full h-[250px] object-contain"
                      src={baseUrl + el}
                      alt="product pic"
                      onError={handleImageError}
                      onClick={() => {
                        setHaveImage(baseUrl + el);
                        setIsImageOpen(!isImageOpen);
                      }}
                    />
                  </li>
                );
              })}
          </ul>
        )}
      </div>
      {isImageOpen ? (
        <div
          className="fixed top-0 bottom-0 right-0 flex justify-center items-center p-6 left-0 max-w-[500px] w-full mx-auto bg-black bg-opacity-40"
          onClick={() => setIsImageOpen(false)}
        >
          <img
            className="w-full h-auto z-10"
            src={haveImage}
            alt="ko'ylak rasmi"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
