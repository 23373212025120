export function formatNumber(number) {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatUzbekPhoneNumber(phoneNumber) {
  const regex = /^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/;
  const formattedNumber = phoneNumber?.replace(regex, "+$1($2)-$3-$4-$5");
  return formattedNumber;
}

export function formatNumberWithSpace(num) {
  // Convert the number to a string, then use the replace method with a regular expression to format.
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
