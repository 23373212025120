import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { Camera } from "../../assets/images";
import MainInput from "../../components/MainInput/MainInput";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import InputDate from "../../components/MainInput/InputDate";
import MainButton from "../../components/MainButton/MainButton";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { getMuiDate } from "../../utils/getMuiDate";
import { useNavigate } from "react-router-dom";
import ButtonSheetSimple from "../../components/BottomSheet/BottomSheetSimple";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

const measures = [
  { id: 1, category_name: "DONA" },
  { id: 2, category_name: "KILOGRAM" },
  { id: 3, category_name: "MILLIMETR" },
  { id: 4, category_name: "SANTIMETR" },
  { id: 5, category_name: "METR" },
  { id: 6, category_name: "BLOK" },
];

export default function AddNewProduct() {
  const { t } = useTranslation();
  const [token] = useToken();

  const [_, setIsLoading] = useLoading();

  const [imgUrl, setImgUrl] = useState();

  const [image, setImage] = useState();

  const [categoryId, setCategoryId] = useState("");

  let categories = useFetch(baseUrl + "/product-category/all");

  const navigate = useNavigate();

  const [showAlert] = useAlert();

  const handleChange = (e) => {
    let formData = new FormData();
    if (e.target.files[0]) {
      setImgUrl(URL.createObjectURL(e.target?.files[0] || {}));
    }

    formData.append("file", e.target.files[0]);

    try {
      axios
        .post(baseUrl + "/file-upload", formData, {
          method: "POST",
          headers: {
            Authorization: `mme:${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setImage(res?.data.image);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let {
      product_name,
      product_code,
      product_count,
      unit_of_measure,
      product_price,
      product_count_warning_limit,
      product_description,
    } = e.target.elements;

    let date = document.querySelector(".MuiInputBase-input");

    let newProduct = {
      product_image: image,
      warehouse_product_category_id: categoryId?.id,
      created_at: getMuiDate(date.value),
      product_name: product_name.value,
      product_code: product_code.value,
      product_count: +product_count.value,
      product_price: +product_price.value,
      unit_of_measure: unit_of_measure.value,
      product_count_warning_limit: +product_count_warning_limit.value,
      product_description: product_description.value,
    };

    if (imgUrl) {
      if (image) {
        setIsLoading(true);
        return axios
          .post(baseUrl + "/product/create", newProduct, {
            method: "POST",
            headers: {
              Authorization: `mme:${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (res.status === 201) {
              setIsLoading(false);
              showAlert(
                "Muvafaqqiyatli!",
                "Jarayon muvafaqqiyatli tugallandi...!",
                "success"
              );
              navigate("/report");
            }
          })
          .catch((err) => {
            setIsLoading(false);
            showAlert(
              "Xatolik !",
              "Xatolik yuz berdi, qaytadan urinib ko'ring!",
              "error"
            );
          });
      }
    }

    if (Object.keys(newProduct).length) {
      setIsLoading(true);
      axios
        .post(baseUrl + "/product/create", newProduct, {
          method: "POST",
          headers: {
            Authorization: `mme:${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data?.data?.id) {
            setIsLoading(false);
            showAlert(
              "Muvafaqqiyatli!",
              "Jarayon muvafaqqiyatli tugallandi...!",
              "success"
            );

            navigate("/report");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          showAlert(
            "Xatolik !",
            "Xatolik yuz berdi, qaytadan urinib ko'ring!",
            "error"
          );
        });
    }
  };

  return (
    <>
      <Header
        heading={t("pages.addNewProduct")}
        icon={true}
        navPath="/report"
      ></Header>
      <form
        className="bg-white pt-3 px-4 relative pb-5 flex flex-col gap-y-4"
        onSubmit={handleSubmit}
      >
        <p className="text-base text-textDark mb-2.5 text-left">
          {t("addNewProduct.addImage")}
        </p>
        <label className="w-36 h-36 block mx-auto">
          <span className="border-dashed border-2 border-[#E6E6EA] w-36 h-36 flex justify-center items-center mx-auto relative">
            <input
              className="hidden"
              type="file"
              name="productImage"
              onChange={handleChange}
              accept=".png, .jpg, .jpeg, .webp"
            />
            <img src={Camera} alt="" />
            {imgUrl?.length ? (
              <>
                <img
                  className="absolute top-0 bottom-0 right-0 left-0 w-full h-full object-cover"
                  src={imgUrl}
                  alt=""
                />
                <span
                  className="absolute right-0.5 top-0.5 text-white w-5 h-5 text-3xl leading-4"
                  onClick={() => setImgUrl({})}
                >
                  &#9746;
                </span>
              </>
            ) : (
              ""
            )}
          </span>
        </label>
        <MainInput
          title={t("addNewProduct.productName")}
          placeholder={"Nomini kiriting"}
          name={"product_name"}
          type={"text"}
          required={true}
        ></MainInput>
        <ButtonSheetSimple
          title={t("addNewProduct.productType")}
          select={true}
          data={categories.map((el) => {
            el.title = el.category_name;
            return el;
          })}
          setData={setCategoryId}
          name={"productType"}
          button={true}
          btnTitle={"Yangi tur kiritish"}
          btnPath={"/addNewCategory"}
        ></ButtonSheetSimple>
        <MainInput
          title={t("addNewProduct.productCode")}
          placeholder={"Xf1234567812we34"}
          code={true}
          type={"text"}
          name={"product_code"}
        ></MainInput>
        <div className="grid grid-cols-2 gap-x-2.5 max-[360px]:grid-cols-1 max-[400px]:mx-auto gap-y-4">
          <MainInput
            title={t("addNewProduct.productCount")}
            placeholder={"Mahsulot soni"}
            type={"number"}
            name={"product_count"}
            required={true}
          ></MainInput>
          <ButtonSheetSimple
            title={t("addNewProduct.productMeasure")}
            name1={"unit_of_measure"}
            select={true}
            data={measures.map((el) => {
              el.title = el.category_name;
              return el;
            })}
          ></ButtonSheetSimple>
          <MainInput
            title={t("addNewProduct.warningLimit")}
            placeholder={"Limit"}
            type={"number"}
            name={"product_count_warning_limit"}
            required={true}
          ></MainInput>
          <MainInput
            type={"number"}
            title={t("addNewProduct.productPrice")}
            placeholder={"Narxi"}
            name={"product_price"}
            required={true}
          ></MainInput>
        </div>
        <InputDate name={"created_at"}></InputDate>
        <label className="mb-7 block">
          <span className="mb-2 text-base text-textSilver block">
            {t("addNewProduct.comment")}
          </span>
          <textarea
            className="w-full h-24 border border-[#D7E1EA] outline-none p-3.5"
            name="product_description"
          ></textarea>
        </label>

        <MainButton bgColor={"#702DE3"} type={"submit"}>
          {t("submit")}
        </MainButton>
      </form>
    </>
  );
}
