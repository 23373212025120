import React, { useEffect } from "react";
import { MinusIcon, Shop } from "../../assets/images";
import MainButton from "../MainButton/MainButton";
import { baseUrl } from "../../baseUrl";
import MainInput from "../MainInput/MainInput";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

export default function UseProduct({
  product_name,
  product_count,
  product_image,
  isClicked,
  setIsClicked,
  product_measure,
  product_id,
  refetch,
}) {
  const [token] = useToken();

  const [_, setIsLoading] = useLoading();

  const { t } = useTranslation();

  const [showAlert] = useAlert();

  const handleSubmit = (e) => {
    e.preventDefault();

    let { product_count } = e.target.elements;

    let data = {
      id: product_id,
      productCount: +product_count.value,
    };

    if (product_count.value) {
      setIsLoading(true);
      axios
        .put(baseUrl + "/product/use", data, {
          method: "PUT",
          headers: {
            Authorization: `mme:${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            showAlert(
              "Muvafaqqiyatli!",
              "Jarayon muvafaqqiyatli yakunlandi!",
              "success"
            );
            refetch();
            product_count.value = null;
            setIsClicked(null);
          } else {
            showAlert("Ogohlantirish!", "Qaytadan urinib ko'ring", "warning");
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const handleClick = (e) => {
    if (e.target.id === "modal_wrapper1") {
      setIsClicked(null);
    } else {
      setIsClicked("use");
    }
  };

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  useEffect(() => {
    if (isClicked) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isClicked]);

  return (
    <div
      className={`${
        isClicked === "use"
          ? "top-0 bg-black bg-opacity-20"
          : "top-full bg-white bg-opacity-0"
      } fixed bottom-0 min-[500px]:left-1/2 left-0 min-[500px]:-translate-x-1/2 right-0 bg-black bg-opacity-30 max-w-[500px] w-full overscroll-none flex items-end z-50 max-h-screen transition delay-150 duration-300`}
      id="modal_wrapper1"
      onClick={handleClick}
    >
      <form
        className={`${
          isClicked === "use"
            ? "translate-y-0 opacity-100"
            : "translate-y-full opacity-0"
        } px-4 pt-6 pb-7 bg-white w-full transition-all delay-75 duration-300`}
        onSubmit={handleSubmit}
      >
        <h4 className="text-brendColor text-center text-lg font-semibold mb-6">
          {t("warehouseProducts.useProduct")}
        </h4>
        <div className="flex mb-10">
          <img
            className="w-[163px] h-[181px] mr-4  object-cover rounded-md"
            src={baseUrl + product_image}
            alt="Product pic"
            width={163}
            height={181}
            onError={handleImageError}
          />

          <div className="w-full">
            <p className="text-textDark text-base font-semibold mb-7 capitalize">
              {product_name}
            </p>
            <span className="flex w-full justify-between mb-7">
              <img
                className=" object-contain"
                src={Shop}
                alt="money icon"
                width={20}
                height={20}
              />
              <span className="text-base text-textDark ">{product_count}</span>
              <span className="text-textDark text-base lowercase">
                {product_measure}
              </span>
            </span>
            <span className="relative w-full block">
              <MainInput styles={"pr-16"} name={"product_count"}></MainInput>
              <span className="text-textSilver text-sm font-semibold absolute right-2 lowercase top-1/2 -translate-y-1/2">
                {product_measure}
              </span>
            </span>
          </div>
        </div>

        <MainButton type={"submit"} bgColor={"#702DE3"} icon={MinusIcon}>
          {t("warehouseProducts.remove")}
        </MainButton>
      </form>
    </div>
  );
}
