import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Filter } from "../../assets/images";
import DatePickerInput from "../../components/DatePicker/DatePicker";
import MainButton from "../../components/MainButton/MainButton";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import axios from "axios";
import useToken from "../../hooks/useToken";
import useAlert from "../../hooks/useAlert";
import { useTranslation } from "react-i18next";

export default function MoneyGiveHistory() {
  let today = new Date();

  let year = today.getFullYear();

  let month = String(today.getMonth() + 1).padStart(2, "0");

  let day = String(today.getDate()).padStart(2, "0");

  let formattedDate = `${year}-${month}-${day}`;

  const { t } = useTranslation();

  const filterList = [
    { id: 1, title: t("giveMoney.filter.all"), status: "" },
    { id: 2, title: t("giveMoney.filter.waiting"), status: "WAITING" },
    { id: 3, title: t("giveMoney.filter.confirmation"), status: "ACCEPTED" },
    { id: 4, title: t("giveMoney.reject"), status: "REJECTED" },
  ];

  const navigate = useNavigate();

  const [token] = useToken();

  const [showAlert] = useAlert();

  const [isClicked, setIsClicked] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState({ clicked: false, data: {} });

  const [clicked, setClicked] = useState(filterList[0]);

  const [data, setData] = useState();

  const [start, setStart] = useState();

  const [end, setEnd] = useState();

  const [histories, refetch] = useFetch(
    baseUrl +
      `/employee-cash/money-history?limit=12&offset=0${
        clicked.status ? "&status_given_money=" + clicked.status : ""
      }`,
    true
  );

  useEffect(() => {
    if (histories) {
      setData([histories?.data]);
    }
  }, [histories]);

  const handleClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked(false);
    }

    if (e.target.id === "mini_modal") {
      setIsModalOpen({ clicked: false, data: {} });
    }
  };

  const handleRejectClick = () => {
    axios
      .put(
        baseUrl +
          `/employee-cash/action?id=${isModalOpen.data?.id}&accept=false`,
        { id: isModalOpen.data?.id, accept: false },
        { method: "PUT", headers: { Authorization: "mme:" + token } }
      )
      .then((res) => {
        if (res.data?.data?.id) {
          setIsModalOpen({ clicked: false, data: {} });
          showAlert(
            "Muvafaqqiyatli !",
            "Jarayon muvafaqqiyatli yakunlandi.",
            "success"
          );
          refetch();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleAcceptClick = () => {
    axios
      .put(
        baseUrl +
          `/employee-cash/action?id=${isModalOpen.data?.id}&accept=true`,
        { id: isModalOpen.data?.id, accept: true },
        { method: "PUT", headers: { Authorization: "mme:" + token } }
      )
      .then((res) => {
        if (res.data?.data?.id) {
          setIsModalOpen({ clicked: false, data: {} });
          showAlert(
            "Muvafaqqiyatli !",
            "Jarayon muvafaqqiyatli yakunlandi.",
            "success"
          );
          refetch();
        }
      })
      .catch((err) => console.log(err));
  };

  function makeStyleForStatus(status) {
    if (status === "WAITING") {
      return (
        <span className="text-[#C09629] bg-[#C09629] bg-opacity-10 px-1.5 rounded-md">
          {t("giveMoney.filter.waiting")}
        </span>
      );
    }

    if (status === "ACCEPTED") {
      return (
        <span className="text-[#29C061] bg-[#29C061] bg-opacity-10 px-1.5 rounded-md">
          {t("giveMoney.filter.confirmation")}
        </span>
      );
    }

    if (status === "REJECTED") {
      return (
        <span className="text-[#DD3439] bg-[#DD3439] bg-opacity-10 px-1.5 rounded-md">
          {t("giveMoney.filter.rejected")}
        </span>
      );
    }
  }
  return (
    <div className="flex flex-col h-screen bg-[#F8F5F5] gap-7">
      <div
        className="pb-4 px-4 bg-white pt-10 relative"
        style={{ boxShadow: "0px 12px 14px rgba(209, 212, 226, 0.157261)" }}
      >
        <div className="text-center mb-10 relative">
          <span
            className="h-8 w-8 cursor-pointer rounded-full text-lg text-[#354B91]  top-1/2 -translate-y-1/2 left-1 border border-[#354B91] flex justify-center items-center absolute"
            onClick={() => navigate(-1)}
          >
            &#10094;
          </span>
          <h2 className="text-xl text-brendColor">
            <span>{t("giveMoney.history")}</span>
          </h2>

          <img
            className="absolute right-2 top-1.5 cursor-pointer"
            src={Filter}
            alt="Just an icon"
            onClick={() => setIsClicked(true)}
          />
        </div>
      </div>
      <ul className="flex px-4 justify-between">
        {filterList.map((el) => (
          <li
            className="grow text-center font-medium"
            key={el.id}
            onClick={() => setClicked(el)}
          >
            <span
              className={`block mb-4 ${
                clicked.id !== el.id ? "text-textSilver" : "text-textDark"
              }`}
            >
              {el.title}
            </span>
            <span
              className={`h-0.5 w-full block bg-black ${
                clicked.id === el.id ? "bg-opacity-90" : "bg-opacity-10"
              }`}
            ></span>
          </li>
        ))}
      </ul>
      <ul className="flex flex-col gap-y-7 grow px-4 pb-6 bg-[#F8F5F5]">
        {data?.length > 0 &&
          data.map((item) =>
            Object.entries(item || {})?.length > 0 ? (
              Object.entries(item || {}).map((el, i) => (
                <li key={i}>
                  <p className="text-lg font-semibold text-textDark mb-4">
                    {el[0]}
                  </p>
                  <ul className="flex flex-col gap-y-4">
                    {el[1].length &&
                      el[1].map((it, idx) => (
                        <li
                          className="bg-white rounded-main p-4 flex flex-col gap-y-1.5"
                          key={idx}
                          onClick={() =>
                            setIsModalOpen({ clicked: true, data: it })
                          }
                        >
                          <div className="flex justify-between">
                            <p className="text-textDark font-medium text-base">
                              {it?.another_employee_name}
                            </p>
                            {makeStyleForStatus(it?.status_given_money)}
                          </div>
                          <span className="text-lg font-medium text-textDark">
                            {it?.money_usd} usd
                          </span>
                          <span className="w-full flex justify-between items-center">
                            <span className="text-lg font-medium text-textDark">
                              {it?.money_uzs} uzs
                            </span>
                            <span className="text-base text-textSilver">
                              {it?.time}
                            </span>
                          </span>
                        </li>
                      ))}
                  </ul>
                </li>
              ))
            ) : (
              <p key={Math.random()}>Ma'lumot topilmadi</p>
            )
          )}
      </ul>

      <div
        className={`${
          isClicked
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed transition delay-150 duration-300 z-10 bottom-0 min-[500px]:left-1/2 min-[500px]:-translate-x-1/2 max-w-[500px] w-full left-0 right-0 flex justify-center items-end`}
        id="modal_wrapper"
        onClick={handleClick}
      >
        <div
          className={`${
            isClicked ? "translate-y-0" : "translate-y-full"
          } bg-white w-full py-5 px-4 pt-6 transition-all delay-75 ease-in-out h-3/4 overflow-y-auto flex flex-col justify-between pb-3`}
        >
          <div className="flex flex-col justify-between">
            <p className="text-lg text-brendColor font-semibold text-center mb-8">
              {t("gipyurExpenses.filter.title")}
            </p>
            <div className="flex justify-between">
              <span className="grow text-textDark">
                {t("gipyurExpenses.filter.start")}
              </span>
              <span className="grow text-textDark">
                {t("gipyurExpenses.filter.end")}
              </span>
            </div>
            <DatePickerInput
              filter={true}
              start={start}
              end={end}
              setStart={setStart}
              setEnd={setEnd}
              formattedDate={formattedDate}
            ></DatePickerInput>
          </div>
          <MainButton handleClick={() => setIsClicked(false)} type={"button"}>
            {t("gipyurExpenses.filter.sort")}
          </MainButton>
        </div>
      </div>

      {isModalOpen.clicked &&
      isModalOpen?.data?.status_given_money !== "ACCEPTED" &&
      isModalOpen?.data?.status_given_money !== "REJECTED" ? (
        <div
          className="fixed top-0 right-0 bottom-0 left-0 bg-black bg-opacity-25 justify-center flex items-center px-4"
          onClick={handleClick}
          id="mini_modal"
        >
          <div className="pt-6 pb-4 px-4 bg-white rounded-main w-full max-w-[500px] mx-auto">
            <p className="text-textDark text-lg mb-5 max-w-[250px] mx-auto text-center">
              {t("giveMoney.accept")}
            </p>
            <div className="flex gap-x-4 justify-between">
              <MainButton
                type={"button"}
                bgColor="#DD3439"
                handleClick={handleRejectClick}
              >
                {t("giveMoney.reject")}
              </MainButton>
              <MainButton
                type={"button"}
                bgColor="#29C061"
                handleClick={handleAcceptClick}
              >
                {t("giveMoney.confirm")}
              </MainButton>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
