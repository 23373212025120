import React, { useEffect, useState } from "react";
import { CloseIcon, Search } from "../../assets/images";
import { searchData } from "../../utils/SearchData";
import { DownArrow } from "../../assets/images";
import { baseUrl } from "../../baseUrl";
import Moment from "react-moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { PlusIcon } from "../../assets/images";
import { useTranslation } from "react-i18next";

export default function ButtonSheetImg({
  data = [],
  height,
  select = false,
  placeholder,
  title,
  name,
  name1,
  type,
  setDress = () => {},
  btn = false,
  navPath,
  btnTitle,
}) {
  const [isClicked, setIsClicked] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [inputVal, setInputVal] = useState(placeholder);

  const [value, setValue] = useState("");

  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked(false);
    }

    if (e.target.id === "main_image") {
      setIsClicked(true);
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    if (isClicked) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isClicked]);

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  const handleImageModalClick = (e) => {
    if (e.target.id === "image_wrapper" || e.target.id === "modal_closer") {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      {select ? (
        <label>
          <span className="text-textDark mb-1.5 block">{title}</span>
          <span className="relative block w-full">
            <input
              className="rounded-md border border-[#D7E1EA] w-full py-3 px-3.5 outline-none text-textSilver"
              type="text"
              placeholder={placeholder}
              readOnly
              name={name1}
              value={inputVal?.title || ""}
              onClick={() => setIsClicked(true)}
            />
            <input
              className="hidden"
              defaultValue={inputVal?.id || ""}
              type="text"
              name={name}
            />
            <img
              className="absolute right-5 top-1/2 -translate-y-1/2"
              src={DownArrow}
              alt="Just an icon"
            />
          </span>
        </label>
      ) : (
        ""
      )}
      <div
        className={`${
          isClicked
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed transition delay-150 duration-300 z-10 bottom-0 min-[500px]:left-1/2 min-[500px]:-translate-x-1/2 max-w-[500px] w-full left-0 right-0 flex justify-center ${
          !isModalOpen ? " items-end" : "items-center"
        }`}
        id="modal_wrapper"
        onClick={handleClick}
      >
        <div
          className={`${
            isClicked ? "translate-y-0" : "translate-y-full"
          } bg-white w-full py-5 px-4 transition-all delay-75 ease-in-out h-3/4 overflow-y-auto flex flex-col justify-between pb-3`}
        >
          <div className="flex-grow flex flex-col">
            <label className="relative block mb-6">
              <img
                className="absolute left-3 top-1/2 -translate-y-1/2"
                src={Search}
                alt="Just an icon"
              />
              <input
                className="border border-[#AFBACA] outline-none py-3 block w-full rounded-md px-12"
                type="text"
                value={value}
                placeholder={t("search")}
                onChange={(e) => setValue(e.target.value)}
              />
              <img
                className="absolute right-3 top-1/2 -translate-y-1/2"
                src={CloseIcon}
                alt="Just an icon"
                onClick={() => setValue("")}
              />
            </label>
            <ul
              className="grid grid-cols-2 gap-4 mb-4
           overflow-y-auto flex-grow"
            >
              {data?.length > 0 &&
                searchData(value, data).map((el) => (
                  <li
                    className="w-full border h-max rounded-main pb-3"
                    key={el?.id}
                    onClick={() => {
                      setInputVal(el);
                      setDress(el);
                      setIsClicked(false);
                    }}
                    style={{
                      boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <LazyLoadImage
                      className="mb-2 rounded-main w-full object-cover block"
                      style={{ height: height }}
                      src={
                        type === "dress"
                          ? baseUrl + el?.image[0]
                          : baseUrl + el?.image
                      }
                      id="main_image"
                      alt="Just a pic"
                      onError={handleImageError}
                    ></LazyLoadImage>
                    <div className="ml-3">
                      <p
                        className="text-[#090A2D] text-lg mb-1 truncate"
                        title={el?.title}
                      >
                        {el?.title}
                      </p>
                      <span className="text-textSilver">
                        <Moment format="YYYY/MM/DD">{el?.created_at}</Moment>
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          {btn ? (
            <button
              className="bg-brendColor w-full mt-4 text-center flex justify-center items-center gap-x-3 rounded-md py-2.5"
              onClick={() => navigate(navPath)}
            >
              <img src={PlusIcon} alt="just an icon" />{" "}
              <span className="text-white font-medium">{btnTitle}</span>
            </button>
          ) : (
            ""
          )}
        </div>
      </div>

      {isModalOpen ? (
        <div
          className="fixed top-0 backdrop-blur-lg  bottom-0 right-0 left-0 bg-black bg-opacity-25 z-50 flex justify-center items-center max-w-[500px] mx-auto overflow-y-hidden"
          onClick={handleImageModalClick}
          id="image_wrapper"
        >
          <span
            className="w-12 h-12 bg-white text-black flex justify-center items-center absolute z-[52] right-3 top-4 rounded-full font-semibold text-2xl"
            id="modal_closer"
          >
            &#10005;
          </span>
          <div className="unwanted_scroll flex gap-x-4 px-3 overflow-x-auto relative">
            {type === "dress" ? (
              inputVal?.image?.map((el, i) => (
                <LazyLoadImage
                  className="z-[51] w-full object-contain shrink-0"
                  src={baseUrl + el}
                  alt="pic"
                  key={i}
                />
              ))
            ) : (
              <LazyLoadImage
                className="rounded-main z-[51]"
                src={baseUrl + inputVal?.image}
                alt="pic"
              />
            )}
          </div>
          {type === "dress" && inputVal?.image?.length > 1 && (
            <span className="text-white text-5xl z-[52] absolute bottom-14">
              . . .
            </span>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
