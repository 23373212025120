import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { Clock } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import useUserId from "../../hooks/useUserId";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import { Search, Profile, CloseIcon, DownArrow } from "../../assets/images";
import { searchData } from "../../utils/SearchData";
import MainInput from "../../components/MainInput/MainInput";
import MainButton from "../../components/MainButton/MainButton";
import useToken from "../../hooks/useToken";
import axios from "axios";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

export default function GiveMoney() {
  const navigate = useNavigate();

  const [userId] = useUserId();

  const [token] = useToken();

  const [_, setIsLoading] = useLoading();

  const [showAlert] = useAlert();

  const { t } = useTranslation();

  const [isClicked, setIsClicked] = useState(false);

  const [employee, setEmployee] = useState({});

  const [value, setValue] = useState("");

  const userCash = useFetch(baseUrl + "/employee-cash?user_id=" + userId);

  const employees = useFetch(baseUrl + "/employee/all");

  const currency = useFetch(baseUrl + "/other/central-bank-usd-rate");

  const handleClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { money_usd, money_uzs } = e.target.elements;

    let newData = {
      recipient_user_id: employee?.id || employees[0]?.id,
      money_usd: +money_usd.value || 0,
      money_uzs: +money_uzs.value || 0,
    };

    if (
      (money_usd.value || money_uzs.value) &&
      +userCash?.employee_cash_usd +
        +userCash?.employee_cash_uzs / currency?.usd_rate >
        0
    ) {
      setIsLoading(true);
      axios
        .post(baseUrl + "/employee-cash/give-to-another", newData, {
          method: "POST",
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res?.data?.data?.id) {
            setIsLoading(false);
            showAlert(
              "Muvafaqqiyatli !",
              "Jarayon muvafaqqiyatli tugallandi!",
              "success"
            );
            navigate(-2);
          } else {
            setIsLoading(false);
            showAlert(
              "Ogohlantirish",
              "O'zingizga pul berolmisizu brat!",
              "warning"
            );
          }
        })
        .catch((err) => {
          setIsLoading(false);
          showAlert("Xatolik", "Qaytadan urinib ko'ring...", "error");
        });
    } else {
      showAlert(
        "Ogohlantirish",
        "Kiritilgan ma'lumotlarni yoki pulingizni tekshiring...",
        "warning"
      );
      money_usd.style.borderColor = "red";
      money_uzs.style.borderColor = "red";
    }
  };

  return (
    <div className="flex flex-col h-screen bg-white">
      <div className="relative">
        <Header
          heading={t("giveMoney.heading")}
          icon={true}
          navPath={-1}
        ></Header>
        <img
          className="absolute top-1/2 -translate-y-1/2 right-9 w-7 h-7"
          src={Clock}
          alt="Just an icon"
          onClick={() => navigate("/moneyGiveHistory")}
        />
      </div>
      <ul className="px-4 flex justify-between gap-x-4 items-center my-7">
        <li
          className="py-3 bg-white flex flex-col gap-y-2 w-1/2 items-center rounded-main"
          style={{ boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <span className="text-textDark font-medium">
            {userCash?.employee_cash_usd}
          </span>
          <span className="text-textSilver">
            {t("gipyurExpenses.statCard")} USD
          </span>
        </li>
        <li
          className="py-3 bg-white flex flex-col gap-y-2 w-1/2 items-center rounded-main"
          style={{ boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <span className="text-textDark font-medium">
            {userCash?.employee_cash_uzs}
          </span>
          <span className="text-textSilver">
            {t("gipyurExpenses.statCard")} UZS
          </span>
        </li>
      </ul>
      <form
        className="px-4 flex flex-col grow justify-between pb-4"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-6">
          <label>
            <span className="text-textDark block mb-1.5">
              {t("giveMoney.form.choice")}
            </span>
            <span className="relative">
              <input
                className="border border-[#E6E6EA] w-full p-3 rounded-main outline-none"
                type="text"
                value={employee?.name || employees[0]?.name || ""}
                onClick={() => setIsClicked(true)}
                readOnly
              />
              <img
                className="absolute top-1/2 -translate-y-1/2 right-4"
                src={DownArrow}
                alt="Just an icon"
              />
            </span>
          </label>
          <div>
            <span className="text-textDark block">
              {t("giveMoney.form.money")}
            </span>
            <div className="flex justify-between gap-x-4">
              <MainInput
                name={"money_usd"}
                currency={"usd"}
                type="number"
              ></MainInput>
              <MainInput
                name={"money_uzs"}
                currency={"uzs"}
                type="number"
              ></MainInput>
            </div>
          </div>
        </div>
        <MainButton type={"submit"}>{t("submit")}</MainButton>
      </form>

      <div
        className={`${
          isClicked
            ? "top-0 bg-black bg-opacity-25"
            : "top-full bg-white bg-opacity-0"
        } fixed transition  duration-300 delay-75 z-10 bottom-0 min-[500px]:left-1/2 min-[500px]:-translate-x-1/2 max-w-[500px] w-full left-0 right-0 flex justify-center items-end`}
        id="modal_wrapper"
        onClick={handleClick}
      >
        <div
          className={`${
            isClicked ? "translate-y-0" : "translate-y-full"
          } bg-white w-full flex flex-col py-5 px-4 transition-all delay-75 ease-in-out h-3/4`}
        >
          <label className="relative block mb-6">
            <img
              className="absolute left-3 top-1/2 -translate-y-1/2"
              src={Search}
              alt="Just an icon"
            />
            <input
              className="border border-[#AFBACA] outline-none py-3 block w-full rounded-md px-12"
              type="text"
              value={value}
              placeholder="Qidirish"
              onChange={(e) => setValue(e.target.value)}
            />
            <img
              className="absolute right-3 top-1/2 -translate-y-1/2"
              src={CloseIcon}
              alt="Just an icon"
              onClick={() => setValue("")}
            />
          </label>
          <ul className="flex flex-col gap-y-2.5 overflow-y-auto unwanted_scroll">
            {employees?.length > 0 &&
              searchData(
                value,
                employees?.map((it) => {
                  it.title = it.name;
                  return it;
                })
              ).map((el) => (
                <li
                  className="w-full py-2.5 px-3 flex items-center rounded-main cursor-pointer relative border border-[#E6E6EA]"
                  key={el?.id}
                  style={{ boxShadow: "0px 3px 15px rgba(13, 14, 43, 0.05)" }}
                  onClick={() => {
                    setEmployee(el);
                    setIsClicked(false);
                  }}
                  name="item"
                >
                  <span
                    className="absolute top-0 right-0 bottom-0 left-0 z-50"
                    id="clicker"
                  ></span>
                  <span
                    className="w-16 h-16 rounded-full flex justify-center items-center mr-3.5"
                    style={{ boxShadow: "0px 3px 10px rgba(13, 14, 43, 0.11)" }}
                  >
                    <img
                      className="w-6 h-7 object-contain"
                      src={Profile}
                      alt="Just an icon"
                    />
                  </span>
                  <div>
                    <p>
                      <span className="text-base text-textSilver font-medium mr-2.5">
                        Ism:
                      </span>
                      <span className="text-base text-textDark capitalize">
                        {el?.name}
                      </span>
                    </p>
                    <p>
                      <span className="text-base text-textSilver font-medium mr-2.5">
                        Lavozim:
                      </span>
                      <span className="text-base text-textDark">
                        {el?.role}
                      </span>
                    </p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
