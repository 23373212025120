import React, { useState, useEffect } from "react";
import { Filter } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import DatePickerInput from "../../components/DatePicker/DatePicker";
import MainButton from "../../components/MainButton/MainButton";
import { getDatePickerDate } from "../../utils/getDatePickerDate";
import { useTranslation } from "react-i18next";

export default function ExpensesHistory() {
  let today = new Date();

  let year = today.getFullYear();

  let month = String(today.getMonth() + 1).padStart(2, "0");

  let day = String(today.getDate()).padStart(2, "0");

  let formattedDate = `${year}-${month}-${day}`;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [start, setStart] = useState();

  const [end, setEnd] = useState();

  const [page, setPage] = useState(0);

  const [page2, setPage2] = useState(0);

  const [data, setData] = useState([]);

  const [isClicked, setIsClicked] = useState(false);

  let limit = 50;

  const histories = useFetch(
    baseUrl +
      (start || end
        ? `/manager-expense/history?limit=${limit}&offset=${page}&from=${
            getDatePickerDate(start) || formattedDate
          }&to=${getDatePickerDate(end) || formattedDate}`
        : `/manager-expense/history?limit=${limit}&offset=${page}`)
  );

  useEffect(() => {
    if (histories) {
      setData((prev) => [...(prev || []), histories?.data]);
      setPage2((prev) => ({
        allCount: histories?.count,
        incDataCount: histories?.data_count + prev?.incDataCount || 0,
      }));
    }
  }, [histories, end, start]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight) {
        if (page2?.allCount > page) setPage(page2.incDataCount);
        console.log("User has reached the bottom of the page.");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page2, page]);

  const handleClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked(false);
    }
  };

  useEffect(() => {
    if (isClicked) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isClicked]);

  return (
    <div className="flex flex-col h-screen bg-[#F8F5F5] gap-7">
      <div
        className="pb-4 px-4 bg-white pt-10 relative"
        style={{ boxShadow: "0px 12px 14px rgba(209, 212, 226, 0.157261)" }}
      >
        <div className="text-center mb-10 relative">
          <span
            className="h-8 w-8 cursor-pointer rounded-full text-lg text-[#354B91]  top-1/2 -translate-y-1/2 left-1 border border-[#354B91] flex justify-center items-center absolute"
            onClick={() => navigate(-1)}
          >
            &#10094;
          </span>
          <h1 className="text-xl text-brendColor">
            <span>{t("gipyurExpenses.expenseHistory")}</span>
          </h1>

          <img
            className="absolute right-2 top-1.5 cursor-pointer"
            src={Filter}
            alt="Just an icon"
            onClick={() => setIsClicked(true)}
          />
        </div>
      </div>
      <ul className="flex flex-col gap-y-7 grow px-4 pb-6 bg-[#F8F5F5]">
        {data?.length > 0 &&
          data.map(
            (item) =>
              Object.entries(item || {}).length > 0 &&
              Object.entries(item || {}).map((el, i) => (
                <li key={i}>
                  <p className="text-lg font-semibold text-textDark mb-4">
                    {el[0]}
                  </p>
                  <ul className="flex flex-col gap-y-4">
                    {el[1].length > 0 &&
                      el[1].map((it, idx) => (
                        <li
                          className="bg-white rounded-main p-4 flex flex-col gap-y-1.5"
                          key={idx}
                        >
                          <p className="text-textDark">{it?.expense_name}</p>
                          <span className="text-lg font-medium text-[#E24B4B]">
                            {it?.expense_usd} usd
                          </span>
                          <span className="w-full flex justify-between items-center">
                            <span className="text-lg font-medium text-[#E24B4B]">
                              {it?.expense_uzs} uzs
                            </span>
                            <span className="text-base text-textSilver">
                              {it?.time}
                            </span>
                          </span>
                        </li>
                      ))}
                  </ul>
                </li>
              ))
          )}
      </ul>

      <div
        className={`${
          isClicked
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed transition delay-150 duration-300 z-10 bottom-0 min-[500px]:left-1/2 min-[500px]:-translate-x-1/2 max-w-[500px] w-full left-0 right-0 flex justify-center items-end`}
        id="modal_wrapper"
        onClick={handleClick}
      >
        <div
          className={`${
            isClicked ? "translate-y-0" : "translate-y-full"
          } bg-white w-full py-5 px-4 pt-6 transition-all delay-75 ease-in-out h-3/4 overflow-y-auto flex flex-col justify-between pb-3`}
        >
          <div className="flex flex-col justify-between">
            <p className="text-lg text-brendColor font-semibold text-center mb-8">
              {t("gipyurExpenses.filter.title")}
            </p>
            <div className="flex justify-between">
              <span className="grow text-textDark">
                {t("gipyurExpenses.filter.start")}
              </span>
              <span className="grow text-textDark">
                {t("gipyurExpenses.filter.end")}
              </span>
            </div>
            <DatePickerInput
              filter={true}
              start={start}
              end={end}
              setStart={setStart}
              setEnd={setEnd}
              formattedDate={formattedDate}
            ></DatePickerInput>
          </div>
          <MainButton handleClick={() => setIsClicked(false)} type={"button"}>
            {t("gipyurExpenses.filter.sort")}
          </MainButton>
        </div>
      </div>
    </div>
  );
}
