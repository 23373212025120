import React, { useState, useRef } from "react";
import {
  CloseIcon,
  Profile,
  Filter,
  Trash,
  EditPen1,
} from "../../assets/images";
import { useNavigate } from "react-router-dom";
import BottomSheet from "../../components/BottomSheet/BottomSheet";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import { searchData } from "../../utils/SearchData";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useNotify } from "../../hooks/useNotify";
import useRole from "../../hooks/useRole";
import { useTranslation } from "react-i18next";

let newRole = {
  id: 1212,
  title: "ALL",
  role_status: "all",
};

export default function EmployeeList() {
  const { t } = useTranslation();

  const [role] = useRole();

  let [employees, refetch] = useFetch(
    baseUrl + (role?.status === 4 ? "/manager-seller" : "/employee/all"),
    true
  );

  let roles = useFetch(baseUrl + "/employee/roles");

  const notify = useNotify();

  const [token] = useToken();

  const [inputVal, setIsInputVal] = useState("");

  const [isSearch, setIsSearch] = useState(false);

  const [isClicked, setIsClicked] = useState(false);

  const [selected, setSelected] = useState({});

  const modelRef = useRef(null);

  const [status, setStatus] = useState(newRole?.role_status);

  const navigate = useNavigate();

  roles = roles?.map((el) => {
    el.title = el.role;
    return el;
  });

  employees = employees?.map((el) => {
    el.title = el?.name;
    return el;
  });

  const handleClick = (el, e) => {
    setStatus(el);

    setIsSearch(false);
  };

  function filterEmployeesByRole(status, data) {
    if (!status) return data;
    if (status === "all") return data;
    return data?.filter((el) => el?.role_status === status);
  }

  const handleModalClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked(false);
    }
  };

  const handleDelete = () => {
    if (selected?.id) {
      axios
        .delete(
          baseUrl +
            (role?.status === 4 ? "/manager-seller/" : "/employee?id=") +
            selected?.id,
          {
            method: "DELETE",
            headers: {
              Authorization: "mme:" + token,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            refetch();
            notify("Successfully!", 200);
          }
        })
        .catch((err) => notify("Xatolik !", 400));
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <div
        className="pb-4 px-4 bg-white pt-10"
        style={{ boxShadow: "0px 12px 14px rgba(209, 212, 226, 0.157261)" }}
      >
        <div className="text-center mb-10 relative">
          <span
            className="h-8 w-8 rounded-full text-lg text-[#354B91]  top-1/2 -translate-y-1/2 left-6 border border-[#354B91] flex justify-center items-center absolute"
            onClick={(e) => {
              navigate("/controlEmployee");
            }}
          >
            &#10094;
          </span>
          <h1 className="text-xl text-[#702DE3]">
            <span>
              {role?.status === 4
                ? t("director.salesmanList")
                : t("director.employeesList")}
            </span>
          </h1>
          {role?.status === 4 ? (
            ""
          ) : (
            <img
              className="absolute right-2 top-0"
              src={Filter}
              alt="Just an icon"
              onClick={() => setIsSearch(!isSearch)}
            />
          )}
        </div>

        <label className="w-full relative">
          <input
            className="bg-[#FCFCFC] border border-[#D7E1EA] w-full rounded-md p-3.5 outline-none text-sm text-textSilver"
            onChange={(e) => setIsInputVal(e.target.value)}
            type="text"
            value={inputVal}
          />
          <img
            className="absolute right-4 top-1/2 -translate-y-1/2"
            src={CloseIcon}
            alt="Just an icon"
            onClick={() => setIsInputVal("")}
          />
        </label>
      </div>

      <ul className="bg-[#F8F5F5] flex flex-col gap-y-4 pt-6 px-4 pb-4 grow">
        {searchData(inputVal, employees)?.length &&
          filterEmployeesByRole(
            status?.role_status,
            searchData(inputVal, employees)
          )?.map((el) => (
            <li
              className="bg-white w-full py-3 px-3 flex justify-between rounded-main"
              key={el?.id}
              style={{ boxShadow: "0px 3px 15px rgba(13, 14, 43, 0.05)" }}
              onClick={() => {
                setIsClicked(true);
                setSelected(el);
              }}
            >
              <div className="flex items-center">
                <span
                  className="w-16 h-16 rounded-full flex justify-center items-center mr-3.5"
                  style={{ boxShadow: "0px 3px 10px rgba(13, 14, 43, 0.11)" }}
                >
                  <img
                    className="w-6 h-7 object-contain"
                    src={Profile}
                    alt="Just an icon"
                  />
                </span>
                <div>
                  <p>
                    <span className="text-base text-textDark font-medium capitalize">
                      {el?.title}
                    </span>
                  </p>
                  <p>
                    <span className="text-base text-textSilver font-medium">
                      +{el?.phone}
                    </span>
                  </p>
                </div>
              </div>
              <span className="text-brendColor font-medium">{el?.role}</span>
            </li>
          ))}
      </ul>

      {isSearch ? (
        <BottomSheet
          handleClick={handleClick}
          data={[newRole, ...roles]}
          isClicked={isSearch}
          setIsClicked={setIsSearch}
          lang={status}
        ></BottomSheet>
      ) : (
        ""
      )}

      <div
        className={`${
          isClicked
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed top-0 bottom-0 right-0 left-0  bg-opacity-30 transition delay-150 duration-300 flex flex-col justify-end max-w-[500px] w-full mx-auto`}
        onClick={handleModalClick}
        id="modal_wrapper"
      >
        <ul
          className={`bg-white px-4 flex flex-col py-8 ${
            isClicked ? "translate-y-0" : "translate-y-full"
          } transition-all delay-75 ease-in-out`}
        >
          <li
            className="py-3 border-b border-[#D7E1EA] gap-x-2.5 flex items-center"
            onClick={() =>
              navigate(
                (role?.status !== 4 ? "/addEmployee/" : "/addSeller/") +
                  selected?.id
              )
            }
          >
            <img src={EditPen1} alt="Just an icon" />
            <p className="text-textDark font-medium text-lg">
              {t("profile.control.workersList.edit")}
            </p>
          </li>
          <li
            className="py-4 border-b border-[#D7E1EA] gap-x-2.5 flex items-center"
            onClick={() => {
              setIsClicked(false);
              modelRef.current?.classList.remove("hidden");
              modelRef.current?.classList.add("flex");
            }}
          >
            <img src={Trash} alt="Just an icon" />
            <p className="text-textDark font-medium text-lg">
              {" "}
              {t("profile.control.workersList.delete")}
            </p>
          </li>
        </ul>
      </div>

      <div
        className="fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-40 hidden justify-center items-center"
        ref={modelRef}
        onClick={() => {
          modelRef.current.classList.remove("flex");
          modelRef.current.classList.add("hidden");
        }}
      >
        <div className="bg-white py-5 w-80 rounded-main">
          <p className="text-center text-textDark font-medium mb-4">
            {t("profile.control.workersList.confirmDeletion")}
          </p>
          <div className="flex gap-x-2.5 justify-center">
            <button
              className="w-32 py-2.5 text-center block text-white rounded-md bg-[#E24B4B]"
              onClick={handleDelete}
            >
              {t("profile.control.workersList.delete")}
            </button>
            <button
              className="w-32 py-2.5 text-center block text-white rounded-md bg-[#702DE3]"
              onClick={() => {
                modelRef.current.classList.remove("flex");
                modelRef.current.classList.add("hidden");
              }}
            >
              {t("profile.control.workersList.cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
