import React from "react";
import Moment from "react-moment";
import Profile from "../../pages/Profile/Profile";
import { useNavigate } from "react-router-dom";
import useRole from "../../hooks/useRole";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { baseUrl } from "../../baseUrl";
import { useTranslation } from "react-i18next";

export default function Debtor({
  handleClick,
  id,
  salon_image,
  partner_name,
  money,
  created_at,
}) {
  const navigate = useNavigate();

  const [role] = useRole();

  const { t } = useTranslation();

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  return (
    <li
      className="w-full py-2.5 px-3 flex items-center rounded-main bg-white"
      key={id}
      style={{ boxShadow: "0px 3px 15px rgba(13, 14, 43, 0.05)" }}
      onClick={(e) => {
        handleClick(e, { id, salon_image, partner_name, money, created_at });
        navigate(`simple-sale_${id}_${role?.status === 1 ? "btn" : ""}`);
      }}
    >
      {!salon_image ? (
        <span
          className="w-16 h-16 rounded-full flex justify-center items-center mr-3.5"
          style={{ boxShadow: "0px 3px 10px rgba(13, 14, 43, 0.11)" }}
        >
          <img
            className="w-6 h-7 object-contain"
            src={Profile}
            alt="Just an icon"
          />
        </span>
      ) : (
        <LazyLoadImage
          className="w-16 h-16 object-cover rounded-full mr-3.5"
          src={baseUrl + salon_image}
          alt="Just an icon"
          onError={handleImageError}
        />
      )}
      <div>
        <p>
          <span className="text-base text-textSilver font-medium mr-2.5">
            {t("profile.message.name")}:
          </span>
          <span className="text-base text-textDark capitalize">
            {partner_name}
          </span>
        </p>
        <p>
          <span className="text-base text-textSilver font-medium mr-2.5">
            {t("home.debt")}:
          </span>
          <span className="text-base text-textDark">
            {money}
            <span className="text-mainText font-medium text-base ml-1.5">
              USD
            </span>
          </span>
        </p>
        <p>
          <span className="text-base text-textSilver font-medium mr-2.5">
            {t("addNewPartner.date")}:
          </span>
          <span className="text-base text-textDark">
            <Moment format="YYYY/MM/DD">{created_at}</Moment>
          </span>
        </p>
      </div>
    </li>
  );
}
