import React, { useEffect } from "react";

export default function BottomSheet({
  data = [],
  handleClick = () => {},
  lang,
  isClicked,
  setIsClicked,
}) {
  const handleModalClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked(false);
    }
  };

  useEffect(() => {
    if (isClicked) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isClicked]);

  return (
    <div
      className={`${
        isClicked
          ? "top-0 bg-black bg-opacity-20"
          : "top-full bg-white bg-opacity-0"
      } fixed top-0 bottom-0 right-0 left-0  bg-opacity-30 transition delay-150 duration-300 flex flex-col justify-end w-full mx-auto max-w-[500px]`}
      onClick={handleModalClick}
      id="modal_wrapper"
    >
      <ul
        className={`bg-white px-4 flex flex-col py-8 ${
          isClicked ? "translate-y-0" : "translate-y-full"
        } transition-all delay-75 ease-in-out`}
      >
        {data.map((el) => (
          <li
            className="py-3 border-b border-[#D7E1EA] flex justify-between items-center"
            key={el?.id}
            onClick={(e) => handleClick(el, e)}
          >
            <p className="text-textDark text-lg">{el?.title}</p>
            <span className="w-6 h-6 rounded-full border border-[#AFBACA] flex justify-center items-center">
              <span
                className="block w-4 h-4 rounded-full"
                style={{
                  backgroundColor: el?.id === lang?.id ? "#702DE3" : "",
                }}
              ></span>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
