import React, { useState } from "react";
import { LastoriaLogo } from "../assets/images";
import LangRadio from "../components/LangRadio/LangRadio";
import LoginForm from "../components/LoginForm/LoginForm";
import axios from "axios";
import { baseUrl } from "../baseUrl";
// import { useNotify } from "../hooks/useNotify";
import { ToastContainer } from "react-toastify";
import Confirmation from "../components/Confirmation/Confirmation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

export default function Login() {
  const [accessType, setAccessType] = useState(false);

  const [confirmationNumber, setConfirmationNumber] = useState();

  const [phoneNumber, setPhoneNumber] = useState();

  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();

    const { phoneInput } = e.target.elements;

    const validPhnoneNumber = {
      phone: "998" + phoneInput.value,
    };

    if (validPhnoneNumber.phone?.length === 12) {
      setPhoneNumber(phoneInput.value);
      axios
        .post(baseUrl + "/login/phone", validPhnoneNumber)
        .then((res) => setConfirmationNumber(res?.data.code))
        .catch((error) => console.log(error));
    } else {
    }

    try {
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    if (confirmationNumber) {
      return setConfirmationNumber(0);
    }

    if (accessType) {
      return setAccessType(false);
    }
  };
  return (
    <div className="login px-4 min-h-screen bg-cover relative bg-opacity-50 flex flex-col justify-center max-w-[500px] w-full mx-auto">
      <ToastContainer rtl autoClose={3000} />
      {accessType ? (
        <span
          className="h-8 w-8 rounded-full text-lg text-white  border border-white flex justify-center items-center absolute top-14 left-6"
          onClick={handleClick}
        >
          &#10094;
        </span>
      ) : (
        ""
      )}
      <div className="translate-y-1/3">
        {!accessType ? (
          <>
            <LazyLoadImage
              className="mx-auto"
              src={LastoriaLogo}
              alt="Login page lastoria logo"
              width={130}
              height={130}
            />
            <LangRadio></LangRadio>
            <button
              className="w-full py-4 text-base text-mainText bg-white mt-16 rounded-md"
              style={{ boxShadow: "0px 2px 14px rgba(0, 0, 0, 0.05)" }}
              onClick={() => setAccessType(true)}
            >
              {t("login.btn")}
            </button>
          </>
        ) : !confirmationNumber ? (
          <LoginForm handleSubmit={handleSubmit}></LoginForm>
        ) : (
          <Confirmation phoneNumber={phoneNumber}></Confirmation>
        )}
      </div>
    </div>
  );
}
