import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import GipyurCard from "../../components/GipyurCard/GipyurCard";
import { Filter, Search } from "../../assets/images";
import { searchData } from "../../utils/SearchData";
import BottomSheet from "../../components/BottomSheet/BottomSheet";

export default function SaleHistory() {
  const { t } = useTranslation();

  const [offset, setOffset] = useState(0);

  const [data, setData] = useState([]);

  const [inputVal, setInputVal] = useState("");

  const [isClicked, setIsClicked] = useState(false);

  const mockData = [
    { id: 1, title: "Hammasi", type: "" },
    { id: 2, title: "Oddiy sotildi", type: "SIMPLY_SOLD" },
    { id: 3, title: "Qarzga sotildi", type: "SOLD_ON_CREDIT" },
  ];

  const [selected, setSelected] = useState();

  const limit = 15;

  const gipyurs = useFetch(
    baseUrl +
      `/gipyur-sale/history?${
        selected?.type ? `type=${selected.type}` : ""
      }&limit=${limit}&offset=${offset * limit}`
  );

  useEffect(() => {
    if (gipyurs?.data?.length) {
      setData([...data, ...gipyurs?.data]);
    }
  }, [gipyurs?.data]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (
        scrollTop + clientHeight >= scrollHeight - 50 &&
        gipyurs?.count >= offset * limit
      ) {
        setOffset(offset + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offset, gipyurs?.count]);

  const handleClick = (el) => {
    setSelected(el);
    setIsClicked(false);
    setOffset(0);
    setData([]);
  };

  return (
    <div>
      <div className="relative">
        <Header
          navPath={-1}
          heading={t("gipyurInfo.history")}
          icon={true}
        ></Header>
        <img
          className="absolute top-1/2 -translate-y-1/2 right-9 w-6 h-6"
          src={Filter}
          alt="Just an icon"
          onClick={() => setIsClicked(true)}
        />
      </div>
      <div className="flex-grow bg-[#F8F5F5] px-4 pt-6 pb-7">
        <div className="relative mb-6">
          <input
            className="w-full text-lg py-3 rounded-main pl-12 outline-none border border-[#E6E6EA] text-[#72809D]"
            type="text"
            placeholder={t("gipyurInfo.search")}
            onChange={(e) => setInputVal(e.target.value)}
          />
          <img
            className="absolute top-1/2 -translate-y-1/2 left-4"
            src={Search}
            alt="Just an icon"
          />
        </div>
        <ul className="grid grid-cols-2 gap-4">
          {data?.length > 0 &&
            searchData(
              inputVal,
              data.map((el) => {
                el.title = el.gipyur_code;
                return el;
              })
            ).map((el) => (
              <GipyurCard
                key={el?.id}
                id={el?.id}
                code={el?.gipyur_code}
                color={el?.gipyur_color}
                inShop={el?.in_shop}
                metr={el?.gipyur_metr}
                image={el?.gipyur_image}
                history={true}
              ></GipyurCard>
            ))}
        </ul>
      </div>
      <BottomSheet
        data={mockData}
        handleClick={handleClick}
        setIsClicked={setIsClicked}
        isClicked={isClicked}
        lang={selected || mockData[0]}
      ></BottomSheet>
    </div>
  );
}
