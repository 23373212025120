import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { Person } from "../../assets/images";
import MainInput from "../../components/MainInput/MainInput";
import LoginInput from "../../components/MainInput/LoginInput";
import MainButton from "../../components/MainButton/MainButton";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { baseUrl } from "../../baseUrl";
import { useNotify } from "../../hooks/useNotify";
import { useNavigate } from "react-router-dom";
import useLoading from "../../hooks/useLoading";

export default function AddNewClient() {
  const [token] = useToken();

  const notify = useNotify();

  const [_, setIsLoading] = useLoading()

  const navigate = useNavigate();

  const [imgUrl, setImgUrl] = useState("");

  const [image, setImage] = useState("");

  const handleChange = (e) => {
    try {
      setImgUrl(URL.createObjectURL(e.target.files[0]));

      let formData = new FormData();

      formData.append("file", e.target.files[0]);

      axios
        .post(baseUrl + "/file-upload", formData, {
          method: "POST",
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res?.data?.image) {
            setImage(res?.data?.image);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { gipyur_client_name, gipyur_client_phone } = e.target.elements;

    const newClient = {
      gipyur_client_image: image || null,
      gipyur_client_name: gipyur_client_name.value,
      gipyur_client_phone: "998" + gipyur_client_phone.value,
    };

    if (gipyur_client_name.value && gipyur_client_phone.value) {
      setIsLoading(true)
      axios
        .post(baseUrl + "/gipyur-client/create", newClient, {
          method: "POST",
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res?.data?.data?.id) {
            setIsLoading(false)
            notify("Successfully !", 200);
            navigate(-1);
          }
        }).catch(() => {
          setIsLoading(false)
        });
    } else {
      setIsLoading(false)
      notify("Formani to'ldiring!", 300);
      gipyur_client_name.style.borderColor = "red";
      gipyur_client_phone.style.borderColor = "red";
    }
  };
  return (
    <div className="bg-white flex flex-col h-screen">
      <Header
        heading={"Yangi mijoz kiritish"}
        icon={true}
        navPath={-1}
      ></Header>

      <form
        className="flex-grow flex flex-col justify-between gap-y-6 pt-8 pb-4 px-4"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-7">
          <label className="w-24 h-24 rounded-full bg-[#F1F1F3] mx-auto relative flex justify-center items-center">
            {imgUrl ? (
              <img
                className="w-full h-full rounded-full"
                src={imgUrl}
                alt="pic"
              />
            ) : (
              <img src={Person} alt="Just an icon" />
            )}
            <span className="w-4 absolute bottom-0 right-0 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center h-4 rounded-full bg-[#00AEDA] text-white">
              +
            </span>
            <input className="hidden" type="file" onChange={handleChange} />
          </label>
          <MainInput
            title={"Mijoz ismi"}
            name={"gipyur_client_name"}
            type="text"
          ></MainInput>
          <div className="">
            <span className="text-textDark block mb-1.5">
              Mijoz telefon raqami
            </span>
            <div>
              <LoginInput
                variant={"mainInput"}
                name={"gipyur_client_phone"}
                type="tel"
              ></LoginInput>
            </div>
          </div>
        </div>
        <MainButton type={"submit"}>Saqlash</MainButton>
      </form>
    </div>
  );
}
