import React, { useState } from "react";
import MainInput from "../MainInput/LoginInput";
import { useTranslation } from "react-i18next";

export default function LoginForm({ handleSubmit }) {
  const [inputVal, setInputVal] = useState("");

  const { t } = useTranslation();

  const handleChange = (e) => {
    setInputVal(e.target.value);
  };
  return (
    <form onSubmit={handleSubmit} className="flex flex-col">
      <MainInput
        title={t("login.label")}
        type="tel"
        name={"phoneInput"}
        handleChange={handleChange}
      ></MainInput>
      <button
        className="w-full py-4 text-base text-mainText bg-white mt-16 rounded-md"
        style={{
          boxShadow: "0px 2px 14px rgba(0, 0, 0, 0.05)",
          backgroundColor: inputVal.length === 9 ? "#702DE3" : "#fff",
          color: inputVal.length === 9 ? "#fff" : "",
        }}
      >
        {t("login.btn")}
      </button>
    </form>
  );
}
