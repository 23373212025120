import React from "react";
import i18next from "i18next";

const data = [
  { id: 1, title: "English", ids: "en" },
  { id: 2, title: "O'zbekcha", ids: "uz" },
  { id: 3, title: "Русский", ids: "ru" },
];

export default function LangRadio() {
  const handleChange = (e) => {
    window.localStorage.setItem("lang", e.target.id || "uz");
    i18next.changeLanguage(e.target.id);
  };
  return (
    <ul className="bg-white flex  justify-around w-full mt-10 rounded-md">
      {data.length &&
        data.map((el) => (
          <li className="w-full" key={el.id}>
            <label className="text-center block">
              <input
                className="login__radio hidden"
                type="radio"
                name="input"
                id={el.ids}
                onChange={handleChange}
              />
              <span className="login__span text-base w-full block py-4 text-mainText">
                {el.title}
              </span>
            </label>
          </li>
        ))}
    </ul>
  );
}
