import React, { useEffect, useState } from "react";
import ReportsList from "../../components/ReportsList/ReportsList";
import {
  Settings,
  Discount,
  Wrench,
  ControlWorkers,
} from "../../assets/images";
import { useNavigate } from "react-router-dom";
import useRole from "../../hooks/useRole";
import { useTranslation } from "react-i18next";

let data = [
  {
    id: 1,
    title: "",
    icon: Settings,
    path: "/settings",
  },
  { id: 2, title: "", icon: Discount, path: "/aboutService" },
  {
    id: 3,
    title: "",
    icon: Wrench,
    path: "/messageToDev",
  },
];

export default function Profile() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [mockData, setMockData] = useState([]);

  const [role] = useRole();

  let forDirector = {
    id: 4,
    title: t("profile.control.heading"),
    icon: ControlWorkers,
    path: "/controlEmployee",
  };

  let forManager = {
    id: 4,
    title: t("profile.sellers.heading"),
    icon: ControlWorkers,
    path: "/controlSellers",
  };

  const titles = [
    { id: 1, title: t("profile.settings.title") },
    { id: 2, title: t("profile.about") },
    { id: 3, title: t("profile.message.heading") },
  ];

  data = data.map((el) => {
    el.title = titles.find((it) => it.id === el.id).title;
    return el;
  });

  useEffect(() => {
    if (role?.status === 1) {
      setMockData([...data, forDirector]);
    } else if (role?.status === 4) {
      setMockData([...data, forManager]);
    } else {
      setMockData(data);
    }
  }, [role]);

  useEffect(() => {
    const elEditImg = document.getElementById("edit_profile_form");
    if (elEditImg) {
      elEditImg.addEventListener("click", () => {
        navigate("/editProfile");
      });
    }
  }, [navigate]);

  return (
    <div className="pb-24 px-4">
      <ReportsList data={mockData}></ReportsList>
    </div>
  );
}
