import React from "react";
import Header from "../../components/Header/Header";
import ReportsList from "../../components/ReportsList/ReportsList";
import {
  Book,
  ShoppingBag,
  SecondBag,
  FiftyFifty,
  Receipt,
} from "../../assets/images";
import { useTranslation } from "react-i18next";

export default function Sell() {
  const { t } = useTranslation();

  const data = [
    {
      id: 1,
      title: t("pages.dressesList"),
      icon: Receipt,
      path: "/dressesList",
    },
    { id: 2, title: t("pages.booking"), icon: Book, path: "/booking" },
    {
      id: 3,
      title: t("pages.simpleSale"),
      icon: ShoppingBag,
      path: "/simpleSale",
    },
    {
      id: 4,
      title: t("pages.fiftyFifty"),
      icon: FiftyFifty,
      path: "/fiftyFifty",
    },
    { id: 5, title: t("pages.accessory"), icon: SecondBag, path: "/accessory" },
  ];
  return (
    <>
      <Header heading={"LaStoria"} user={true}></Header>
      <div className="px-4 pb-24">
        <ReportsList data={data}></ReportsList>
      </div>
    </>
  );
}
