import React from "react";

import { Context } from "../context/Autentication";

const useUserId = (setterOnly = false) => {
  const ctx = React.useContext(Context);
  return setterOnly ? [ctx.setUserId] : [ctx.userId, ctx.setUserId];
};

export default useUserId;
