import React from "react";
import { baseUrl } from "../../baseUrl";
import useFetch from "../../hooks/useFetch";
import useRole from "../../hooks/useRole";
import Header from "../../components/Header/Header";
import Accordion from "../../components/Accordion/Accordion";
import { useTranslation } from "react-i18next";

export default function AboutService() {
  const [userRole] = useRole();

  const { t } = useTranslation();

  const aboutData = useFetch(
    baseUrl + "/other/about-service?roleStatus=" + userRole?.status
  );

  return (
    <div className="flex flex-col h-screen">
      <Header
        icon={true}
        heading={t("profile.about")}
        navPath="/profile"
      ></Header>
      <div className="flex flex-col gap-y-3 px-4 py-3 bg-[#F8F5F5] grow">
        {aboutData?.length > 0 &&
          aboutData.map((el) => (
            <Accordion
              key={el?.id}
              title={el?.title}
              body={el?.body}
            ></Accordion>
          ))}
      </div>
    </div>
  );
}
