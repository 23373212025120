import React from "react";
import { ArrowRight } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function ReportsList({ data = [] }) {
  const navigate = useNavigate();
  return (
    <ul className="flex flex-col gap-y-3 pt-5">
      {data.map((el) => (
        <li
          className="border border-[#E6E6EA] p-7 flex items-center justify-between rounded-main bg-white"
          key={el.id}
          onClick={() => navigate(el.path, { state: { comeBack: "/report" } })}
        >
          <div className="flex items-center">
            <span className="w-11 h-11 border border-[#E6E6EA]  flex justify-center items-center rounded-md">
              {el?.icon ? (
                <LazyLoadImage
                  className="brend_svg"
                  src={el.icon}
                  alt="Just an icon"
                ></LazyLoadImage>
              ) : (
                ""
              )}
            </span>
            <span className="text-textDark text-base ml-7">{el.title}</span>
          </div>
          <LazyLoadImage src={ArrowRight} alt="arrow icon"></LazyLoadImage>
        </li>
      ))}
    </ul>
  );
}
