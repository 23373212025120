import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { Clock } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import CurrencyCalculator from "../../components/CurrencyCalculator/CurrencyCalculator";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import MainInput from "../../components/MainInput/MainInput";
import ButtonSheetSimple from "../../components/BottomSheet/BottomSheetSimple";
import MainButton from "../../components/MainButton/MainButton";
import useToken from "../../hooks/useToken";
import useUserId from "../../hooks/useUserId";
import axios from "axios";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useRole from "../../hooks/useRole";
import useLoading from "../../hooks/useLoading";

const places = [
  { id: 3, title: "TexnoStyle", status: "TEXNO_STYLE" },
  { id: 2, title: "1SONiA", status: "1SONIA" },
  { id: 1, title: "Lastoria", status: "LASTORIA" },
  { id: 4, title: "LastoriaDantel", status: "LASTORIA_DANTEL" },
];

export default function AddExpenses() {
  const currency = useFetch(baseUrl + "/other/central-bank-usd-rate");

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [userId] = useUserId();

  const [token] = useToken();

  const [_, setIsLoading] = useLoading();

  const [role] = useRole();

  const [showAlert] = useAlert();

  const [organization, setOrganization] = useState(places[2]);

  const userCash = useFetch(baseUrl + "/employee-cash?user_id=" + userId);

  const handleSubmit = (e) => {
    e.preventDefault();

    let elements = e.target.elements;

    const { expense_name, expense_usd, expense_uzs } = e.target.elements;

    let expense = {
      expense_name: expense_name?.value,
      expense_usd: +expense_usd?.value || 0,
      expense_uzs: +expense_uzs?.value || 0,
      expense_organization:
        role?.status === 5 ? "LASTORIA_DANTEL" : organization?.status,
    };

    if ((expense_usd?.value || expense_uzs?.value) && expense_name.value) {
      setIsLoading(true);
      axios
        .post(baseUrl + "/manager-expense", expense, {
          method: "POST",
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res?.data?.data?.id) {
            setIsLoading(false);
            showAlert(
              "Muvafaqqiyatli!",
              "Jarayon muvafaqqiyatli tugallandi...",
              "success"
            );
            navigate(-1);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          showAlert("Xatolik !", "Xatolik yuz berdi!", "error");
        });
    } else {
      showAlert("Ogohlantirish", "Forma to'liq to'ldirilmadi...", "warning");
      for (let el of elements) {
        if (!el.value && el.name) {
          el.style.borderColor = "red";
        }
      }
    }
  };
  return (
    <div className="flex flex-col h-screen bg-white">
      <div className="relative">
        <Header
          heading={t("gipyurExpenses.heading")}
          icon={true}
          navPath={-1}
        ></Header>
        <img
          className="absolute top-1/2 -translate-y-1/2 right-9 w-7 h-7"
          src={Clock}
          alt="Just an icon"
          onClick={() => navigate("/expensesHistory")}
        />
      </div>
      <ul className="px-4 flex justify-between gap-x-4 items-center my-7">
        <li
          className="py-3 bg-white flex flex-col gap-y-2 w-1/2 items-center rounded-main"
          style={{ boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.10)" }}
          onClick={() => (role?.status === 5 ? {} : navigate("/giveMoney"))}
        >
          <span className="text-textDark font-medium">
            {userCash?.employee_cash_usd}
          </span>
          <span className="text-textSilver">
            {t("gipyurExpenses.statCard")} USD
          </span>
        </li>
        <li
          className="py-3 bg-white flex flex-col gap-y-2 w-1/2 items-center rounded-main"
          style={{ boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.10)" }}
          onClick={() => (role?.status === 5 ? {} : navigate("/giveMoney"))}
        >
          <span className="text-textDark font-medium">
            {userCash?.employee_cash_uzs}
          </span>
          <span className="text-textSilver">
            {" "}
            {t("gipyurExpenses.statCard")} UZS
          </span>
        </li>
      </ul>
      <form
        className="flex flex-col grow justify-between px-4 pb-4"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-6 ">
          <CurrencyCalculator
            currency={currency?.usd_rate}
          ></CurrencyCalculator>
          <MainInput
            name={"expense_name"}
            title={t("gipyurExpenses.form.expenseName")}
          ></MainInput>
          {role?.status !== 5 ? (
            <ButtonSheetSimple
              title={t("gipyurExpenses.form.institution")}
              data={places}
              select={true}
              name1={"expense_organization"}
              setData={setOrganization}
            ></ButtonSheetSimple>
          ) : (
            ""
          )}
          <div>
            <span className="text-textDark block mb-1.5">
              {t("gipyurExpenses.form.expensePrice")}
            </span>
            <div className="flex justify-between gap-x-4">
              <MainInput
                name={"expense_usd"}
                currency={"usd"}
                type="number"
              ></MainInput>
              <MainInput
                name={"expense_uzs"}
                currency={"uzs"}
                type="number"
              ></MainInput>
            </div>
          </div>
        </div>
        <MainButton type={"submit"}>{t("submit")}</MainButton>
      </form>
    </div>
  );
}
