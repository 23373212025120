import React, { useEffect, useState } from "react";
import ButtonToggle from "../../components/ButtonToggle/ButtonToggle";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import Debtor from "../../components/Debtor/Debtor";
import { Search, CloseIcon } from "../../assets/images";
import { useNavigate, useLocation } from "react-router-dom";
import { searchData } from "../../utils/SearchData";
import Debtor2 from "../../components/Debtor/Debtor2";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/formatNumber";

export default function Debtors() {
  const [isClicked, setIsClicked] = useState(false);

  const [isSearch, setIsSearch] = useState(false);

  const [inputVal, setIsInputVal] = useState("");

  const [simpleData, setSimpleData] = useState([]);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const data = useFetch(baseUrl + "/simple-sale/debt/all");

  const data1 = useFetch(baseUrl + "/sale5050/debt/all");

  const location = useLocation();

  const comeBack = location?.state?.comeBack;

  useEffect(() => {
    if (comeBack) {
      window.sessionStorage.setItem("path", comeBack);
    }
  }, [comeBack]);

  useEffect(() => {
    if (!isClicked) {
      setSimpleData(
        data?.map((el) => {
          return {
            date_gives_debt: el?.date_gives_debt,
            id: el?.id,
            salon_debt: el?.salon_debt,
            salon_image: el?.salon_image,
            title: el?.salon_name,
          };
        })
      );
    } else {
      setSimpleData(
        data1?.map((el) => {
          return {
            id: el?.id,
            title: el?.salon_name,
            salon_image: el?.salon_image,
            bride_name: el?.bride_name,
            date_gives_debt: el?.date_gives_debt,
            bride_debt: el?.bride_debt,
            salon_debt: el?.salon_debt,
          };
        })
      );
    }
  }, [data, isClicked, data1]);

  const handleClick = (e, el) => {};

  return (
    <>
      <div
        className="pb-4 px-4 bg-white pt-10"
        style={{ boxShadow: "0px 12px 14px rgba(209, 212, 226, 0.157261)" }}
      >
        <div className="text-center mb-10 relative">
          <span
            className="h-8 w-8 rounded-full text-lg text-[#354B91]  top-1/2 -translate-y-1/2 left-6 border border-[#354B91] flex justify-center items-center absolute"
            onClick={(e) => {
              navigate(window.sessionStorage.getItem("path") || -1);
            }}
          >
            &#10094;
          </span>
          <h1 className="text-xl text-[#706189]">
            <span>{t("pages.debtorsList")}</span>
          </h1>
          <img
            className="absolute right-2 top-0"
            src={Search}
            alt="Just an icon"
            onClick={() => setIsSearch(!isSearch)}
          />
        </div>
        {!isSearch ? (
          <ButtonToggle
            setIsClicked={setIsClicked}
            isClicked={isClicked}
            toggle1={t("pages.simpleSale")}
            toggle2={t("pages.fiftyFifty")}
          ></ButtonToggle>
        ) : (
          <label className="w-full relative">
            <input
              className="bg-[#FCFCFC] border border-[#D7E1EA] w-full rounded-md p-3.5 outline-none text-sm text-textSilver"
              onChange={(e) => setIsInputVal(e.target.value)}
              type="text"
              value={inputVal}
            />
            <img
              className="absolute right-4 top-1/2 -translate-y-1/2"
              src={CloseIcon}
              alt="Just an icon"
              onClick={() => setIsInputVal("")}
            />
          </label>
        )}
      </div>

      <div className="bg-[#F8F5F5] py-6 px-4 h-full">
        <div className="p-4 border border-[#E6E6EA] bg-white rounded-main mb-6">
          <p className="mb-4 text-black font-medium">
            {t("debtorsPage.totalCount")}: {data?.length}
          </p>
          <p className="text-black font-medium">
            {t("total")} <span className="text-[#354B91]">USD: </span>
            {formatNumber(
              simpleData
                ?.reduce((sum, item) => sum + item.salon_debt, 0)
                .toFixed(2)
            )}
          </p>
        </div>
        <ul className="flex flex-col gap-y-2.5">
          {searchData(inputVal, simpleData)?.map((el) =>
            !isClicked ? (
              <Debtor
                key={el?.id}
                id={el?.id}
                salon_image={el?.salon_image}
                partner_name={el?.title}
                money={el?.salon_debt}
                created_at={el?.date_gives_debt}
                handleClick={handleClick}
              />
            ) : (
              <Debtor2 key={el?.id} data={el} handleClick={handleClick} />
            )
          )}
        </ul>
      </div>
    </>
  );
}
