import React from "react";
import Header from "../../components/Header/Header";
import ReportsList from "../../components/ReportsList/ReportsList";
import { Profile, AddEmployee } from "../../assets/images";
import useRole from "../../hooks/useRole";
import { useTranslation } from "react-i18next";

export default function ControlEmployee() {
  const { t } = useTranslation();

  const [role] = useRole();

  const data = [
    {
      id: 1,
      title: t("director.employeesList"),
      icon: Profile,
      path: "/employeeList",
    },
    {
      id: 2,
      title: t("director.addEmployee"),
      icon: AddEmployee,
      path: "/addEmployee",
    },
  ];

  const data1 = [
    {
      id: 1,
      title: "Sotuvchilar ro’yxati",
      icon: Profile,
      path: "/employeeList",
    },
    {
      id: 2,
      title: "Sotuvchi qo’shish",
      icon: AddEmployee,
      path: "/addSeller",
    },
  ];

  return (
    <>
      <Header
        icon={true}
        heading={`${
          role?.status === 4
            ? "Sotuvchilarni boshqarish"
            : t("profile.control.heading")
        }`}
        navPath="/profile"
      ></Header>
      <div className="px-4">
        <ReportsList data={role?.status === 4 ? data1 : data}></ReportsList>
      </div>
    </>
  );
}
