import React from "react";
import { formatNumber } from "../../utils/formatNumber";

export default function Statistics({ data, status }) {
  return (
    <ul className="flex flex-col gap-y-4 mb-16">
      {data.map((el) => (
        <li
          className="flex justify-between p-3 rounded-main border border-[#E6E6EA]"
          key={el.id}
        >
          <span className="flex flex-col gap-y-1">
            <span className="text-lg text-[#090A2D]">{el.title}</span>
            {status !== "salon" ? (
              <span
                className="text-[#702DE3] text-lg"
                style={{ color: el?.color }}
              >
                {el?.count || ""}
              </span>
            ) : (
              <>
                <span
                  className="text-[#702DE3] text-lg"
                  style={{ color: el?.color }}
                >
                  {formatNumber(el?.usd?.count)}{" "}
                  <span className="text-mainText font-semibold uppercase">
                    {el?.usd?.label}
                  </span>
                </span>
                <span
                  className="text-[#702DE3] text-lg"
                  style={{ color: el?.color }}
                >
                  {formatNumber(el?.uzs?.count)}{" "}
                  <span className="text-mainText font-semibold uppercase">
                    {el?.uzs?.label}
                  </span>
                </span>
              </>
            )}
          </span>
          <span
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: el.color }}
          ></span>
        </li>
      ))}
    </ul>
  );
}
