import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./Layout/Layout";
import Home from "./pages/Home";
import Reports from "./pages/Reports/Reports";
import Profile from "./pages/Profile/Profile";
import ViewReport from "./pages/Reports/ViewReport";
import AddNewProduct from "./pages/Reports/AddNewProduct";
import WareHouseProducts from "./pages/Reports/WareHouseProducts";
import Expenses from "./pages/Reports/Expenses";
import RejectedOrders from "./pages/Reports/RejectedOrders";
import RejectedOrdersPage from "./pages/Reports/RejectedOrdersPage";
import PartnersList from "./pages/Reports/PartnersList";
import AddPartner from "./pages/Reports/AddPartner";
import ClosePartnerDebt from "./pages/Reports/ClosePartnerDebt";
import EditProfile from "./pages/Profile/EditProfile";
import Setting from "./pages/Profile/Setting";
import AboutService from "./pages/Profile/AboutService";
import MessageToDevs from "./pages/Profile/MessageToDevs";
import Sell from "./pages/Sell/Sell";
import Booking from "./pages/Sell/Booking";
import Accessory from "./pages/Sell/Accessory";
import Debtors from "./pages/Sell/Debtors";
import DebtorPage from "./pages/Sell/DebtorPage";
import SimpleSale from "./pages/Sell/SimpleSale";
import SaleFiftyFifty from "./pages/Sell/SaleFiftyFifty";
import OrdersList from "./pages/Sell/OrdersList";
import AnOrderPage from "./pages/Sell/AnOrderPage";
import AllSoldList from "./pages/Sell/AllSoldList";
import AddNewSalon from "./pages/Sell/AddNewSalon";
import AddDress from "./pages/Sell/AddDress";
import StatisticsPage from "./pages/Director/Statistics";
import DirectorReport from "./pages/Director/DirectorReport";
import ViewDirectorReports from "./pages/Director/ViewDirectorReports";
import ControlEmployee from "./pages/Director/ControlEmployee";
import EmployeeList from "./pages/Director/EmployeeList";
import AddEmployee from "./pages/Director/AddEmployee";
import GipyurInfo from "./pages/Manager/GipyurInfo";
import AddGipyur from "./pages/Manager/AddGipyur";
import GipyurColor from "./pages/Manager/GipyurColor";
import OneGipyurPage from "./pages/Manager/OneGipyurPage";
import SellGipyur from "./pages/Manager/SellGipyur";
import SellSimple from "./pages/Manager/SellSimple";
import SellForDebt from "./pages/Manager/SellForDebt";
import AddNewClient from "./pages/Manager/AddNewClient";
import GipyurDistirbution from "./pages/Manager/GipyurDistirbution";
import GipyurHistory from "./pages/Manager/GipyurHistory";
import AddExpenses from "./pages/Manager/AddExpenses";
import ExpensesHistory from "./pages/Manager/ExpensesHistory";
import GiveMoney from "./pages/Manager/GiveMoney";
import MoneyGiveHistory from "./pages/Manager/MoneyGiveHistory";
import ControlSellers from "./pages/Profile/ControlSellers";
import ListOfDebtors from "./pages/Manager/ListOfDebtors";
import AddSeller from "./pages/Director/AddSeller";
import AddSalonOrDress from "./pages/Sell/AddSalonOrDress";
import OneDebtorPage from "./pages/Manager/OneDebtorPage";
import DressesList from "./pages/Sell/DressesList";
import { ToastContainer } from "react-toastify";
import PartnerPage from "./pages/Reports/PartnerPage";
import SaleHistory from "./pages/Manager/SaleHistory";
import Loading from "./components/Loading/Loading";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/dist/sweetalert2.css";

export default function AuthenticatedApp() {
  return (
    <div className="main_wrapper max-w-[500px] w-full mx-auto">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <Routes>
        <Route
          path="/*"
          element={
            <Layout>
              <Route path="/" element={<Home></Home>} />
              <Route path="/report" element={<Reports></Reports>} />
              <Route path="/profile" element={<Profile></Profile>} />
              <Route path="/sell" element={<Sell />} />
              <Route path="/statistics" element={<Reports />} />
              <Route path="/directorReport" element={<DirectorReport />} />
              <Route
                path="/directorReport/:path"
                element={<ViewDirectorReports />}
              />
            </Layout>
          }
        />
        <Route path="/salonStatistics" element={<StatisticsPage />} />
        <Route path="/orderStatistics" element={<StatisticsPage />} />
        <Route path="/orders" element={<OrdersList></OrdersList>} />
        <Route path="/orders/:orderId" element={<AnOrderPage></AnOrderPage>} />
        <Route path="/viewReport" element={<ViewReport></ViewReport>} />
        <Route path="/addNewProduct" element={<AddNewProduct />} />
        <Route path="/warehouseProducts" element={<WareHouseProducts />} />
        <Route path="/expenses" element={<Expenses />} />
        <Route path="/rejectedOrders" element={<RejectedOrders />} />
        <Route path="/rejectedOrders/:id" element={<RejectedOrdersPage />} />
        <Route path="/partnersList" element={<PartnersList />} />
        <Route path="/partnersList/:id" element={<PartnerPage />} />
        <Route path="/addPartner" element={<AddPartner />} />
        <Route path="/closePartnerDebt" element={<ClosePartnerDebt />} />
        <Route path="/editProfile" element={<EditProfile />} />
        <Route path="/settings" element={<Setting />} />
        <Route path="/aboutService" element={<AboutService />} />
        <Route path="/messageToDev" element={<MessageToDevs />} />
        <Route path="/accessory" element={<Accessory />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/debtors" element={<Debtors />} />
        <Route path="/debtors/:id" element={<DebtorPage />} />
        <Route path="/simpleSale" element={<SimpleSale />} />
        <Route path="/fiftyFifty" element={<SaleFiftyFifty />} />
        <Route path="/ordersList" element={<OrdersList />} />
        <Route path="/ordersList/:id" element={<AnOrderPage />} />
        <Route path="/allSold" element={<AllSoldList />} />
        <Route path="/addNewSalon" element={<AddNewSalon />} />
        <Route path="/addDress" element={<AddDress />} />
        <Route path="/controlEmployee" element={<ControlEmployee />} />
        <Route path="/employeeList" element={<EmployeeList />} />
        <Route path="/addEmployee" element={<AddEmployee />} />
        <Route path="/addEmployee/:id" element={<AddEmployee />} />
        <Route path="/gipyurInfo" element={<GipyurInfo />} />
        <Route path="/gipyurInfo/:id" element={<OneGipyurPage />} />
        <Route path="/addGipyur" element={<AddGipyur />} />
        <Route path="/gipyurColor" element={<GipyurColor />} />
        <Route path="/addNewCategory" element={<GipyurColor />} />
        <Route path="/sellGipyur" element={<SellGipyur />} />
        <Route path="/sellSimple" element={<SellSimple />} />
        <Route path="/sellForDebt" element={<SellForDebt />} />
        <Route path="/addNewClient" element={<AddNewClient />} />
        <Route path="/distirbuteGipyur" element={<GipyurDistirbution />} />
        <Route path="/gipyurHistory" element={<GipyurHistory />} />
        <Route path="/addExpenses" element={<AddExpenses />} />
        <Route path="/expensesHistory" element={<ExpensesHistory />} />
        <Route path="/giveMoney" element={<GiveMoney />} />
        <Route path="/moneyGiveHistory" element={<MoneyGiveHistory />} />
        <Route path="/controlSellers" element={<ControlSellers />} />
        <Route path="/listOfDebtors" element={<ListOfDebtors />} />
        <Route path="/listOfDebtors/:id" element={<OneDebtorPage />} />
        <Route path="/addSeller" element={<AddSeller />} />
        <Route path="/addSeller/:id" element={<AddSeller />} />
        <Route path="/addSalonOrDress" element={<AddSalonOrDress />} />
        <Route path="/saleHistory" element={<SaleHistory />} />
        <Route path="/dressesList" element={<DressesList />} />
      </Routes>
      <Loading></Loading>
    </div>
  );
}
