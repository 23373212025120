import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Header from "../../components/Header/Header";
import { baseUrl } from "../../baseUrl";
import {
  GivenMoney,
  Receipt,
  Calender,
  Wallet,
  Profile1,
} from "../../assets/images";
import { getDate } from "../../utils/getDate";
import MainButton from "../../components/MainButton/MainButton";
import CurrencyCalculator from "../../components/CurrencyCalculator/CurrencyCalculator";
import axios from "axios";
import useToken from "../../hooks/useToken";
import useRole from "../../hooks/useRole";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";
import useUserId from "../../hooks/useUserId";

export default function DebtorPage() {
  const { id } = useParams();

  const { t } = useTranslation();

  const [token] = useToken();

  const [_, setIsLoading] = useLoading();

  const [role] = useRole();

  const [userId] = useUserId();

  const [showAlert] = useAlert();

  let path = id.split("_")[0];

  let url = id.split("_")[1];

  let btn = id.split("_")[2];

  const [data, setData] = useState({});

  const [isClicked, setIsClicked] = useState(false);

  const [product, refetch] = useFetch(baseUrl + `/${path}/${url}`, true);

  const currency = useFetch(baseUrl + "/other/central-bank-usd-rate");

  useEffect(() => {
    setData(product);
  }, [product]);

  const handleModalClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked(false);
    }
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      money_bride_usd,
      money_bride_uzs,
      money_salon_usd,
      money_salon_uzs,
    } = e.target.elements;

    if (path === "simple-sale") {
      let newDebt = {
        money_salon_usd: +money_salon_usd?.value || 0,
        money_salon_uzs: +money_salon_uzs?.value || 0,
        central_bank_usd_course: currency?.usd_rate,
        simple_sale_id: url,
        debt: data?.salon_debt,
        created_by_user: userId,
      };

      if (
        (money_salon_usd?.value || money_salon_uzs?.value) &&
        Math.abs(product?.salon_debt) >=
          newDebt?.money_salon_usd +
            newDebt?.money_salon_uzs / currency?.usd_rate
      ) {
        setIsLoading(true);
        return axios
          .post(baseUrl + "/simple-sale/debt/payment", newDebt, {
            method: "POST",
            headers: {
              Authorization: `mme:${token}`,
            },
          })
          .then((res) => {
            if (res.data?.data?.id) {
              setIsLoading(false);
              showAlert(
                "Muvafaqqiyatli!",
                "Qarz uchun pul muvafaqqiyatli to'landi!",
                "success"
              );
              setIsClicked(false);
              refetch();
            }
          })
          .catch((err) => {
            setIsLoading(false);
            showAlert("Xatolik !", "Xatolik yuz berdi!", "error");
          });
      } else {
        showAlert(
          "Ogohlantirish!",
          "Qarzdorlikdan ortiqcha summa kiritish mumkin emas!",
          "warning"
        );
      }
    } else {
      let newDebt = {
        money_salon_usd: +money_salon_usd?.value || 0,
        money_salon_uzs: +money_salon_uzs?.value || 0,
        money_bride_usd: +money_bride_usd?.value || 0,
        money_bride_uzs: +money_bride_uzs?.value || 0,
        central_bank_usd_course: currency?.usd_rate,
        sale_50_50_id: url,
        bride_passport_given: data?.bride_passport_taken,
        debt_bride: data?.bride_debt,
        debt_salon: data?.salon_debt,
        created_by_user: userId,
      };

      if (
        money_salon_usd?.value ||
        money_salon_uzs?.value ||
        money_bride_usd?.value ||
        money_bride_uzs?.value
      ) {
        setIsLoading(true)
        return axios
          .post(baseUrl + "/sale5050/debt/payment", newDebt, {
            method: "POST",
            headers: {
              Authorization: `mme:${token}`,
            },
          })
          .then((res) => {
            if (res?.data?.data?.id) {
              setIsLoading(false)
              showAlert(
                "Muvafaqqiyatli!",
                "Qarz uchun pul muvafaqqiyatli to'landi!",
                "success"
              );
              refetch();
              setIsClicked(false);
            }
          })
          .catch((err) =>{
            setIsLoading(false)
            showAlert("Xatolik !", "Xatolik yuz berdi!", "error")}
          );
      }
    }
  };

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  return (
    <>
      <Header
        icon={true}
        heading={data?.salon_name}
        navPath={`${!btn || role?.status === 1 ? "/debtors" : "/allSold"}`}
      ></Header>
      <div className="pt-6 bg-white px-4 pb-6 ">
        <img
          className="w-full block rounded-main mb-7 h-64 object-cover"
          src={baseUrl + data?.salon_image}
          alt="salon pic"
          onError={handleImageError}
        />
        <span className="w-full text-textSilver block border-b border-[#E6E6EA] pb-1 mb-4">
          {t("debtorsPage.salonInfo")}:
        </span>
        <ul className="border border-[#E6E6EA] p-4 mb-6 flex flex-col gap-y-4 rounded-main">
          {path === "sale5050" ? (
            <li className="flex items-center gap-x-2">
              <img
                src={Wallet}
                alt="Just an icon"
                style={{ fill: "#702DE3" }}
              />
              <div className="text-textSilver text-base font-medium">
                <span className="mb-2 block">
                  {t("debtorsPage.willGiven")} USD:
                </span>
                <span className="text-textDark block">
                  {data?.salon_must_pay}
                </span>
              </div>
            </li>
          ) : (
            ""
          )}
          <li className="flex items-center gap-x-2">
            <img
              src={GivenMoney}
              alt="Just an icon"
              style={{ fill: "#702DE3" }}
            />
            <div className="text-textSilver text-base font-medium">
              <span className="mb-2 block">{t("debtorsPage.given")} USD:</span>
              <span className="text-textDark block">
                {data?.money_salon_usd}
              </span>
            </div>
          </li>
          <li className="flex items-center gap-x-2">
            <img
              src={GivenMoney}
              alt="Just an icon"
              style={{ fill: "#702DE3" }}
            />
            <div className="text-textSilver text-base font-medium">
              <span className="mb-2 block">{t("debtorsPage.given")} UZS:</span>
              <span className="text-textDark block">
                {data?.money_salon_uzs}
              </span>
            </div>
          </li>
          <li className="flex items-center gap-x-2">
            <img src={Receipt} alt="Just an icon" style={{ fill: "#702DE3" }} />
            <div className="text-textSilver text-base font-medium">
              <span className="mb-2 block">{t("home.debt")} USD:</span>
              <span className="text-textDark block">{data?.salon_debt}</span>
            </div>
          </li>
          <li className="flex items-center gap-x-2">
            <img
              className="brend_svg"
              src={Calender}
              alt="Just an icon"
              style={{ fill: "#702DE3" }}
            />
            <div className="text-textSilver text-base font-medium">
              <span className="mb-2 block">{t("debtorsPage.payDate")}:</span>
              <span className="text-textDark block">
                {getDate(data?.date_gives_debt)}
              </span>
            </div>
          </li>
        </ul>

        <p className="text-textSilver font-medium mb-4">
          {path === "simple-sale" ? (
            <>
              {t("debtorsPage.takenDresses")}:
              <span className="text-textDark">{getDate(data?.created_at)}</span>
            </>
          ) : (
            t("debtorsPage.brideInfo") + ":"
          )}
        </p>

        {path === "sale5050" ? (
          <ul className="border border-[#E6E6EA] p-4 mb-6 flex flex-col gap-y-4 rounded-main">
            <li className="flex items-center gap-x-2">
              <img
                src={Profile1}
                alt="Just an icon"
                style={{ fill: "#702DE3" }}
              />
              <div className="text-textSilver text-base font-medium">
                <span className="mb-2 block">{t("simpleSale.brideName")}:</span>
                <span className="text-textDark block">{data?.bride_name}</span>
              </div>
            </li>
            <li className="flex items-center gap-x-2">
              <img
                src={Wallet}
                alt="Just an icon"
                style={{ fill: "#702DE3" }}
              />
              <div className="text-textSilver text-base font-medium">
                <span className="mb-2 block">
                  {t("debtorsPage.willGiven")} USD:
                </span>
                <span className="text-textDark block">
                  {data?.bride_must_pay}
                </span>
              </div>
            </li>
            <li className="flex items-center gap-x-2">
              <img
                src={GivenMoney}
                alt="Just an icon"
                style={{ fill: "#702DE3" }}
              />
              <div className="text-textSilver text-base font-medium">
                <span className="mb-2 block">
                  {t("debtorsPage.given")} USD:
                </span>
                <span className="text-textDark block">
                  {data?.money_bride_usd}
                </span>
              </div>
            </li>
            <li className="flex items-center gap-x-2">
              <img
                src={GivenMoney}
                alt="Just an icon"
                style={{ fill: "#702DE3" }}
              />
              <div className="text-textSilver text-base font-medium">
                <span className="mb-2 block">
                  {t("debtorsPage.given")} UZS:
                </span>
                <span className="text-textDark block">
                  {data?.money_bride_uzs}
                </span>
              </div>
            </li>
            <li className="flex items-center gap-x-2">
              <img
                src={Receipt}
                alt="Just an icon"
                style={{ fill: "#702DE3" }}
              />
              <div className="text-textSilver text-base font-medium">
                <span className="mb-2 block">{t("home.debt")} USD:</span>
                <span className="text-textDark block">{data?.bride_debt}</span>
              </div>
            </li>
            <li className="flex items-center gap-x-2">
              <img
                className="brend_svg"
                src={Calender}
                alt="Just an icon"
                style={{ fill: "#702DE3" }}
              />
              <div className="text-textSilver text-base font-medium">
                <span className="mb-2 block">{t("debtorsPage.payDate")}:</span>
                <span className="text-textDark block">
                  {getDate(data?.date_gives_debt)}
                </span>
              </div>
            </li>
          </ul>
        ) : (
          ""
        )}

        <ul className="flex flex-col gap-y-4 mb-10">
          {path === "sale5050" ? (
            <li className="p-4 rounded-main border border-[#E6E6EA">
              <h4 className="text-textSilver font-medium border-b border-[#E6E6EA] text-center mb-3 pb-2">
                {t("debtorsPage.aboutDress")}
              </h4>
              <ul className="flex flex-col gap-y-5 text-textSilver">
                <li className="w-full flex justify-between border-b border-[#E6E6EA] pb-2">
                  <span> {t("debtorsPage.name")}:</span>
                  <span className="text-textDark">{data?.dress_name}</span>
                </li>
                <li className="w-full flex justify-between border-b border-[#E6E6EA] pb-2">
                  <span> {t("debtorsPage.color")}:</span>
                  <span className="text-textDark">{data?.dress_color}</span>
                </li>
                <li className="w-full flex justify-between border-b border-[#E6E6EA] pb-2">
                  <span> {t("ordersPage.card.shleft")}:</span>
                  <span className="text-textDark">{data?.shleft_size}</span>
                </li>
                <li className="w-full flex justify-between border-b border-[#E6E6EA] pb-2">
                  <span> {t("debtorsPage.price")}:</span>
                  <span className="text-textDark">{data?.price}</span>
                </li>
              </ul>
            </li>
          ) : (
            data?.dresses?.length &&
            data?.dresses.map((el, i) => (
              <li className="p-4 rounded-main border border-[#E6E6EA" key={i}>
                <h4 className="text-textSilver font-medium border-b border-[#E6E6EA] text-center mb-3 pb-2">
                  {t("debtorsPage.aboutDress")}
                </h4>
                <ul className="flex flex-col gap-y-5 text-textSilver">
                  <li className="w-full flex justify-between border-b border-[#E6E6EA] pb-2">
                    <span>{t("debtorsPage.name")}:</span>
                    <span className="text-textDark">{el?.dress_name}</span>
                  </li>
                  <li className="w-full flex justify-between border-b border-[#E6E6EA] pb-2">
                    <span>{t("debtorsPage.color")}:</span>
                    <span className="text-textDark">{el?.dress_color}</span>
                  </li>
                  <li className="w-full flex justify-between border-b border-[#E6E6EA] pb-2">
                    <span>{t("ordersPage.card.shleft")}:</span>
                    <span className="text-textDark">{el?.shleft_size}</span>
                  </li>
                  <li className="w-full flex justify-between border-b border-[#E6E6EA] pb-2">
                    <span>{t("debtorsPage.price")}:</span>
                    <span className="text-textDark">{el?.price}</span>
                  </li>
                </ul>
              </li>
            ))
          )}
        </ul>

        {!btn ? (
          <MainButton handleClick={handleClick}>
            {t("debtorsPage.pay")}
          </MainButton>
        ) : (
          ""
        )}
      </div>

      <div
        className={`${
          isClicked
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed transition  duration-300 z-10 bottom-0 min-[500px]:left-1/2 min-[500px]:-translate-x-1/2 max-w-[500px] w-full left-0 right-0 flex items-end justify-center`}
        id="modal_wrapper"
        onClick={handleModalClick}
      >
        <form
          className={`${
            isClicked ? "translate-y-0" : "translate-y-full"
          } bg-white w-full py-6 px-4 transition-all ease-in h-3/4 overflow-y-auto`}
          onSubmit={handleSubmit}
        >
          <h5 className="text-center text-2xl text-brendColor font-semibold mb-8">
            {path === "sale5050"
              ? "50/50 " + t("debtorsPage.form.payDebt")
              : `${t("home.debt")}: ${data?.salon_debt}`}
          </h5>
          <CurrencyCalculator
            currency={currency?.usd_rate}
          ></CurrencyCalculator>
          {path !== "sale5050" ? (
            <span className="text-textDark block mb-2 mt-5">
              {t("debtorsPage.form.debtType")}
            </span>
          ) : (
            <>
              <p className="border-b border-[#E6E6EA] pb-1.5 my-5 flex justify-between">
                <span className="text-textSilver font-medium">
                  {t("debtorsPage.form.salonDebt")}
                </span>
                <span className="flex items-center gap-x-2">
                  <span className="text-textDark text-lg font-semibold">
                    {data?.salon_debt}
                  </span>
                  <span className="text-lg text-mainText font-semibold">
                    USD
                  </span>
                </span>
              </p>
              <p className="text-base text-textDark mb-1.5">
                {t("debtorsPage.form.paymentType")}
              </p>
            </>
          )}

          <div className="flex justify-between gap-x-6 items-center mb-5">
            <label className="relative w-1/2">
              <input
                className="pl-3 w-full pr-12 text-base py-4 rounded-md text-textSilver border border-[#D7E1EA] outline-none "
                type="number"
                name="money_salon_usd"
              />
              <span className="absolute right-3  top-1/2 -translate-y-1/2 text-lg text-mainText font-semibold">
                USD
              </span>
            </label>
            <label className="relative w-1/2">
              <input
                className="pl-3 w-full pr-12 text-base py-4 rounded-md text-textSilver border border-[#D7E1EA] outline-none "
                type="number"
                name="money_salon_uzs"
              />
              <span className="absolute right-3  top-1/2 -translate-y-1/2 text-lg text-mainText font-semibold">
                UZS
              </span>
            </label>
          </div>
          {path === "sale5050" ? (
            <>
              <p className="border-b border-[#E6E6EA] pb-1.5 mb-5 flex justify-between">
                <span className="text-textSilver font-medium">
                  {t("debtorsPage.form.brideDebt")}
                </span>
                <span className="flex items-center gap-x-2">
                  <span className="text-textDark text-lg font-semibold">
                    {data?.bride_debt}
                  </span>
                  <span className="text-lg text-mainText font-semibold">
                    USD
                  </span>
                </span>
              </p>
              <p className="text-base text-textDark mb-1.5">
                {t("debtorsPage.form.paymentType")}
              </p>
              <div className="flex justify-between gap-x-6 items-center mb-10">
                <label className="relative w-1/2">
                  <input
                    className="pl-3 w-full pr-12 text-base py-4 rounded-md text-textSilver border border-[#D7E1EA] outline-none "
                    type="number"
                    name="money_bride_usd"
                  />
                  <span className="absolute right-3  top-1/2 -translate-y-1/2 text-lg text-mainText font-semibold">
                    USD
                  </span>
                </label>
                <label className="relative w-1/2">
                  <input
                    className="pl-3 w-full pr-12 text-base py-4 rounded-md text-textSilver border border-[#D7E1EA] outline-none "
                    type="number"
                    name="money_bride_uzs"
                  />
                  <span className="absolute right-3  top-1/2 -translate-y-1/2 text-lg text-mainText font-semibold">
                    UZS
                  </span>
                </label>
              </div>
            </>
          ) : (
            ""
          )}
          {!btn ? (
            <MainButton type={"submit"}>{t("debtorsPage.pay")}</MainButton>
          ) : (
            ""
          )}
        </form>
      </div>
    </>
  );
}

// {
//     "id": "baa5423f-9933-480d-ace9-477e6a09e32c",
//     "salon_image": "https://dev.backend-sonia.uz/service-ads/1684408608028.jpg",
//     "date_gives_debt": "2023-04-22T19:00:00.000Z",
//     "created_at": "2023-04-19T05:16:08.686Z",
//     "salon_name": "LaStoria",
//     "money_salon_usd": "1964",
//     "money_salon_uzs": "12",
//     "salon_debt": -35.99,
//     "dresses": [
//       {
//         "dress_name": "Gujiferro",
//         "dress_color": "Ayvory",
//         "shleft_size": 80,
//         "price": 1000
//       },
//       {
//         "dress_name": "Gujiferro",
//         "dress_color": "Ayvory",
//         "shleft_size": 1,
//         "price": 1000
//       }
//     ]
//   }

// {
//     "salon_name": "LaStoria",
//     "salon_image": "https://dev.backend-sonia.uz/service-ads/1684408608028.jpg",
//     "money_salon_usd": 500,
//     "money_salon_uzs": 0,
//     "salon_debt": 0,
//     "date_gives_debt": "2023-04-21T19:00:00.000Z",
//     "bride_name": "Tursunoy",
//     "money_bride_usd": 470,
//     "money_bride_uzs": 0,
//     "bride_debt": -30,
//     "bride_passport_taken": true,
//     "created_at": "2023-04-19T05:18:15.444Z",
//     "dress_name": "Gujiferro",
//     "dress_color": "Ayvory",
//     "shleft_size": 60,
//     "price": 1000,
//     "salon_must_pay": 500,
//     "bride_must_pay": 500
//   }
