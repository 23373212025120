let debug = true;

let baseUrl;

if (debug) {
  baseUrl = "https://dastur.mme-holding.uz";
} else {
  baseUrl = "https://team.mme-holding.uz";
}

export { baseUrl };
