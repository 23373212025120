import React from "react";
import Header from "../../components/Header/Header";
import MainInput from "../../components/MainInput/MainInput";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import LoginInput from "../../components/MainInput/LoginInput";
import MainButton from "../../components/MainButton/MainButton";
import useToken from "../../hooks/useToken";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNotify } from "../../hooks/useNotify";
import useLoading from "../../hooks/useLoading";

export default function AddSeller() {
  const { id } = useParams();

  const notify = useNotify();

  const [_, setIsLoading] = useLoading();

  const user = useFetch(id ? baseUrl + "/user/" + id : "");

  const [token] = useToken();

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, phone } = e.target.elements;

    let newEmployee = {
      name: name.value,
      phone: "998" + phone.value,
    };

    if (id) {
      if (user?.name !== name.value || user?.phone !== phone.value) {
        let newEmployee = {
          name: name.value,
          phone: phone.value,
        };
        newEmployee.id = id;
        setIsLoading(true);
        return axios
          .put(baseUrl + "/manager-seller", newEmployee, {
            headers: {
              Authorization: "mme:" + token,
            },
          })
          .then((res) => {
            if (res?.data?.data?.id) {
              setIsLoading(false);
              notify("Successfully!", 200);
              navigate("/employeeList");
            }
          })
          .catch((err) => {
            setIsLoading(false);
            notify("Xatolik !", 400);
          });
      } else {
        notify("Hech narsa o'zgarmadi !", 300);
      }
    }

    if (name.value && phone.value && !id) {
      setIsLoading(true);
      axios
        .post(baseUrl + "/manager-seller", newEmployee, {
          method: "POST",
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res?.data?.data?.id) {
            setIsLoading(false);
            notify("Successfully!", 200);
            navigate("/controlEmployee");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          notify("Xatolik !", 400);
        });
    }
  };

  return (
    <>
      <Header heading={"Sotuvchi qo'shish"} icon={true} navPath={-1}></Header>
      <form
        className="bg-white px-4 pt-6 flex flex-col justify-between pb-7 h-full"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-4">
          <MainInput
            title={"Sotuvchi ismi"}
            name={"name"}
            required={true}
            value={user?.name}
          ></MainInput>
          <div className="flex flex-col gap-y-1.5">
            <span>Sotuvchi telefon raqami</span>
            {id ? (
              <MainInput name={"phone"} value={user?.phone}></MainInput>
            ) : (
              <LoginInput
                variant={"mainInput"}
                type={"tel"}
                name={"phone"}
                required={true}
              ></LoginInput>
            )}
          </div>
        </div>
        <MainButton type={"submit"}>Saqlash</MainButton>
      </form>
    </>
  );
}
