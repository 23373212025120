import React, { useState } from "react";
import { Search, CloseIcon } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import ButtonToggle from "../../components/ButtonToggle/ButtonToggle";
import { Receipt, Dress, Date, House } from "../../assets/images";
import { searchData } from "../../utils/SearchData";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import useRole from "../../hooks/useRole";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

export default function OrdersList() {
  const [isSearch, setIsSearch] = useState(false);

  const [isClicked, setIsClicked] = useState(false);

  const [inputVal, setInputVal] = useState("");

  const { t } = useTranslation();

  const [role] = useRole();

  let data = useFetch(`${baseUrl}/dress-order/all?accepted=${!isClicked}`);

  const navigate = useNavigate();

  data = data?.map((el) => {
    el.title = el?.dress_name;
    return el;
  });

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };
  return (
    <div className="flex flex-col h-screen bg-white">
      <div
        className="pb-4 px-4 bg-white pt-10"
        style={{ boxShadow: "0px 12px 14px rgba(209, 212, 226, 0.157261)" }}
      >
        <div className="text-center mb-10 relative">
          <span
            className="h-8 w-8 rounded-full text-lg text-[#354B91]  top-1/2 -translate-y-1/2 left-6 border border-[#354B91] flex justify-center items-center absolute"
            onClick={(e) => {
              navigate(role?.status === 1 ? "/statistics" : "/report");
            }}
          >
            &#10094;
          </span>
          <h1 className="text-xl text-[#702DE3]">
            <span>
              {role?.status !== 1
                ? t("pages.ordersList")
                : t("pages.allOrdersList")}
            </span>
          </h1>
          <img
            className="absolute right-2 top-0"
            src={Search}
            alt="Just an icon"
            onClick={() => setIsSearch(!isSearch)}
          />
        </div>
        {!isSearch ? (
          <ButtonToggle
            setIsClicked={setIsClicked}
            isClicked={isClicked}
            toggle1={t("btns.approved")}
            toggle2={t("btns.notApproved")}
          ></ButtonToggle>
        ) : (
          <label className="w-full relative">
            <input
              className="bg-[#FCFCFC] border border-[#D7E1EA] w-full rounded-md p-3.5 outline-none text-sm text-textSilver"
              onChange={(e) => setInputVal(e.target.value)}
              type="text"
              value={inputVal}
            />
            <img
              className="absolute right-4 top-1/2 -translate-y-1/2"
              src={CloseIcon}
              alt="Just an icon"
              onClick={() => setInputVal("")}
            />
          </label>
        )}
      </div>

      <ul className="flex flex-col px-4 pt-6 gap-y-4 flex-grow pb-10 bg-white">
        {data?.length &&
          searchData(inputVal, data).map((el) => (
            <li
              className="p-4 border border-[#E6E6EA] rounded-main flex gap-x-7 justify-between items-center"
              key={el.id}
              onClick={() => navigate(`/ordersList/${el?.id}`)}
            >
              <ul className="flex flex-col gap-y-2">
                <li className="flex gap-x-2 items-center">
                  <img
                    src={House}
                    alt="Just an icon"
                    width={22}
                    className="object-contain shrink-0"
                  />
                  <div className="flex flex-col">
                    <span className="text-sm text-textSilver mb-1">
                      {t("ordersPage.card.salonName")}
                    </span>
                    <span className="text-textDark font-medium text-xs">
                      {el?.salon_name}
                    </span>
                  </div>
                </li>
                <li className="flex gap-x-2 items-center">
                  <img
                    src={Dress}
                    alt="Just an icon"
                    width={22}
                    className="object-contain shrink-0"
                  />
                  <div className="flex flex-col">
                    <span className="text-sm text-textSilver mb-1">
                      {t("ordersPage.card.dressName")}
                    </span>
                    <span className="text-textDark font-medium text-xs">
                      {el?.dress_name}
                    </span>
                  </div>
                </li>
                <li className="flex gap-x-2 items-center">
                  <img
                    src={Date}
                    alt="Just an icon"
                    width={22}
                    height={18}
                    className="object-contain shrink-0 h-6"
                  />
                  <div className="flex flex-col">
                    <span className="text-sm text-textSilver mb-1">
                      {t("ordersPage.card.date")}
                    </span>
                    <span className="text-textDark font-medium text-xs">
                      <Moment format="YYYY/MM/DD">{el?.delivery_date}</Moment>
                    </span>
                  </div>
                </li>
                <li className="flex gap-x-2 items-center">
                  <img
                    src={Receipt}
                    alt="Just an icon"
                    width={22}
                    className="object-contain shrink-0"
                  />
                  <div className="flex flex-col">
                    <span className="text-sm text-textSilver mb-1">
                      {t("ordersPage.card.order")} №:
                    </span>
                    <span className="text-textDark font-medium text-xs">
                      {el?.order_number}
                    </span>
                  </div>
                </li>
              </ul>
              <img
                className="rounded-main"
                src={baseUrl + el.dress_image}
                alt="product pic"
                width={149}
                height={192}
                onError={handleImageError}
              />
            </li>
          ))}
      </ul>
    </div>
  );
}
