import React from "react";
import Header from "../../components/Header/Header";
import MainInput from "../../components/MainInput/MainInput";
import MainButton from "../../components/MainButton/MainButton";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import useToken from "../../hooks/useToken";
import useUserId from "../../hooks/useUserId";
import useFetch from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { useNotify } from "../../hooks/useNotify";
import { useTranslation } from "react-i18next";
import useLoading from "../../hooks/useLoading";

export default function MessageToDevs() {
  const [userId] = useUserId();

  const [token] = useToken();

  const [_, setIsLoading] = useLoading();

  const notify = useNotify();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const user = useFetch(baseUrl + "/user/" + userId);

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, message } = e.target.elements;

    let newMessage = {
      name: name.value,
      message: message.value,
    };

    if (name.value && message.value) {
      try {
        setIsLoading(true);
        axios
          .post(baseUrl + "/other/message", newMessage, {
            method: "POST",
            headers: {
              Authorization: `mme:${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (res.data?.data?.id) {
              setIsLoading(false);
              notify("Successfully!", 200);
              navigate("/profile");
            }
          });
      } catch (error) {
        setIsLoading(false);
        notify("Xatolik !", 400);
      }
    }
  };
  return (
    <div className="h-screen flex flex-col">
      <Header
        icon={true}
        navPath="/profile"
        heading={t("profile.message.heading")}
      ></Header>

      <form
        className="px-4 bg-white flex-grow flex flex-col justify-between pt-5 pb-8"
        onSubmit={handleSubmit}
      >
        <div>
          <MainInput
            title={t("profile.message.name")}
            name={"name"}
            margin={"20px"}
            value={user?.name}
            readOnly={true}
          ></MainInput>
          <label className="block">
            <span className="text-textDark block mb-1.5">
              {t("profile.message.textarea")}
            </span>
            <textarea
              className="min-h-[100px] max-h-[200px] border outline-none border-[#AFBACA] rounded-md w-full px-3.5 py-2.5"
              name="message"
            ></textarea>
          </label>
        </div>
        <MainButton type={"submit"}>{t("profile.message.btn")}</MainButton>
      </form>
    </div>
  );
}
