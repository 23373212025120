import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import { getDate } from "../../utils/getDate";
import AddProducts from "../../components/ProductsBottomSheet/AddProducts";
import UseProduct from "../../components/ProductsBottomSheet/UseProduct";
import EditProduct from "../../components/ProductsBottomSheet/EditProduct";
import useRole from "../../hooks/useRole";
import { searchData } from "../../utils/SearchData";
import {
  Shop,
  MoneyIcon,
  PlusIcon,
  EditPen,
  Search,
} from "../../assets/images";
import { useTranslation } from "react-i18next";

export default function WareHouseProducts() {
  const [isEditClicked, setIsEditClicked] = useState(false);

  const [isClicked, setIsClicked] = useState(null);

  const [product, setProduct] = useState({});

  const [role] = useRole();

  const { t } = useTranslation();

  const [inputVal, setInputVal] = useState("");

  const [products, refetch] = useFetch(
    baseUrl + `/product/all?limit=15&offset=0`,
    true
  );

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("User has reached the bottom of the page.");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleEditClick = (e) => {
    if (role?.status === 1) return;
    setIsEditClicked(!isEditClicked);
  };

  const handleClick = (e, el) => {
    let id = e.target.id;

    if (id === "editBtn" || id === "editImg" || id === "editSpan") {
      setIsClicked("edit");
      setProduct(el);
    } else if (id === "addBtn" || id === "addImg" || id === "addSpan") {
      setIsClicked("add");
      setProduct(el);
    } else {
      setProduct(el);
      setIsClicked("use");
    }
  };

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  return (
    <>
      <Header
        heading={t("pages.warehouseProducts")}
        icon={true}
        navPath="/report"
        edit={true}
        handleEditClick={handleEditClick}
        isEditClicked={isEditClicked}
      ></Header>

      <div className="pb-4 bg-white">
        <label className="relative w-full block px-4 bg-white">
          <input
            className="border w-full border-[#E6E6EA] rounded-md outline-none focus:border-[#3cc] placeholder:text-sm placeholder:text-textSilver text-sm text-textDark py-2.5 pl-12 pr-2.5"
            type="text"
            placeholder={t("search")}
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
          />
          <img
            className="absolute left-7 top-1/2 -translate-y-1/2"
            src={Search}
            alt="Just an icon"
          />
        </label>
      </div>
      <ul className="flex flex-col gap-y-3 px-4 mt-4 w-full">
        {products?.products?.length &&
          searchData(
            inputVal,
            products?.products.map((el) => {
              el.title = el?.product_name;
              return el;
            })
          ).map((el) => (
            <li
              className="bg-white p-3 rounded-md "
              key={el.id}
              onClick={(e) => handleClick(e, el)}
            >
              <div className="flex items-center ">
                <img
                  className="w-[85px] h-[95px] mr-3 rounded"
                  src={baseUrl + el?.product_image}
                  alt="product pic"
                  width={85}
                  height={95}
                  onError={handleImageError}
                />

                <div className="w-full overflow-y-hidden">
                  <h4 className="font-medium text-base text-textDark mb-2 capitalize">
                    {el?.product_name}
                  </h4>
                  <p className="text-textSilver mb-4 text-base">
                    {t("warehouseProducts.date")}: {getDate(el?.created_at)}
                  </p>
                  <div className="flex items-center gap-x-7">
                    <span className="flex">
                      <img
                        className="mr-1.5 object-contain"
                        src={MoneyIcon}
                        alt="money icon"
                        width={20}
                        height={20}
                      />
                      <span className="text-base text-textDark mr-1">
                        {el?.product_price}
                      </span>
                      <span className="text-[#354B91] text-base">USD</span>
                    </span>
                    <span className="flex">
                      <img
                        className="mr-1.5 object-contain"
                        src={Shop}
                        alt="money icon"
                        width={20}
                        height={20}
                      />
                      <span className="text-base text-textDark mr-1">
                        {el?.product_count}
                      </span>
                      <span className="text-textDark text-base lowercase">
                        {el?.unit_of_measure}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  isEditClicked ? "flex" : "hidden"
                }  items-center justify-between  w-full gap-x-2.5  mt-6`}
              >
                <button
                  className="flex items-center bg-[#702DE3] p-4  rounded-md w-1/2 justify-center"
                  id="addBtn"
                  onClick={() => {
                    setIsClicked("add");
                    setProduct(el);
                  }}
                >
                  <img src={PlusIcon} alt=" Just an icon" id="addImg" />
                  <span
                    className="text-base font-medium ml-3 text-white"
                    id="addSpan"
                  >
                    {t("warehouseProducts.add")}
                  </span>
                </button>
                <button
                  className="flex items-center bg-textSilver p-4  rounded-md w-1/2 justify-center"
                  id="editBtn"
                  onClick={() => {
                    setIsClicked("edit");
                    setProduct(el);
                  }}
                >
                  <img src={EditPen} alt=" Just an icon" id="editImg" />
                  <span
                    className="text-base font-medium ml-3 text-white"
                    id="editSpan"
                  >
                    {t("warehouseProducts.edit")}
                  </span>
                </button>
              </div>
            </li>
          ))}
      </ul>

      {role?.status !== 1 ? (
        <>
          <AddProducts
            product_count={product?.product_count}
            product_name={product?.product_name}
            product_price={product?.product_price}
            product_id={product?.id}
            measure={product?.unit_of_measure}
            created_at={getDate(product?.created_at, true)}
            isClicked={isClicked}
            setIsClicked={setIsClicked}
            refetch={refetch}
          ></AddProducts>
          {!isEditClicked ? (
            <UseProduct
              product_name={product?.product_name}
              product_count={product?.product_count}
              product_measure={product?.unit_of_measure}
              product_image={product.product_image}
              isClicked={isClicked}
              setIsClicked={setIsClicked}
              product_id={product?.id}
              refetch={refetch}
            ></UseProduct>
          ) : (
            ""
          )}
          <EditProduct
            productName={product?.product_name}
            product_image={product?.product_image}
            product_type={product?.warehouse_product_category_id}
            productCode={product?.product_code}
            productWarning={product?.product_count_warning_limit}
            isClicked={isClicked}
            setIsClicked={setIsClicked}
            product_id={product?.id}
            refetch={refetch}
          ></EditProduct>
        </>
      ) : (
        ""
      )}
    </>
  );
}
