import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { baseUrl } from "../../baseUrl";
import useFetch from "../../hooks/useFetch";
import MainButton from "../../components/MainButton/MainButton";
import MainInput from "../../components/MainInput/MainInput";
import useToken from "../../hooks/useToken";
import { useNotify } from "../../hooks/useNotify";
import { useNavigate } from "react-router-dom";
import ButtonSheetSimple from "../../components/BottomSheet/BottomSheetSimple";
import {
  Dollar,
  Dye,
  House,
  Korzinka,
  Eraser,
  Calender,
  Products,
  Percent,
  PlusIcon,
  DeleteIcon,
  EditPen1,
  Warning,
} from "../../assets/images";
import axios from "axios";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useRole from "../../hooks/useRole";
import useLoading from "../../hooks/useLoading";

export default function OneGipyurPage() {
  const { id } = useParams();

  const { t } = useTranslation();

  const [_, setIsLoading] = useLoading();

  const [token] = useToken();

  const [role] = useRole();

  const notify = useNotify();

  const [showAlert] = useAlert();

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isClicked, setIsClicked] = useState(false);

  const [imgUrl, setImgUrl] = useState();

  const [image, setImage] = useState("");

  const [gipyur, refetch] = useFetch(baseUrl + "/gipyur/" + id, true);

  const gipyurColors = useFetch(baseUrl + "/gipyur/colors");

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  const validData = [
    {
      title: t("gipyurInfo.gipyur.getPrice"),
      type: "gipyur_price",
      icon: Dollar,
      role: 4,
    },
    {
      title: t("gipyurInfo.gipyur.sellPrice"),
      type: "gipyur_sell_price",
      icon: Dollar,
      role: 5,
    },
    {
      title: t("gipyurInfo.form.percent"),
      type: "gipyur_percent",
      icon: Percent,
      role: 5,
    },
    {
      title: t("gipyurInfo.form.color"),
      type: "gipyur_color",
      icon: Dye,
      role: 4,
    },
    {
      title: t("gipyurInfo.gipyur.howMuchStore"),
      type: "gipyur_metr",
      icon: Products,
      role: 4,
    },
    {
      title: t("gipyurInfo.gipyur.howMuchShop"),
      type: "in_shop",
      icon: House,
      role: 4,
    },
    {
      title: t("gipyurInfo.gipyur.howMuchSold"),
      type: "all_sold",
      icon: Korzinka,
      role: 4,
    },
    {
      title: t("gipyurInfo.gipyur.income"),
      type: "profit_made",
      icon: Dollar,
      role: 5,
    },
    {
      title: t("gipyurInfo.gipyur.lastDate"),
      type: "last_info",
      icon: Calender,
      inner: "last_bought_date",
      role: 5,
    },
    {
      title: t("gipyurInfo.gipyur.lastMetr"),
      type: "last_info",
      icon: Eraser,
      inner: "last_bought_metr",
      role: 4,
    },
    {
      title: t("gipyurInfo.gipyur.lastPrice"),
      type: "last_info",
      icon: Dollar,
      inner: "last_bought_price",
      role: 4,
    },
  ];

  const handleDeleteClick = () => {
    axios
      .delete(baseUrl + "/gipyur/by/" + gipyur?.id, {
        method: "POST",
        headers: {
          Authorization: "mme:" + token,
        },
      })
      .then((res) => {
        if (res?.data?.data?.id) {
          showAlert("Muvafaqqiyatli!", "Gipyu o'chirildi...", "success");
          navigate(-1);
        }
      })
      .catch((err) => showAlert("Xatolik!", "Xatolik yuz berdi !", "error"));
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();

    const { gipyur_price, gipyur_metr, gipyur_sell_price } = e.target.elements;

    const elements = e.target.elements;

    let updatedGipyur = {
      id: gipyur?.id,
      gipyur_metr: +gipyur_metr.value,
      gipyur_sell_price: +gipyur_sell_price.value,
      gipyur_price: +gipyur_price.value,
    };

    if (
      Object.values(updatedGipyur).length === 4 &&
      gipyur_price.value > 0 &&
      gipyur_metr.value > 0 &&
      gipyur_sell_price.value > 0
    ) {
      setIsLoading(true);
      axios
        .put(baseUrl + "/gipyur/add", updatedGipyur, {
          method: "POST",
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res?.data?.data?.id) {
            setIsLoading(false);
            refetch();
            showAlert(
              "Muvafaqqiyatli!",
              "Jarayon muvafaqqiyatli tugallandi.",
              "success"
            );
            setIsClicked("");
            gipyur_metr.value = null;
            gipyur_sell_price.value = null;
            gipyur_price.value = null;
          }
        })
        .catch((err) => {
          setIsLoading(false);
          showAlert("Xatolik!", "Xatolik yuz berdi !", "error");
        });
    } else {
      notify("Forma to'liq to'ldirilmadi !", 300);
      for (let el of elements) {
        if (!el.value) {
          el.style.borderColor = "red";
        }
      }
    }
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();

    const elements = e.target.elements;

    let editedtGipyur = {
      id: gipyur?.id,
      gipyur_image: image || gipyur?.gipyur_image,
    };

    for (let el of elements) {
      if (el.name && el.value) {
        editedtGipyur[el.name] = el.value;
      }
      if (!isNaN(el?.value) && el.value && el.name) {
        editedtGipyur[el.name] = Number(el.value);
      }
    }

    editedtGipyur.warning_limit = String(editedtGipyur?.warning_limit);
    editedtGipyur.gipyur_code = editedtGipyur?.gipyur_code + "";

    if (
      editedtGipyur.gipyur_image ||
      (editedtGipyur?.gipyur_code !== gipyur?.gipyur_code &&
        editedtGipyur?.gipyur_color !== gipyur?.gipyur_color &&
        editedtGipyur?.warning_limit !== gipyur?.warning_limit &&
        editedtGipyur?.gipyur_percent !== gipyur?.gipyur_percent)
    ) {
      setIsLoading(true);
      axios
        .put(baseUrl + "/gipyur/update", editedtGipyur, {
          method: "PUT",
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res?.data?.data?.id) {
            setIsLoading(false);
            showAlert(
              "Muvafaqqiyatli!",
              "Jarayon muvafaqqiyatli yakunlandi.",
              "Success"
            );
            setIsClicked("");
            refetch();
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      if (!editedtGipyur?.gipyur_image) {
        showAlert("Ogohlantirish", "Rasm kiritilishi shart!", "warning");
      } else {
        showAlert("Ogohlantirish", "Hech narsa o'zgarmadi !", "warning");
      }
    }
  };

  const handleClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked("");
    }
  };

  const handleAddClick = () => {
    setIsClicked("add");
  };

  const handleEditClick = () => {
    setIsClicked("edit");
  };

  const handleChange = (e) => {
    try {
      setImgUrl(URL.createObjectURL(e.target.files[0] || {}));

      let formData = new FormData();

      formData.append("file", e.target.files[0]);

      axios
        .post(baseUrl + "/file-upload", formData, {
          method: "POST",
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res?.data?.image) {
            setImage(res?.data?.image);
          }
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col bg-[#F8F5F5] h-screen gap-y-7">
      <div className="relative">
        <Header heading={gipyur?.gipyur_code} icon={true} navPath={-1}></Header>
        {role?.status === 4 ? (
          <img
            className="absolute top-1/2 -translate-y-1/2 right-9 w-6 h-6 brend_svg object-contain"
            src={DeleteIcon}
            alt="Just an icon"
            onClick={() => setIsModalOpen(!isModalOpen)}
          />
        ) : (
          ""
        )}
      </div>
      <div className="flex-grow px-4 bg-[#F8F5F5] pb-4">
        <div className="bg-white rounded-t-main  rounded-b-main">
          <LazyLoadImage
            className="w-full max-h-[235px] rounded-t-main"
            src={baseUrl + gipyur?.gipyur_image}
            alt="Gipyur info card pic"
            onError={handleImageError}
          ></LazyLoadImage>
          <ul className="p-4 flex flex-col gap-y-8">
            {validData.map((el, idx) =>
              el.role === 5 && role?.status === 5
                ? null
                : gipyur[el?.type] && (
                    <li className="flex items-center gap-x-3" key={idx}>
                      <span
                        className="w-11 h-11 flex justify-center items-center rounded-md"
                        style={{ backgroundColor: "rgba(112, 45, 227, 0.08)" }}
                      >
                        <img
                          className="brend_svg"
                          src={el?.icon}
                          alt="Just an icon"
                        />
                      </span>
                      <div className="text-black flex flex-col gap-y-1">
                        <span className="font-normal">{el?.title}:</span>
                        <span className="font-medium">
                          {el.inner === "last_bought_date" ? (
                            <Moment format="YYYY/MM/DD">
                              {gipyur?.[el?.type]?.[el?.inner]}
                            </Moment>
                          ) : el?.inner ? (
                            gipyur?.[el?.type]?.[el?.inner]
                          ) : (
                            gipyur[el?.type]
                          )}
                        </span>
                      </div>
                    </li>
                  )
            )}
          </ul>
        </div>
      </div>
      {role?.status !== 5 ? (
        <div className="flex gap-x-4 items-center px-4 pb-5">
          <MainButton icon={PlusIcon} handleClick={handleAddClick}>
            {t("warehouseProducts.add")}
          </MainButton>
          <MainButton
            icon={EditPen1}
            bgColor="#72809D"
            handleClick={handleEditClick}
          >
            {t("warehouseProducts.edit")}
          </MainButton>
        </div>
      ) : (
        ""
      )}

      {isModalOpen ? (
        <div className="fixed top-0 right-0 left-0 bottom-0 bg-black px-4 bg-opacity-50 flex justify-center items-center max-w-[500px] mx-auto">
          <div className="bg-white w-full px-4 py-6 rounded-main">
            <img
              className="block mx-auto mb-6"
              src={Warning}
              alt="warning icon"
            />
            <p className="text-center mb-6 text-base text-textDark max-w-[220px] mx-auto">
              {t("gipyurInfo.deleteInfo")}
            </p>
            <div className="flex w-full gap-x-4">
              <MainButton
                bgColor={"#72809D"}
                handleClick={() => setIsModalOpen(false)}
              >
                {t("profile.control.workersList.cancel")}
              </MainButton>
              <MainButton bgColor={"#702DE3"} handleClick={handleDeleteClick}>
                {t("warehouseProducts.confirm")}
              </MainButton>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div
        className={`${
          isClicked === "add"
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed bottom-0 min-[500px]:left-1/2 left-0 min-[500px]:-translate-x-1/2 w-full right-0 bg-black bg-opacity-30 overscroll-none flex items-end z-50 max-h-screen max-w-[500px] transition delay-150 duration-300`}
        id="modal_wrapper"
        onClick={handleClick}
      >
        <form
          className={`${
            isClicked === "add"
              ? "translate-y-0 opacity-100"
              : "translate-y-full opacity-0"
          } px-4 pt-6 pb-7 bg-white w-full h-3/4 transition-all delay-75 duration-300 flex flex-col justify-between`}
          onSubmit={handleAddSubmit}
        >
          <div className="flex flex-col gap-y-4 flex-grow">
            <h2 className="text-textDark font-medium text-center text-lg">
              {t("gipyurInfo.addGipyur")}
            </h2>
            <MainInput
              title={t("gipyurInfo.form.price")}
              currency={"usd"}
              name={"gipyur_price"}
              type="number"
              // required={true}
            ></MainInput>
            <div className="flex gap-x-4 justify-between items-center">
              <MainInput
                currency={"m"}
                name={"gipyur_metr"}
                title={t("gipyurInfo.form.metrCome")}
                type="number"
                // required={true}
              ></MainInput>
              <MainInput
                name={"gipyur_sell_price"}
                title={t("gipyurInfo.form.percent")}
                type="number"
                // required={true}
              ></MainInput>
            </div>
          </div>
          <MainButton type={"submit"} bgColor={"#702DE3"} icon={PlusIcon}>
            {t("gipyurInfo.addToStore")}
          </MainButton>
        </form>
      </div>
      <div
        className={`${
          isClicked === "edit"
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed bottom-0 min-[500px]:left-1/2 left-0 min-[500px]:-translate-x-1/2 w-full right-0 bg-black bg-opacity-30 overscroll-none flex items-end z-50 max-h-screen max-w-[500px] transition delay-150 duration-300`}
        id="modal_wrapper"
        onClick={handleClick}
      >
        <form
          className={`${
            isClicked === "edit"
              ? "translate-y-0 opacity-100"
              : "translate-y-full opacity-0"
          } px-4 pt-6 pb-7 bg-white w-full h-5/6 transition-all delay-75 duration-300 flex flex-col justify-between`}
          onSubmit={handleEditSubmit}
        >
          <div className="flex flex-col gap-y-4 flex-grow">
            <h2 className="text-textDark font-medium text-center text-lg">
              {t("gipyurInfo.editGipyur")}
            </h2>
            <div className="px-4 relative">
              {imgUrl ? (
                <span
                  className="w-8 h-8 bg-white rounded-full top-1 right-5 absolute flex justify-center items-center"
                  onClick={() => {
                    setImgUrl("");
                    setImage("");
                  }}
                >
                  <span>&#10006;</span>
                </span>
              ) : (
                ""
              )}
              <label>
                <img
                  className="w-full h-36 rounded"
                  src={imgUrl || baseUrl + gipyur?.gipyur_image}
                  alt="gipyur pic"
                  onError={handleImageError}
                />
                <input
                  className="hidden"
                  type="file"
                  accept="image/*"
                  name="gipyur_color"
                  onChange={handleChange}
                />
              </label>
            </div>
            <ButtonSheetSimple
              title={t("gipyurInfo.form.color")}
              select={true}
              name1={"gipyur_color"}
              button={true}
              btnTitle={"Yangi rang qo'shish"}
              btnPath={"/gipyurColor"}
              defval={false}
              value={{ title: gipyur?.gipyur_color }}
              data={gipyurColors.map((el) => {
                el.title = el?.gipyur_color;
                return el;
              })}
            ></ButtonSheetSimple>
            <MainInput
              name={"gipyur_code"}
              title={t("gipyurInfo.form.code")}
              value={gipyur?.gipyur_code}
              required={true}
            ></MainInput>
            <div className="flex gap-x-4 justify-between items-center">
              <MainInput
                name={"gipyur_sell_price"}
                title={t("gipyurInfo.form.percent")}
                value={gipyur?.gipyur_sell_price}
                type="number"
                required={true}
              ></MainInput>
              <MainInput
                currency={"m"}
                name={"warning_limit"}
                title={t("gipyurInfo.form.metrCome")}
                type="number"
                value={gipyur?.warning_limit}
                required={true}
              ></MainInput>
            </div>
          </div>
          <MainButton type={"submit"} bgColor={"#702DE3"} icon={PlusIcon}>
            {t("submit")}
          </MainButton>
        </form>
      </div>
    </div>
  );
}
