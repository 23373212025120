import React from "react";
import ReportsList from "../../components/ReportsList/ReportsList";
import { Dollar } from "../../assets/images";
import {
  Expense,
  Products,
  Report,
  Rejection,
  Plus,
  Users,
  OrdersList,
  AllOrders,
  Korzinka,
  House,
  Dress,
  MessageText,
  Exchange,
} from "../../assets/images";
import useRole from "../../hooks/useRole";
import { useTranslation } from "react-i18next";

export default function Reports() {
  const [role] = useRole();

  const { t } = useTranslation();

  const data = [
    { id: 1, title: t("pages.viewReport"), icon: Report, path: "/viewReport" },
    {
      id: 2,
      title: t("pages.addNewProduct"),
      icon: Plus,
      path: "/addNewProduct",
    },
    {
      id: 3,
      title: t("pages.warehouseProducts"),
      icon: Products,
      path: "/warehouseProducts",
    },
    { id: 4, title: t("pages.addExpenses"), icon: Expense, path: "/expenses" },
    {
      id: 5,
      title: t("pages.rejectedOrders"),
      icon: Rejection,
      path: "/rejectedOrders",
    },
    {
      id: 6,
      title: t("pages.partnersList"),
      icon: Users,
      path: "/partnersList",
    },
    {
      id: 7,
      title: t("pages.closePartnersDebt"),
      icon: Users,
      path: "/closePartnerDebt",
    },
  ];

  const data1 = [
    { id: 1, title: t("pages.viewReport"), icon: Report, path: "/viewReport" },

    {
      id: 2,
      title: t("pages.debtorsList"),
      icon: Dollar,
      path: "/debtors",
    },
    {
      id: 3,
      title: t("pages.ordersList"),
      icon: OrdersList,
      path: "/ordersList",
    },
    {
      id: 4,
      title: t("pages.allSoldList"),
      icon: Korzinka,
      path: "/allSold",
    },
    {
      id: 5,
      title: t("pages.rejectedOrders"),
      icon: Rejection,
      path: "/rejectedOrders",
    },
    {
      id: 6,
      title: t("pages.addNewDress"),
      icon: Dress,
      path: "/addDress",
    },
    {
      id: 7,
      title: t("pages.addNewSalon"),
      icon: House,
      path: "/addNewSalon",
    },
    { id: 8, title: t("pages.addExpenses"), icon: Expense, path: "/expenses" },
    {
      id: 9,
      title: t("pages.closePartnersDebt"),
      icon: Users,
      path: "/closePartnerDebt",
    },
  ];

  const data2 = [
    {
      id: 1,
      title: t("pages.saleInfo"),
      icon: OrdersList,
      path: "/salonStatistics",
    },
    {
      id: 2,
      title: t("pages.allSoldList"),
      icon: Korzinka,
      path: "/allSold",
    },
    {
      id: 3,
      title: t("pages.ordersInfo"),
      icon: OrdersList,
      path: "/orderStatistics",
    },
    {
      id: 4,
      title: t("pages.allOrdersList"),
      icon: AllOrders,
      path: "/ordersList",
    },
    {
      id: 5,
      title: t("pages.warehouseProducts"),
      icon: Products,
      path: "/warehouseProducts",
    },
    {
      id: 6,
      title: t("pages.partnersList"),
      icon: Users,
      path: "/partnersList",
    },
    {
      id: 7,
      title: t("pages.closePartnersDebt"),
      icon: Users,
      path: "/closePartnerDebt",
    },
  ];

  const data3 = [
    {
      id: 1,
      title: t("pages.gipyurInfos"),
      icon: MessageText,
      path: "/gipyurInfo",
    },
    {
      id: 2,
      title: t("pages.gipyurSelling"),
      icon: Report,
      path: "/sellGipyur",
    },
    {
      id: 3,
      title: t("pages.distirbuteGipyur"),
      icon: Exchange,
      path: "/distirbuteGipyur",
    },
    {
      id: 4,
      title: t("pages.addExpenses"),
      icon: Expense,
      path: "/addExpenses",
    },
    {
      id: 5,
      title: t("pages.closePartnersDebt"),
      icon: Rejection,
      path: "/closePartnerDebt",
    },
    {
      id: 6,
      title: t("pages.lastoriaDebtors"),
      icon: House,
      path: "/debtors",
    },
  ];

  const data4 = [
    {
      id: 1,
      title: t("pages.gipyurInfos"),
      icon: MessageText,
      path: "/gipyurInfo",
    },
    {
      id: 2,
      title: t("pages.gipyurSelling"),
      icon: Report,
      path: "/sellGipyur",
    },
  ];

  const reports = (status) => {
    if (status === 2) {
      return data;
    }

    if (status === 3) {
      return data1;
    }

    if (status === 1) {
      return data2;
    }

    if (status === 4) {
      return data3;
    }

    if (status === 5) {
      return data4;
    }
  };
  return (
    <>
      <div className="px-4 pb-24">
        <ReportsList data={reports(role?.status)}></ReportsList>
      </div>
    </>
  );
}
