import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../baseUrl";
import useFetch from "../../hooks/useFetch";
import ButtonToggle from "../../components/ButtonToggle/ButtonToggle";
import useAlert from "../../hooks/useAlert";
import useToken from "../../hooks/useToken";
import axios from "axios";
import MainButton from "../../components/MainButton/MainButton";
import {
  Calender,
  CallSvg,
  Report,
  Dollar,
  Clock,
  Users,
  Filter,
} from "../../assets/images";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { formatUzbekPhoneNumber } from "../../utils/formatNumber";
import { useTranslation } from "react-i18next";
import { formattedDate } from "../../utils/getDatePickerDate";
import DatePickerInput from "../../components/DatePicker/DatePicker";
import { getDatePickerDate } from "../../utils/getDatePickerDate";
import CurrencyCalculator from "../../components/CurrencyCalculator/CurrencyCalculator";
import useLoading from "../../hooks/useLoading";

export default function PartnerPage() {
  const { id } = useParams();

  const navigate = useNavigate();

  const [debtor, refetch] = useFetch(
    baseUrl + "/gipyur-client/debt/" + id,
    true
  );

  const [_, setIsLoading] = useLoading();

  const currency = useFetch(baseUrl + "/other/central-bank-usd-rate");

  const [offset, setOffset] = useState(0);

  const [start, setStart] = useState(formattedDate);

  const [end, setEnd] = useState(formattedDate);

  const { t } = useTranslation();

  const limit = 50;

  const histories = useFetch(
    baseUrl +
      `/partner/reports?partner_id=${id}&from=${getDatePickerDate(
        start
      )}&to=${getDatePickerDate(end)}&limit=${limit}&offset=${offset}`
  );

  const [isClicked, setIsClicked] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isFilterClicked, setIsFilterClicked] = useState(false);

  const [showAlert] = useAlert();

  const [token] = useToken();

  const handleSubmit = (e) => {
    e.preventDefault();

    const { money_usd, money_uzs } = e.target.elements;

    let clientDebt = +debtor?.gipyur_client_debt_usd;

    let newData = {
      id: id,
      money_usd: +money_usd.value || 0,
      money_uzs: +money_uzs.value || 0,
      central_bank_usd_course: currency?.usd_rate,
    };

    if (clientDebt === 0) {
      return showAlert(
        "Ogohlantirish!",
        "Ushbu hamkorni qarzdorligi mavjud emas !",
        "warning"
      );
    } else if (
      -1 * clientDebt <
      newData.money_uzs + newData.money_uzs / currency?.usd_rate
    ) {
      console.log(
        newData.money_usd + newData.money_uzs / currency?.usd_rate,
        clientDebt
      );
      return showAlert(
        "Ogohlantirish!",
        "Qarzdorlikdan ortiqcha summa kirityapsiz !",
        "warning"
      );
    }

    if (money_usd.value || money_uzs.value) {
      setIsLoading(true);
      axios
        .put(baseUrl + "/gipyur-client/gives-money", newData, {
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res?.data?.data?.id) {
            setIsLoading(false);
            refetch();
            setIsModalOpen(false);
            showAlert(
              "Muvafaqqiyatli !",
              "Jarayon muvafaqqiyatli yakunlandi",
              "success"
            );
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const handleClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsFilterClicked(false);
    } else {
      setIsFilterClicked(true);
    }
  };

  const handleModalClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div className="bg-white flex flex-col gap-y-5 h-screen">
      <div
        className="pb-4 px-4 bg-white pt-10 relative"
        style={{ boxShadow: "0px 12px 14px rgba(209, 212, 226, 0.157261)" }}
      >
        <div className="text-center mb-10 relative">
          <span
            className="h-8 w-8 cursor-pointer rounded-full text-lg text-[#354B91]  top-1/2 -translate-y-1/2 left-1 border border-[#354B91] flex justify-center items-center absolute"
            onClick={() => navigate(-1)}
          >
            &#10094;
          </span>
          <h1 className="text-xl text-brendColor">
            <span>{debtor?.gipyur_client_name}</span>
          </h1>

          {isClicked ? (
            <img
              className="absolute right-2 top-1.5 cursor-pointer"
              src={Filter}
              alt="Just an icon"
              onClick={() => setIsFilterClicked(true)}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="px-4 grow bg-white flex flex-col justify-between pb-4">
        <div>
          <ButtonToggle
            toggle1={"Hamkor haqida"}
            toggle2={"To'lov tarixi"}
            isClicked={isClicked}
            setIsClicked={setIsClicked}
          ></ButtonToggle>
          {!isClicked ? (
            <>
              <ul className="border border-[#E6E6EA] p-4 flex flex-col gap-y-6 rounded-main mt-6">
                <li className="flex items-center gap-x-2">
                  <img
                    className="brend_svg"
                    src={Calender}
                    alt="Just an icon"
                  />
                  <div className="flex flex-col gap-y-1">
                    <span className="text-textSilver">Kiritilgan sana:</span>
                    <Moment className="font-semibold" format="YYYY/MM/DD">
                      {debtor?.created_at}
                    </Moment>
                  </div>
                </li>
                <li className="flex items-center gap-x-2">
                  <img className="brend_svg" src={CallSvg} alt="Just an icon" />
                  <div className="flex flex-col gap-y-1">
                    <span className="text-textSilver">Telefon raqami:</span>
                    <span className="font-semibold">
                      {formatUzbekPhoneNumber(debtor?.gipyur_client_phone)}
                    </span>
                  </div>
                </li>
                <li className="flex items-center gap-x-2">
                  <img className="brend_svg" src={Dollar} alt="Just an icon" />
                  <div className="flex flex-col gap-y-1">
                    <span className="text-textSilver">Qarz:</span>
                    <span className="font-semibold">
                      {debtor?.gipyur_client_debt_usd}
                    </span>
                  </div>
                </li>

                {debtor?.last_info ? (
                  <>
                    <li className="flex items-center gap-x-2">
                      <img
                        className="brend_svg"
                        src={Clock}
                        alt="Just an icon"
                      />
                      <div className="flex flex-col gap-y-1">
                        <span className="text-textSilver">So’ngi to’lov:</span>
                        <Moment className="font-semibold" format="YYYY/MM/DD">
                          {debtor?.last_info?.created_at}
                        </Moment>
                      </div>
                    </li>
                    <li className="flex items-center gap-x-2">
                      <img
                        className="brend_svg"
                        src={Dollar}
                        alt="Just an icon"
                      />
                      <div className="flex flex-col gap-y-1">
                        <span className="text-textSilver">To’lov summasi:</span>
                        <span className="font-semibold">
                          {debtor?.last_info?.total_cash_in_usd}
                        </span>
                      </div>
                    </li>
                    <li className="flex items-center gap-x-2">
                      <img
                        className="brend_svg"
                        src={Users}
                        alt="Just an icon"
                      />
                      <div className="flex flex-col gap-y-1">
                        <span className="text-textSilver">Kim oldi:</span>
                        <span className="font-semibold">
                          {debtor?.last_info?.who_taked}
                        </span>
                      </div>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </ul>
            </>
          ) : (
            <ul className="flex flex-col gap-y-6 mt-6">
              {Object.entries(histories?.data || {}).length &&
                Object.entries(histories?.data || {}).map((el, id) => (
                  <li key={id}>
                    <time className="font-medium text-mainText mb-2 block">
                      {el[0]}
                    </time>
                    <ul className="flex flex-col gap-y-2">
                      {el[1]?.length &&
                        el[1].map((it) => (
                          <li
                            className="p-4"
                            key={it?.id}
                            style={{
                              boxShadow:
                                "0px 3px 15px 0px rgba(13, 14, 43, 0.05)",
                            }}
                          >
                            <p className="mb-4">
                              <span className="text-textSilver font-medium mr-1">
                                Xodim:
                              </span>{" "}
                              <span className="font-medium">{it?.name}</span>
                            </p>
                            <div className="flex justify-between items-center">
                              <time className="text-sm text-textSilver font-medium">
                                {it?.time}
                              </time>
                              <span
                                className={`${
                                  it?._money_ > 0
                                    ? "text-[#29C061]"
                                    : "text-[#DD3439]"
                                } text-lg font-medium`}
                              >
                                {it?._money_} {debtor?.selected_currency}
                              </span>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </li>
                ))}
            </ul>
          )}
        </div>

        {!isClicked ? (
          <div className="flex gap-x-4 mt-6">
            <button
              className="bg-brendColor py-3 w-full rounded-md text-white"
              onClick={() => setIsModalOpen(true)}
            >
              To'lash
            </button>
          </div>
        ) : (
          ""
        )}
      </div>

      <div
        className={`${
          isFilterClicked
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed transition delay-150 duration-300 z-10 bottom-0 min-[500px]:left-1/2 min-[500px]:-translate-x-1/2 max-w-[500px] w-full left-0 right-0 flex justify-center items-end`}
        id="modal_wrapper"
        onClick={handleClick}
      >
        <div
          className={`${
            isFilterClicked ? "translate-y-0" : "translate-y-full"
          } bg-white w-full py-5 px-4 pt-6 transition-all delay-75 ease-in-out h-3/4 overflow-y-auto flex flex-col justify-between pb-3`}
        >
          <div className="flex flex-col justify-between">
            <p className="text-lg text-brendColor font-semibold text-center mb-8">
              {t("gipyurExpenses.filter.title")}
            </p>
            <div className="flex justify-between">
              <span className="grow text-textDark">
                {t("gipyurExpenses.filter.start")}
              </span>
              <span className="grow text-textDark">
                {t("gipyurExpenses.filter.end")}
              </span>
            </div>
            <DatePickerInput
              filter={true}
              start={start}
              end={end}
              setStart={setStart}
              setEnd={setEnd}
              formattedDate={formattedDate}
            ></DatePickerInput>
          </div>
          <MainButton handleClick={() => setIsClicked(false)} type={"button"}>
            {t("gipyurExpenses.filter.sort")}
          </MainButton>
        </div>
      </div>
      <div
        className={`${
          isModalOpen
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed transition  duration-300 z-[60] bottom-0 min-[500px]:left-1/2 min-[500px]:-translate-x-1/2 max-w-[500px] w-full left-0 right-0 flex items-end justify-center`}
        id="modal_wrapper"
        onClick={handleModalClick}
      >
        <form
          className={`${
            isModalOpen ? "translate-y-0" : "translate-y-full"
          } bg-white w-full py-4 px-4 flex flex-col z-[61] justify-between transition-all ease-in h-3/4 overflow-y-auto`}
          onSubmit={handleSubmit}
        >
          <div>
            <h5 className="text-center text-2xl text-brendColor font-semibold mb-8">
              {debtor?.gipyur_client_debt_usd}
            </h5>
            <CurrencyCalculator
              currency={currency?.usd_rate}
            ></CurrencyCalculator>

            <p className="text-base text-textDark mb-1.5 mt-6">
              {t("debtorsPage.form.debtType")}
            </p>

            <div className="flex justify-between gap-x-6 items-center mb-10">
              <label className="relative w-1/2">
                <input
                  className="pl-3 w-full pr-12 text-base py-4 rounded-md text-textSilver border border-[#D7E1EA] outline-none "
                  type="number"
                  name="money_usd"
                />
                <span className="absolute right-3  top-1/2 -translate-y-1/2 text-lg text-mainText font-semibold">
                  USD
                </span>
              </label>
              <label className="relative w-1/2">
                <input
                  className="pl-3 w-full pr-12 text-base py-4 rounded-md text-textSilver border border-[#D7E1EA] outline-none "
                  type="number"
                  name="money_uzs"
                />
                <span className="absolute right-3  top-1/2 -translate-y-1/2 text-lg text-mainText font-semibold">
                  UZS
                </span>
              </label>
            </div>
          </div>

          <MainButton type={"submit"}>{t("debtorsPage.pay")}</MainButton>
        </form>
      </div>
    </div>
  );
}
