import React, { useState } from "react";
import Header from "../../components/Header/Header";
import ButtonSheetImg from "../../components/BottomSheet/ButtonSheetImg";
import ButtonSheetSimple from "../../components/BottomSheet/BottomSheetSimple";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import MainInput from "../../components/MainInput/MainInput";
import CurrencyCalculator from "../../components/CurrencyCalculator/CurrencyCalculator";
import InputDate from "../../components/MainInput/InputDate";
import { CameraPh } from "../../assets/images";
import MainButton from "../../components/MainButton/MainButton";
import { formattedDate } from "../../utils/getDatePickerDate";
import axios from "axios";
import useToken from "../../hooks/useToken";
import useUserId from "../../hooks/useUserId";
import useAlert from "../../hooks/useAlert";
import { useNavigate } from "react-router-dom";
import { validateInputWithCurrency } from "../../utils/validateInput";
import { useTranslation } from "react-i18next";
import useLoading from "../../hooks/useLoading";

const shlefData = [
  { id: 1, title: 30, tag: "sm" },
  { id: 2, title: 60, tag: "sm" },
  { id: 3, title: 80, tag: "sm" },
  { id: 4, title: 100, tag: "sm" },
  { id: 5, title: 150, tag: "sm" },
  { id: 6, title: 200, tag: "sm" },
];

export default function Booking() {
  const { t } = useTranslation();

  const salons = useFetch(baseUrl + "/salon/all");

  const dresses = useFetch(baseUrl + "/dress/all");

  const currency = useFetch(baseUrl + "/other/central-bank-usd-rate");

  const [_, setIsLoading] = useLoading()

  const [token] = useToken();

  const [userId] = useUserId();

  const [showAlert] = useAlert();

  const [selectedImages, setSelectedImages] = useState([]);

  const [images, setImages] = useState([]);

  const [dress, setDress] = useState();

  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const elements = e.target.elements;

    const { money_salon_usd, money_salon_uzs, price } = elements;

    if (
      validateInputWithCurrency(
        price.value,
        money_salon_usd,
        money_salon_uzs,
        currency?.usd_rate,
        showAlert
      )
    ) {
      return true;
    } else {
      let newDress = {
        base_price: dress?.price,
        delivery_date: formattedDate,
        images: selectedImages,
        must_be_delivered: isChecked,
        central_bank_usd_course: currency?.usd_rate,
        created_by_user: userId,
        money_salon_uzs: 0,
        money_salon_usd: 0,
      };

      for (let val of elements) {
        if (val.name && val.value) {
          newDress[val.name] = val.value;
        }

        if (val.value && val.name && !isNaN(val?.value)) {
          newDress[val.name] = Number(val.value);
        }

        if (val.name === "description") {
          newDress[val.name] = val.value + "";
        }
      }

      if (
        newDress?.base_price &&
        Object.values(newDress).length >= 13 &&
        (newDress?.money_salon_usd || newDress?.money_salon_uzs)
      ) {
        setIsLoading(true)
        axios
          .post(baseUrl + "/dress-order/create", newDress, {
            method: "POST",
            headers: {
              Authorization: `mme:${token}`,
            },
          })
          .then((res) => {
            if (res.data?.data?.id) {
              setIsLoading(false)
              showAlert(
                "Muvafaqqiyatli !",
                "Ma'lumot muvafaqqiyatli qo'shild.",
                "success"
              );
              navigate("/sell");
            }
          })
          .catch((err) => {
            setIsLoading(false)
            showAlert("Xatolik !", "Qaytadan urinib ko'ring!", "error");
          });
      } else {
        showAlert("Ogohlantirish !", "Formani to'liq to'ldiring...", "warning");
      }
    }
  };

  let salonData = salons?.map((el) => {
    return { id: el?.id, title: el?.salon_name, image: el?.salon_image };
  });

  let dressData = dresses?.map((el) => {
    return {
      id: el?.id,
      price: el?.dress_price,
      title: el?.dress_name,
      created_at: el?.created_at,
      image: el?.dress_image,
      shleft_size: el?.dress_shleft_size,
      dress_color: el?.dress_color,
    };
  });

  let validColors = [];

  const handleChange = (e) => {
    setImages([...images, ...e.target.files]);

    let formData = new FormData();

    formData.append("file", e.target.files[0]);

    try {
      axios
        .post(baseUrl + "/file-upload", formData, {
          method: "POST",
          headers: {
            Authorization: `mme:${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 201) {
            setSelectedImages([...selectedImages, res.data?.image]);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header icon={true} heading={t("pages.booking")} navPath="/sell"></Header>
      <form
        className="pt-6 px-4 bg-white flex flex-col gap-y-4 pb-6"
        onSubmit={handleSubmit}
      >
        <ButtonSheetImg
          placeholder={t("bookingPage.form.salonChoice")}
          data={salonData}
          select={true}
          title={t("bookingPage.form.salonName")}
          width={"165px"}
          height={"120px"}
          name={"customer_salon_id"}
          btn={true}
          btnTitle={"Salon qo'shish"}
          navPath={"/addNewSalon"}
        ></ButtonSheetImg>
        <ButtonSheetImg
          type={"dress"}
          placeholder={t("bookingPage.form.dressChoice")}
          data={dressData}
          select={true}
          title={t("bookingPage.form.dressName")}
          width={"165px"}
          height={"120px"}
          name={"dress_id"}
          name1={"dress_name"}
          setDress={setDress}
          btn={true}
          btnTitle={"Ko'ylak qo'shish"}
          navPath={"/addDress"}
        ></ButtonSheetImg>
        <div className="flex gap-x-4 justify-between items-center">
          <ButtonSheetSimple
            title={t("bookingPage.form.shleftSize")}
            select={true}
            data={shlefData}
            name1={"shleft_size"}
            value={{ title: dress?.shleft_size }}
          ></ButtonSheetSimple>
          <ButtonSheetSimple
            title={t("bookingPage.form.dressColor")}
            select={true}
            data={validColors}
            name1={"dress_color"}
            value={{ title: dress?.dress_color }}
          ></ButtonSheetSimple>
        </div>
        <MainInput
          title={t("bookingPage.form.dressPrice")}
          name={"price"}
          currency={"usd"}
          type="number"
          value={dress?.price}
          // required={true}
        ></MainInput>
        <CurrencyCalculator currency={currency?.usd_rate}></CurrencyCalculator>
        <div>
          <span className="text-textDark">
            {t("bookingPage.form.givenMoney")}
          </span>
          <div className="flex gap-x-4 justify-between items-center">
            <MainInput
              currency={"usd"}
              name={"money_salon_usd"}
              // required={true}
              type="number"
            ></MainInput>
            <MainInput
              currency={"uzs"}
              name={"money_salon_uzs"}
              type="number"
              // required={true}
            ></MainInput>
          </div>
        </div>
        <label>
          <span>{t("bookingPage.form.finishedDate")}</span>
          <InputDate name="delivery_date"></InputDate>
        </label>
        <label>
          <span className="text-textDark mb-2 block">
            {t("bookingPage.form.deliveryDate")}
          </span>
          <input
            className="w-5 h-5"
            type="checkbox"
            // name="must_be_delivered"
            onChange={() => setIsChecked(!isChecked)}
          />
        </label>
        <div>
          <span className="text-textDark mb-2 block">
            {t("bookingPage.form.addDressImage")}
          </span>
          <div className="flex gap-2 items-center flex-wrap">
            {images.length > 0 ? (
              images.map((el, id) => (
                <div
                  className="w-20 h-[70px] border-dashed border rounded-md border-[#D7E1EA] flex justify-center items-center"
                  key={id}
                >
                  <img
                    className="w-full h-full object-cover rounded-md"
                    width={80}
                    height={70}
                    src={URL.createObjectURL(el)}
                    alt="Just an icon"
                  />
                </div>
              ))
            ) : (
              <div className="w-20 h-[70px] border-dashed border rounded-md border-[#D7E1EA] flex justify-center items-center">
                <img src={CameraPh} alt="Just an icon" />
              </div>
            )}
            <label className="w-20 h-[70px] border-dashed border rounded-md border-[#D7E1EA] flex justify-center items-center">
              <input
                className="hidden"
                type="file"
                onChange={handleChange}
                multiple
                accept="image/*"
              />
              <span className="text-3xl text-brendColor">+</span>
            </label>
          </div>
        </div>
        <label className="mb-12">
          <span className="text-textSilver block mb-1.5">
            {t("bookingPage.form.dressDesc")}
          </span>
          <textarea
            className="w-full max-h-[140px] h-full outline-none p-3.5 border border-[#D7E1EA] rounded-md"
            name="description"
          ></textarea>
        </label>

        <MainButton type={"submit"}>{t("submit")}</MainButton>
      </form>
    </>
  );
}
