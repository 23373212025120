import React, { useState } from "react";
import { ArrowRight } from "../../assets/images";

export default function Accordion({ title, body }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="p-4 text-[#090A2D] bg-white rounded-main">
      <div
        className="flex items-center justify-between w-full"
        onClick={() => setIsOpen(!isOpen)}
        style={{
          boxShadow: "0px 0px 200px rgba(13, 14, 43, 0.1)",
        }}
      >
        <span className={isOpen ? "mb-3" : ""}>{title}</span>
        <img
          className={`${
            !isOpen ? "rotate-90" : "-rotate-90"
          } transition-all duration-300`}
          src={ArrowRight}
          alt="Just an icon"
        />
      </div>
      <div className={`${isOpen ? "block" : "hidden"} bg-white`}>
        <p>{body}</p>
      </div>
    </div>
  );
}
