import React, { useEffect, useState } from "react";
import { DownArrow } from "../../assets/images";

export default function SelectBox({
  label,
  data,
  margin,
  name,
  optionHeading,
  setCategoryId,
  required,
  newValue,
  placeholder,
}) {
  const [isClicked, setIsClicked] = useState(false);

  const [value, setValue] = useState();

  useEffect(() => {
    if (newValue) {
      let foundVal = data.find((it) => it?.id === newValue);
      setValue(foundVal?.category_name);
    }
  }, [data, newValue]);

  const handleClick = (val) => {
    setValue(val);
    if (setCategoryId) {
      let foundData = data.find((el) => el.category_name === val);
      setCategoryId(foundData?.id);
    }
  };

  return (
    <label className="block relative" style={{ marginBottom: margin }}>
      <span className="text-base block mb-2 text-textDark">{label}</span>
      <span className="relative">
        <input
          className="border border-[#D7E1EA] px-4 py-3 text-textDark placeholder:text-textSilver w-full bg-white rounded-md outline-none"
          type="text"
          onClick={() => {
            setIsClicked(!isClicked);
          }}
          placeholder={placeholder}
          defaultValue={value}
          readOnly
          name={name}
          required={required}
        />
        <img
          className="absolute right-6 top-1/2 -translate-y-1/2"
          src={DownArrow}
          alt="Just an icon"
        />
      </span>
      <span
        className={`${
          isClicked
            ? "scale-100 translate-y-0 opacity-100"
            : "scale-0 translate-y-full opacity-0"
        } absolute overflow-auto z-20 max-h-fit p-[-16px] flex flex-col w-full bg-white ease-in rounded-t-main px-4 py-5 gap-y-5 transition-all duration-300`}
      >
        <span className="block text-center text-lg text-textDark font-semibold mb-4">
          {optionHeading}
        </span>
        {data?.length &&
          data.map((el) => (
            <span
              className="flex justify-between items-center"
              key={el?.id}
              onClick={() => {
                handleClick(el?.category_name);
              }}
            >
              <span className="text-base text-textDark">
                {el?.category_name}
              </span>
              <span
                className="w-5 h-5 rounded-full border border-textDark"
                style={{
                  backgroundColor:
                    value === el?.category_name ? "#702DE3" : "transparent",
                }}
              ></span>
            </span>
          ))}
      </span>
    </label>
  );
}
