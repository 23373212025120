import React, { useEffect, useState } from "react";
import {
  CloseIcon,
  Search,
  Profile,
  DownArrow,
  AddSquare,
} from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { searchData } from "../../utils/SearchData";
import { baseUrl } from "../../baseUrl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

export default function PartnersButtonSheet({ data, setData = () => {} }) {
  const [isClicked, setIsClicked] = useState(false);

  const [value, setValue] = useState("");

  const [selected, setSelected] = useState(data[0]);

  const navigate = useNavigate();

  const handleClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked(false);
    }
  };

  useEffect(() => {
    setSelected(data[0]);
    setData(data[0]);
  }, [data]);

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  return (
    <>
      <div className="text-base" onClick={() => setIsClicked(true)}>
        <span className="text-textDark mb-1.5 block">Mijozni tanlang</span>
        <div className="text-textSilver flex justify-between items-center text-base capitalize border border-[#D7E1EA] px-4 py-3 rounded-md">
          <span>{selected?.gipyur_client_name}</span>
          <img src={DownArrow} alt="Just an icon" />
        </div>
      </div>
      <div
        className={`${
          isClicked
            ? "top-0 bg-black bg-opacity-25"
            : "top-full bg-white bg-opacity-0"
        } fixed transition  duration-300 delay-75 z-10 bottom-0 min-[500px]:left-1/2 min-[500px]:-translate-x-1/2 max-w-[500px] w-full left-0 right-0 flex justify-center items-end`}
        id="modal_wrapper"
        onClick={handleClick}
      >
        <div
          className={`${
            isClicked ? "translate-y-0" : "translate-y-full"
          } bg-white w-full flex flex-col justify-between gap-y-4 overflow-y-auto py-5 px-4 transition-all delay-75 ease-in-out h-3/4`}
        >
          <div>
            <label className="relative block mb-6">
              <img
                className="absolute left-3 top-1/2 -translate-y-1/2"
                src={Search}
                alt="Just an icon"
              />
              <input
                className="border border-[#AFBACA] outline-none py-3 block w-full rounded-md px-12"
                type="text"
                value={value}
                placeholder="Qidirish"
                onChange={(e) => setValue(e.target.value)}
              />
              <img
                className="absolute right-3 top-1/2 -translate-y-1/2"
                src={CloseIcon}
                alt="Just an icon"
                onClick={() => setValue("")}
              />
            </label>
            <ul className="flex flex-col gap-y-2.5 overflow-y-auto">
              {data?.length > 0 &&
                searchData(
                  value,
                  data.map((el) => {
                    el.title = el?.gipyur_client_name;
                    return el;
                  })
                ).map((el) => (
                  <li
                    className="w-full py-2.5 px-3 flex items-center rounded-main cursor-pointer relative border border-[#E6E6EA]"
                    key={el?.id}
                    onClick={() => {
                      setSelected(el);
                      setData(el);
                      setIsClicked(false);
                    }}
                    name="item"
                  >
                    <span
                      className="absolute top-0 right-0 bottom-0 left-0 z-50"
                      id="clicker"
                    ></span>
                    <span
                      className="w-16 h-16 rounded-full flex justify-center items-center mr-3.5"
                      style={{
                        boxShadow: "0px 3px 10px rgba(13, 14, 43, 0.11)",
                      }}
                    >
                      {el?.gipyur_client_image ? (
                        <LazyLoadImage
                          className="w-full h-full rounded-full"
                          src={baseUrl + el?.gipyur_client_image}
                          alt="pic"
                          onError={handleImageError}
                        />
                      ) : (
                        <LazyLoadImage
                          className="w-6 h-7 object-contain"
                          src={Profile}
                          alt="Just an icon"
                        />
                      )}
                    </span>
                    <div>
                      <p>
                        <span className="text-base text-textSilver font-medium mr-2.5">
                          Ism:
                        </span>
                        <span className="text-base text-textDark capitalize">
                          {el?.gipyur_client_name}
                        </span>
                      </p>
                      <p>
                        <span className="text-base text-textSilver font-medium mr-2.5">
                          Telefon:
                        </span>
                        <span className="text-base text-textDark">
                          {el?.gipyur_client_phone}
                        </span>
                      </p>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <button
            className="bg-[#702DE3] rounded-md text-white py-3.5 flex justify-center items-center gap-x-2 text-center w-full mt-6"
            onClick={() => navigate("/addNewClient")}
          >
            <img
              className="brend_svg--white"
              src={AddSquare}
              alt="Just an icon"
            />
            <span className="">Yangi mijoz qo'shish</span>
          </button>
        </div>
      </div>
    </>
  );
}
