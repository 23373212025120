import React from "react";
import AuthenticatedApp from "./AuthenticatedApp";
import UnAuthtenticatedApp from "./UnAuthtenticatedApp";
import "./assets/styles/main.css";
import useToken from "./hooks/useToken";

function App() {
  const [token] = useToken();

  if (token) {
    return <AuthenticatedApp></AuthenticatedApp>;
  } else {
    return <UnAuthtenticatedApp></UnAuthtenticatedApp>;
  }
}

export default App;
