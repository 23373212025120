import React, { useState } from "react";
import useToken from "../../hooks/useToken";
import useUserId from "../../hooks/useUserId";
import useRole from "../../hooks/useRole";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function Confirmation({ phoneNumber }) {
  let phoneNum = `${phoneNumber}`.slice(-4);

  const { t } = useTranslation();

  const [setToken] = useToken(true);

  const [setUserId] = useUserId(true);

  const [setUserRole] = useRole(true);

  const navigate = useNavigate();

  const handleChange = (el, id) => {
    if (isNaN(el.value)) return false;

    setOtp([...otp.map((item, i) => (id === i ? el.value : item))]);

    if (el.value !== "" && el.nextSibling) {
      el.nextSibling.focus();
    } else if (el.value === "" && el.previousSibling) {
      el.previousSibling.focus();
    }
  };

  const [otp, setOtp] = useState(new Array(5).fill(""));

  const lang = window.localStorage.getItem("lang");

  const handleSubmit = (e) => {
    e.preventDefault();

    let elements = e.target.elements;
    let res = "";
    for (let el of elements) {
      res += el?.value;
    }

    let data = {
      phone: "998" + phoneNumber,
      code: +res,
    };

    if (phoneNumber && res.length === 5) {
      axios
        .post(baseUrl + "/login/code", data)
        .then((res) => {
          if (res?.data?.userID) {
            let language = res?.data?.selected_language?.toLowerCase();
            setToken(res?.data.token);
            setUserId(res?.data?.userID);
            window.localStorage.setItem(
              "userId",
              JSON.stringify(res?.data?.userID)
            );
            window.localStorage.setItem("lang", language);
            i18next.changeLanguage(language);
            setUserRole({
              role: res?.data.userRole,
              status: res?.data.userRoleStatus,
            });
            navigate("/");
            axios
              .put(
                baseUrl + "/user/update",
                { id: res?.data?.userID, language: lang.toUpperCase() },
                {
                  headers: {
                    Authorization: "mme:" + res?.data?.token,
                  },
                }
              )
              .then((res) => res)
              .catch((err) => console.log(err));
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="text-3xl text-white text-center mb-6 font-medium">
        {t("login.checkingSms")}
      </h3>
      <p className="text-white text-center mx-auto w-80 mb-9">
        {t("login.smsText", { phoneNum })}
      </p>
      <div className="flex justify-between w-full" id="input-group">
        {otp.map((el, ids) => (
          <input
            className="rounded-md border border-[#DCE0E7] text-xl w-12 h-12 text-center"
            type="tel"
            maxLength={1}
            name="otp"
            key={ids}
            value={el}
            onChange={(e) => handleChange(e.target, ids)}
            onFocus={(e) => e.target.select()}
          />
        ))}
      </div>
      <button
        className="w-full py-4 text-base text-mainText bg-white mt-16 rounded-md"
        style={{ boxShadow: "0px 2px 14px rgba(0, 0, 0, 0.05)" }}
      >
        {t("login.btn")}
      </button>
    </form>
  );
}
