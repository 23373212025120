import React from "react";
import { useTranslation } from "react-i18next";

export default function ReportCard({ name, price_usd, price_uzs, time }) {
  const { t } = useTranslation();
  return (
    <ul className="border border-[#E6E6EA] py-5 px-4 flex flex-col gap-y-6 rounded-main">
      <li className="border-b border-[#E6E6EA] flex justify-between items-center">
        <span className="text-base text-textDark font-medium">
          {t("viewReport.card.name")}:
        </span>
        <span className="text-base text-textDark font-light">{name}</span>
      </li>
      <li className="border-b border-[#E6E6EA] flex justify-between items-center">
        <span className="text-base text-textDark font-medium">
          {t("viewReport.card.price")}{" "}
          <span style={{ color: "#354B91" }}>USD</span>
        </span>
        <span className="text-base text-textDark font-light">{price_usd}</span>
      </li>
      <li className="border-b border-[#E6E6EA] flex justify-between items-center">
        <span className="text-base text-textDark font-medium">
          {t("viewReport.card.price")}{" "}
          <span style={{ color: "#354B91" }}>UZS</span>
        </span>
        <span className="text-base text-textDark font-light">{price_uzs}</span>
      </li>
      <li className="border-b border-[#E6E6EA] flex justify-between items-center">
        <span className="text-base text-textDark font-medium">
          {t("viewReport.card.time")}:
        </span>
        <span className="text-base text-textDark font-light">{time}</span>
      </li>
    </ul>
  );
}
