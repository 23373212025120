import React, { useState } from "react";
import { Clock } from "../../assets/images";
import Header from "../../components/Header/Header";
import GipyurButtonSheet from "../../components/BottomSheet/GipyurButtonSheet";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import ButtonSheetSimple from "../../components/BottomSheet/BottomSheetSimple";
import MainInput from "../../components/MainInput/MainInput";
import MainButton from "../../components/MainButton/MainButton";
import { useNotify } from "../../hooks/useNotify";
import useToken from "../../hooks/useToken";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import useLoading from "../../hooks/useLoading";

const places = [
  {
    id: 1,
    title: "Do'konga",
    status: "DO'KON",
  },
  {
    id: 2,
    title: "Lastoria omboriga",
    status: "LASTORIA",
  },
];

export default function GipyurDistirbution() {
  const { t } = useTranslation();

  const [_, setIsLoading] = useLoading();

  const [data, setData] = useState({});

  const [whereGiven, setWhereGiven] = useState(places[0]);

  const gipyurs = useFetch(baseUrl + "/gipyur/all");

  const notify = useNotify();

  const navigate = useNavigate();

  const [token] = useToken();

  const handleSubmit = (e) => {
    e.preventDefault();

    const { distributed_gipyur_metr } = e.target.elements;

    let newGipyur = {
      gipyur_id: data?.id,
      sold_from: whereGiven.status.toUpperCase(),
      distributed_gipyur_metr: +distributed_gipyur_metr.value,
    };

    if (!distributed_gipyur_metr.value) {
      distributed_gipyur_metr.style.borderColor = "red";
      return;
    }

    if (+distributed_gipyur_metr.value > data?.gipyur_metr) {
      alert(
        `Me'yordan ortiq gipyur kiritildi ! Jami: ${data?.gipyur_metr} m, \n Kiritilgan gipyur: ${distributed_gipyur_metr.value}`
      );
      return notify("Xatolik yuz berdi !", 400);
    } else {
      setIsLoading(true);
      axios
        .post(baseUrl + "/distribution", newGipyur, {
          method: "POST",
          headers: {
            Authorization: "mme:" + token,
          },
        })
        .then((res) => {
          if (res?.data?.data?.id) {
            setIsLoading(false);
            notify("Successfully!", 200);
            navigate(-1);
          }
        })
        .catch((res) => {
          setIsLoading(false);
          notify("Xatolik!", 400);
        });
    }
  };
  return (
    <div className="flex flex-col h-screen bg-white">
      <div className="relative">
        <Header
          heading={t("gipyurDistribution.heading")}
          icon={true}
          navPath={-1}
        ></Header>
        <img
          className="absolute top-1/2 -translate-y-1/2 right-9 w-7 h-7"
          src={Clock}
          alt="Just an icon"
          onClick={() => navigate("/gipyurHistory")}
        />
      </div>
      <form
        className="flex flex-col justify-between grow pt-9 pb-4 px-4"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-4">
          <GipyurButtonSheet
            variant="code"
            data={gipyurs}
            setData={setData}
          ></GipyurButtonSheet>
          <ButtonSheetSimple
            title={t("gipyurDistribution.form.whereGive")}
            name1={"sold_from"}
            select={true}
            data={places}
            setData={setWhereGiven}
          ></ButtonSheetSimple>
          <MainInput
            title={t("gipyurDistribution.form.howMuchGive")}
            currency={"m"}
            name={"distributed_gipyur_metr"}
          ></MainInput>
        </div>
        <MainButton type={"submit"}>{t("submit")}</MainButton>
      </form>
    </div>
  );
}
