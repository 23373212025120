const today = new Date();

const year1 = today.getFullYear();

const month1 = String(today.getMonth() + 1).padStart(2, "0");

const day1 = String(today.getDate()).padStart(2, "0");

const formattedDate = `${year1}-${month1}-${day1}`;

function getDatePickerDate(date) {
  const originalDate = new Date(date);

  const year = originalDate.getFullYear();

  const month = String(originalDate.getMonth() + 1).padStart(2, "0");

  const day = String(originalDate.getDate()).padStart(2, "0");

  if (year && month && day) {
    return `${year}-${month}-${day}`;
  } else {
    return formattedDate;
  }
}

export { getDatePickerDate, formattedDate };
