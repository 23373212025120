import React from "react";

export default function MainButton({
  children,
  bgColor = "#702DE3",
  width,
  handleClick,
  type = "button",
  icon,
  color,
}) {
  return (
    <button
      className="rounded-md p-3.5 text-whitish text-base w-full flex items-center justify-center"
      style={{ backgroundColor: bgColor, width: width, color: color }}
      onClick={handleClick}
      type={type}
    >
      {icon ? (
        <img
          className="mr-3.5 brend_svg--white w-5 h-5 object-contain"
          src={icon}
          alt="just an icon"
        />
      ) : (
        ""
      )}
      <span>{children}</span>
    </button>
  );
}
