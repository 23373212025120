import React from "react";
import { useNavigate } from "react-router-dom";
import { Search } from "../../assets/images";
import { Edit } from "../../assets/images";
import useUserId from "../../hooks/useUserId";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import useRole from "../../hooks/useRole";

export default function Header({
  heading,
  icon = false,
  navPath = "/",
  user,
  search = false,
  edit = false,
  handleEditClick = () => {},
  isEditClicked,
  profile,
}) {
  const navigate = useNavigate();

  const [userId] = useUserId();

  const [role] = useRole();

  const userjon = useFetch(baseUrl + "/user/" + userId);

  return (
    <div
      className="relative py-9 bg-white text-center"
      style={{ boxShadow: "0px 12px 14px rgba(209, 212, 226, 0.157261)" }}
    >
      {search ? (
        <img
          className="absolute right-4 top-1/2 -translate-y-1/2"
          src={Search}
          alt="Just an icon"
        />
      ) : (
        ""
      )}
      {edit && role?.status !== 1 ? (
        <img
          className="absolute right-4 top-1/2 -translate-y-1/2 p-1.5 rounded-md"
          src={Edit}
          alt="Just an icon"
          style={{ backgroundColor: isEditClicked ? "#D9D9D9" : "transparent" }}
          onClick={(e) => {
            handleEditClick(e);
          }}
        />
      ) : (
        ""
      )}
      <h2 className="text-xl font-semibold text-[#702DE3]">{heading}</h2>
      {user ? (
        <p className=" text-textDark text-base font-medium">
          {userjon?.name} {userjon?.surname}
        </p>
      ) : (
        ""
      )}
      {icon ? (
        <span
          className="h-8 w-8 rounded-full text-lg text-[#354B91]  top-1/2 -translate-y-1/2 left-6 border border-[#354B91] flex justify-center items-center absolute"
          onClick={(e) => {
            navigate(navPath);
            handleEditClick(e);
          }}
        >
          &#10094;
        </span>
      ) : (
        ""
      )}
      {profile ? (
        <div className="flex justify-between items-center mt-7 px-4">
          <div className="">
            <p className="text-lg font-semibold text-textDark mb-1.5">
              {userjon?.name} {userjon?.surname}
            </p>
            <p className="text-textSilver font-medium w-max">
              {userjon?.phone}
            </p>
          </div>
          <img src={Edit} alt="Just an icon" id="edit_profile_form" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
