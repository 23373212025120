import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Search, CloseIcon, Profile } from "../../assets/images";
import { searchData } from "../../utils/SearchData";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import MainButton from "../../components/MainButton/MainButton";
import CurrencyCalculator from "../../components/CurrencyCalculator/CurrencyCalculator";
import useToken from "../../hooks/useToken";
import axios from "axios";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import { formatNumber, formatUzbekPhoneNumber } from "../../utils/formatNumber";
import useRole from "../../hooks/useRole";

export default function ListOfDebtors() {
  const navigate = useNavigate();

  const [token] = useToken();

  const [role] = useRole();

  const { t } = useTranslation();

  const [showAlert] = useAlert();

  const [isSearch, setIsSearch] = useState(false);

  const [isClicked, setIsClicked] = useState(false);

  const [debtor, setDebtor] = useState({});

  const [value, setValue] = useState("");

  const [debtors, refetch] = useFetch(baseUrl + "/gipyur-client/all", true);

  const currency = useFetch(baseUrl + "/other/central-bank-usd-rate");

  const handleModalClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked(false);
    }
  };

  return (
    <div className="flex flex-col bg-white gap-y-6 h-screen">
      <div
        className="pb-4 px-4 bg-white pt-10"
        style={{ boxShadow: "0px 12px 14px rgba(209, 212, 226, 0.157261)" }}
      >
        {isSearch ? (
          <label className="relative block mb-6">
            <img
              className="absolute left-3 top-1/2 -translate-y-1/2"
              src={Search}
              alt="Just an icon"
            />
            <input
              className="border border-[#AFBACA] outline-none py-3 block w-full rounded-md px-12"
              type="text"
              value={value}
              placeholder={t("search")}
              onChange={(e) => setValue(e.target.value)}
            />
            <img
              className="absolute right-3 top-1/2 -translate-y-1/2"
              src={CloseIcon}
              alt="Just an icon"
              onClick={() => setIsSearch(false)}
            />
          </label>
        ) : (
          <div className="text-center mb-10 relative">
            <span
              className="h-8 w-8 rounded-full text-lg text-[#354B91]  top-1/2 -translate-y-1/2 left-6 border border-[#354B91] flex justify-center items-center absolute"
              onClick={(e) => {
                navigate(-1);
              }}
            >
              &#10094;
            </span>
            <h1 className="text-xl text-[#702DE3]">
              <span>{t("pages.debtorsList")}</span>
            </h1>
            <img
              className="absolute right-2 top-0"
              src={Search}
              alt="Just an icon"
              onClick={() => setIsSearch(!isSearch)}
            />
          </div>
        )}
      </div>

      <div className="px-4">
        <div className="border border-[#E6E6EA] p-4 rounded-main">
          <p className="text-textDark font-medium">
            {t("debtorsPage.totalCount")}:{" "}
            {
              searchData(
                value,
                debtors?.map((it) => {
                  it.title = it?.gipyur_client_name;
                  return it;
                })
              )?.length
            }
          </p>
          <p className="text-textDark font-medium">
            {t("total")}
            <span className="text-mainText uppercase font-medium mx-1">
              usd:
            </span>
            {formatNumber(
              searchData(
                value,
                debtors?.map((it) => {
                  it.title = it?.gipyur_client_name;
                  return it;
                })
              )
                ?.reduce((acc, curr) => acc + +curr?.gipyur_client_debt_usd, 0)
                ?.toFixed(2)
            )}
          </p>
        </div>
      </div>

      <ul className="flex flex-col gap-y-2.5 overflow-y-auto unwanted_scroll bg-white px-4 grow pb-4">
        {debtors?.length > 0 &&
          searchData(
            value,
            debtors?.map((it) => {
              it.title = it?.gipyur_client_name;
              return it;
            })
          ).map((el) => (
            <li
              className="w-full py-2.5 px-3 flex items-center rounded-main cursor-pointer relative border border-[#E6E6EA]"
              key={el?.id}
              style={{ boxShadow: "0px 3px 15px rgba(13, 14, 43, 0.05)" }}
              onClick={() => {
                setDebtor(el);
                navigate(el?.id);
              }}
              name="item"
            >
              <span
                className="absolute top-0 right-0 bottom-0 left-0 z-50"
                id="clicker"
              ></span>
              <span
                className="w-16 h-16 rounded-full flex justify-center items-center mr-3.5"
                style={{ boxShadow: "0px 3px 10px rgba(13, 14, 43, 0.11)" }}
              >
                <img
                  className="w-6 h-7 object-contain"
                  src={Profile}
                  alt="Just an icon"
                />
              </span>
              <div>
                <p>
                  <span className="text-base text-textSilver font-medium mr-2.5">
                    {t("profile.edit.name")}:
                  </span>
                  <span className="text-base text-textDark capitalize font-medium">
                    {el?.gipyur_client_name}
                  </span>
                </p>
                <p>
                  <span className="text-base text-textSilver font-medium mr-2.5">
                    {t("phone")}:
                  </span>
                  <span className="text-base text-textDark font-medium">
                    {formatUzbekPhoneNumber(el?.gipyur_client_phone)}
                  </span>
                </p>
                <p>
                  <span className="text-base text-textSilver font-medium mr-2.5">
                    {t("home.debt")} USD:
                  </span>
                  <span className="text-base text-textDark font-medium">
                    {el?.gipyur_client_debt_usd}
                  </span>
                </p>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}
