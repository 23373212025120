import React, { useCallback, useState } from "react";
import Header from "../../components/Header/Header";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import ButtonSheetImg from "../../components/BottomSheet/ButtonSheetImg";
import InputDate from "../../components/MainInput/InputDate";
import CurrencyCalculator from "../../components/CurrencyCalculator/CurrencyCalculator";
import MainInput from "../../components/MainInput/MainInput";
import MainButton from "../../components/MainButton/MainButton";
import DressCard from "../../components/dressCard/dressCard";
import useUserId from "../../hooks/useUserId";
import { formattedDate } from "../../utils/getDatePickerDate";
import useToken from "../../hooks/useToken";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { checkInputForDebt } from "../../utils/validateInput";
import { useTranslation } from "react-i18next";
import { AddSquare } from "../../assets/images";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

export default function SimpleSale() {
  const salons = useFetch(baseUrl + "/salon/all");

  const currency = useFetch(baseUrl + "/other/central-bank-usd-rate");

  const navigate = useNavigate();

  const [showAlert] = useAlert();

  const [userId] = useUserId();

  const [_, setIsLoading] = useLoading();

  const { t } = useTranslation();

  const [token] = useToken();

  const [dresses, setDresses] = useState([]);

  const [dressCount, setDressCount] = useState(0);

  const [isChecked, setIsChecked] = useState(false);

  const renderDressForm = useCallback(() => {
    let result = [];
    for (let i = 0; i < dressCount; i++) {
      result.push(
        <DressCard
          dressCount={dressCount}
          setDressCount={setDressCount}
          key={i}
          setDresses={setDresses}
          dressess={dresses}
        />
      );
    }

    return result;
  }, [dressCount, dresses]);

  let total_order_summ = dresses?.reduce((acc, curr) => acc + curr?.price, 0);

  const handleSubmit = (e) => {
    e.preventDefault();

    let elements = e.target.elements;

    let { money_salon_usd, money_salon_uzs } = e.target.elements;

    let newData = {
      created_by_user: userId,
      must_be_delivered: isChecked,
      delivery_date: formattedDate,
      central_bank_usd_course: currency?.usd_rate,
      date_gives_debt: formattedDate,
      money_salon_usd: money_salon_usd.value || 0,
      money_salon_uzs: money_salon_uzs.value || 0,
      total_order_summ,
      dresses,
    };

    for (let el of elements) {
      if (el.name && el.value) {
        newData[el.name] = el.value;
      }

      if (el.value && el.name && !isNaN(el?.value)) {
        newData[el.name] = Number(el.value);
      }
    }

    if (
      checkInputForDebt(
        total_order_summ,
        money_salon_usd,
        money_salon_uzs,
        currency?.usd_rate,
        showAlert
      )
    ) {
      return showAlert("Xatolik !", "Xatolik yuz berdi!", "error");
    } else {
      if (
        dresses.length &&
        (newData?.money_salon_usd || newData?.money_salon_uzs)
      ) {
        try {
          setIsLoading(true);
          axios
            .post(baseUrl + "/simple-sale/create", newData, {
              method: "POST",
              headers: {
                Authorization: "mme:" + token,
              },
            })
            .then((res) => {
              if (res.data?.data?.id) {
                setIsLoading(false);
                showAlert(
                  "Muvaffaqiyatli!",
                  "Jarayon muvaffaqiyatli yakunlandi!",
                  "success"
                );
                navigate("/sell");
              }
            })
            .catch((err) => {
              showAlert("Xatolik !", "Xatolik yuz berdi!", "error");
              setIsLoading(false);
            });
        } catch (error) {}
      }
    }
  };

  let salonData = salons?.map((el) => {
    return { id: el?.id, title: el?.salon_name, image: el?.salon_image };
  });

  return (
    <>
      <div className="relative">
        <Header
          heading={t("pages.simpleSale")}
          icon={true}
          navPath="/sell"
        ></Header>
        <img
          className="absolute top-1/2 -translate-y-1/2 right-9 w-6 h-6"
          src={AddSquare}
          alt="Just an icon"
          onClick={() => navigate("/addDress")}
        />
      </div>
      <form
        className="bg-white px-4 pt-5 flex flex-col gap-y-4 pb-5"
        onSubmit={handleSubmit}
      >
        {renderDressForm()}
        <p className="text-lg text-textDark font-semibold mb-2">
          {t("total")}:{" "}
          <span className="text-brendColor uppercase">
            {total_order_summ} usd
          </span>
        </p>
        <button
          className="block w-full py-3 text-center rounded-md text-brendColor font-semibold border border-brendColor"
          type="button"
          onClick={() => {
            if (renderDressForm().length === dresses.length) {
              setDressCount(dressCount + 1);
            } else {
              showAlert(
                "Ogohlantirish !",
                "Iltimos yangi card qo'shish uchun hozirgi cardni ma'lumotlarini to'liq kiriting",
                "warning"
              );
            }
          }}
        >
          {t("simpleSale.addDress")} +
        </button>

        <ButtonSheetImg
          placeholder={t("bookingPage.form.salonChoice")}
          data={salonData}
          select={true}
          title={t("bookingPage.form.salonName")}
          width={"165px"}
          height={"120px"}
          name={"customer_salon_id"}
          btn={true}
          btnTitle={"Salon qo'shish"}
          navPath={"/addNewSalon"}
        ></ButtonSheetImg>
        <label>
          <span className="block mb-1">
            {t("bookingPage.form.deliveryDate")}
          </span>
          <input
            className="w-5 h-5"
            type="checkbox"
            onChange={() => setIsChecked(!isChecked)}
          />
        </label>
        <label>
          <span className="text-textDark block">
            {t("simpleSale.deliveryDate")}
          </span>
          <InputDate name="delivery_date"></InputDate>
        </label>
        <CurrencyCalculator currency={currency?.usd_rate}></CurrencyCalculator>
        <div>
          <span className="text-textDark block mb-1.5">
            {t("simpleSale.salonGivenMoney")}
          </span>
          <div className="flex justify-between gap-x-4">
            <MainInput
              currency={"usd"}
              name={"money_salon_usd"}
              type="number"
              // required={true}
            ></MainInput>
            <MainInput
              currency={"uzs"}
              type="number"
              name={"money_salon_uzs"}
              // required={true}
            ></MainInput>
          </div>
        </div>
        <label>
          <span className="text-textDark block">
            {" "}
            {t("simpleSale.paybackDate")}
          </span>
          <InputDate name={"date_gives_debt"}></InputDate>
        </label>
        <label className="mb-12">
          <span className="text-textSilver block mb-1.5">
            {t("bookingPage.form.dressDesc")}
          </span>
          <textarea
            className="w-full max-h-[140px] h-full outline-none p-3.5 border border-[#D7E1EA] rounded-md"
            name="description"
          ></textarea>
        </label>

        <MainButton type={"submit"}>{t("submit")}</MainButton>
      </form>
    </>
  );
}
