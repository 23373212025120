import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { AddSquare, Profile } from "../../assets/images";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import { useNavigate } from "react-router-dom";
import MainInput from "../../components/MainInput/MainInput";
import MainButton from "../../components/MainButton/MainButton";
import useUserId from "../../hooks/useUserId";
import axios from "axios";
import useToken from "../../hooks/useToken";
import Moment from "react-moment";
import useRole from "../../hooks/useRole";
import { useTranslation } from "react-i18next";
import useAlert from "../../hooks/useAlert";
import useLoading from "../../hooks/useLoading";

export default function PartnersList() {
  const [userId] = useUserId();

  const { t } = useTranslation();

  const [_, setIsLoading] = useLoading();

  const [showAlert] = useAlert();

  const [token] = useToken();

  const [role] = useRole();

  const [partners, refetch] = useFetch(baseUrl + "/partner/debts/all", true);

  const [isClicked, setIsClicked] = useState();

  const [partner, setPartner] = useState();

  const navigate = useNavigate();

  const handleClick = (e, el) => {
    setIsClicked(true);
    setPartner(el);
    navigate(el?.id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { money_debt } = e.target.elements;

    const newDebt = {
      user_id: userId,
      given_by_whom: "WAREHOUSE",
      partner_id: partner?.id,
      _money_: +money_debt.value,
    };

    if (money_debt.value) {
      setIsLoading(true);
      axios
        .post(baseUrl + "/partner/create-debt", newDebt, {
          method: "POST",
          headers: {
            Authorization: `mme:${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data?.data?.id) {
            setIsLoading(false);
            showAlert(
              "Muvafaqqiyatli!",
              "Qarz muvafaqqiyatli qo'shildi!",
              "success"
            );
            refetch();
            money_debt.value = null;
            setIsClicked(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const handleModalClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked(false);
    } else {
      setIsClicked(true);
    }
  };

  let totalUsd = partners
    .filter((el) => el.selected_currency === "USD")
    .reduce((acc, curr) => acc + curr?._money_, 0);

  let totalUzs = partners
    .filter((el) => el.selected_currency === "UZS")
    .reduce((acc, curr) => acc + curr?._money_, 0);

  return (
    <>
      <div className="relative">
        <Header
          icon={true}
          heading={t("pages.partnersList")}
          navPath={role?.status === 1 ? "/statistics" : "/report"}
        ></Header>
        <img
          className="absolute top-1/2 -translate-y-1/2 right-9 w-6 h-6"
          src={AddSquare}
          alt="Just an icon"
          onClick={() => navigate("/addPartner")}
        />
      </div>
      <div className="bg-[#F8F5F5] py-6 pb-0 px-4">
        <div className="border border-[#E6E6EA] p-4 bg-white rounded-main text-textDark font-medium flex flex-col gap-y-3">
          <p>Barcha hamkorlar: {partners?.length}</p>
          <p>
            Umumiy <span className="uppercase text-mainText">usd</span>:{" "}
            <span className="ml-2">{totalUsd}</span>
          </p>
          <p>
            Umumiy <span className="uppercase text-mainText">uzs</span>:{" "}
            <span className="ml-2">{totalUzs}</span>
          </p>
        </div>
      </div>
      <ul className="bg-[#F8F5F5] px-4 flex flex-col gap-y-5 pt-5 h-full pb-6">
        {partners?.length > 0 &&
          partners.map((el, idx) => (
            <li
              className="w-full py-2.5 px-3 flex items-center rounded-main bg-white"
              key={el?.id}
              style={{ boxShadow: "0px 3px 15px rgba(13, 14, 43, 0.05)" }}
              onClick={(e) => handleClick(e, el)}
            >
              <span
                className="w-16 h-16 rounded-full flex justify-center items-center mr-3.5"
                style={{ boxShadow: "0px 3px 10px rgba(13, 14, 43, 0.11)" }}
              >
                <img
                  className="w-6 h-7 object-contain"
                  src={Profile}
                  alt="Just an icon"
                />
              </span>
              <div>
                <p>
                  <span className="text-base text-textSilver font-medium mr-2.5">
                    {t("profile.message.name")}:
                  </span>
                  <span className="text-base text-textDark capitalize">
                    {el?.partner_name}
                  </span>
                </p>
                <p>
                  <span className="text-base text-textSilver font-medium mr-2.5">
                    {t("home.debt")}:
                  </span>
                  <span className="text-base text-textDark">
                    {el?._money_}
                    <span className="text-mainText font-medium text-base ml-1.5">
                      {el?.selected_currency}
                    </span>
                  </span>
                </p>
                <p>
                  <span className="text-base text-textSilver font-medium mr-2.5">
                    So'nggi to'lov:
                  </span>
                  <span className="text-base text-textDark">
                    {el?.last_payment_data?.last_given_money}
                    <span className="text-mainText font-medium text-base ml-1.5">
                      {el?.selected_currency}
                    </span>
                  </span>
                </p>
                <p>
                  <span className="text-base text-textSilver font-medium mr-2.5">
                    {t("addNewPartner.date")}:
                  </span>
                  <span className="text-base text-textDark">
                    <Moment format="YYYY/MM/DD">{el?.created_at}</Moment>
                  </span>
                </p>
              </div>
            </li>
          ))}
      </ul>

      <div
        className={`${
          isClicked
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed bottom-0 min-[500px]:left-1/2 left-0 ease-out min-[500px]:-translate-x-1/2 right-0 bg-black bg-opacity-30 max-w-[500px] w-full overscroll-none flex items-end z-50 max-h-screen transition delay-150 duration-300`}
        onClick={handleModalClick}
        id="modal_wrapper"
      >
        <form
          className={`${
            isClicked ? "translate-y-0" : "translate-y-full"
          } px-4 pt-6 pb-12 ease-in-out bg-white w-full transition-all delay-75 duration-300`}
          onSubmit={handleSubmit}
        >
          <h4 className="text-lg text-brendColor text-center mb-7">
            Qarz qo'shish
          </h4>
          <div className="flex justify-between items-center mb-4">
            <p className="text-base font-medium">
              <span className="text-textSilver  mr-1.5">Ism:</span>
              <span className=" text-textDark capitalize">
                {partner?.partner_name}
              </span>
            </p>
            <p className="text-base font-medium">
              <span className="text-textDark mr-1.5">{partner?._money_}</span>
              <span className="text-mainText">
                {partner?.selected_currency}
              </span>
            </p>
          </div>
          <MainInput
            type="number"
            margin={"16px"}
            name={"money_debt"}
          ></MainInput>
          <span className="text-sm font-medium text-textSilver block mb-20">
            Kiritilgan pulga e’tiborli bo’ling!
          </span>
          <MainButton bgColor={"#702DE3"}>Saqlash</MainButton>
        </form>
      </div>
    </>
  );
}
