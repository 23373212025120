import React, { useState } from "react";
import Moment from "react-moment";
import { baseUrl } from "../../baseUrl";
import { Check } from "../../assets/images";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function DressCheckbox({ el, data, setData }) {
  const [isClicked, setIsClicked] = useState(el?.in_stock);

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };

  const handleClick = (el) => {
    if (el?.in_stock) {
      if (!isClicked) {
        setData({
          in_stock: [...data.in_stock],
          out_stock: [...data.out_stock.filter((it) => it !== el?.id)],
        });
      } else {
        setData({
          in_stock: [...data.in_stock],
          out_stock: [...data.out_stock, el?.id],
        });
      }
    } else {
      if (!isClicked) {
        setData({
          in_stock: [...data.in_stock, el?.id],
          out_stock: [...data.out_stock],
        });
      } else {
        setData({
          in_stock: [...data.in_stock.filter((it) => it !== el?.id)],
          out_stock: [...data.out_stock],
        });
      }
    }
  };

  return (
    <li
      className="bg-white p-2 gap-4 rounded-main"
      key={el?.id}
      onClick={() => {
        setIsClicked(!isClicked);
        handleClick(el);
      }}
    >
      <div className="relative">
        <LazyLoadImage
          className="rounded-md h-56 w-full mb-2"
          src={baseUrl + el?.dress_image[0]}
          // width={144}
          height={176}
          alt="Dress pic"
          onError={handleImageError}
        />
        <div className="w-8 h-8 rounded-full flex justify-center items-center absolute top-1 right-1">
          {isClicked ? <img src={Check} alt="" /> : ""}
        </div>
      </div>
      <div className="flex-col flex gap-y-1">
        <p className="font-medium text-lg">{el?.dress_name}</p>
        <p className="text-textSilver">
          <span className="capitalize">{el?.dress_color}</span> -{" "}
          <span>{el?.dress_shleft_size}</span> SM
        </p>
        <Moment className="text-textSilver" format="YYYY/MM/DD">
          {el?.created_at}
        </Moment>
      </div>
    </li>
  );
}
