import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Calender } from "../../assets/images";

export default function DatePickerInput({
  start,
  filter = false,
  end,
  setStart,
  setEnd,
  formattedDate,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          "DatePicker",
          "MobileDatePicker",
          "DesktopDatePicker",
          "StaticDatePicker",
        ]}
      >
        <div className="flex justify-between mb-4">
          <label className="relative">
            <DatePicker
              defaultValue={dayjs("2022-04-17")}
              onChange={(e) => setStart(e.$d)}
              value={dayjs(start) || formattedDate}
            />
            <img
              className={`absolute top-1/2 -translate-y-3/4 right-4 hidden max-[500px]:block ${
                filter ? "brend_svg" : ""
              }`}
              src={Calender}
              alt=""
            />
          </label>
          <label className="relative ml-4">
            <DatePicker
              defaultValue={dayjs("2022-04-17")}
              onChange={(e) => setEnd(e.$d)}
              value={dayjs(end) || formattedDate}
            />
            <img
              className={`absolute top-1/2 -translate-y-3/4 right-4 hidden max-[500px]:block ${
                filter ? "brend_svg" : ""
              }`}
              src={Calender}
              alt=""
            />
          </label>
        </div>
      </DemoContainer>
    </LocalizationProvider>
  );
}
