import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Filter } from "../../assets/images";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import { getDate } from "../../utils/getDate";
import useRole from "../../hooks/useRole";
import { useTranslation } from "react-i18next";

let mockData = [
  { id: 1, title: "Oddiy sotish", path: "/simple-sale/all", type: "simple" },
  { id: 2, title: "50/50 sotish", path: "/sale5050/all", type: "fifty" },
  {
    id: 3,
    title: "Buyurtmalar",
    path: "/dress-order/ready/all",
    type: "orders",
  },
  {
    id: 4,
    title: "Aksessuarlar sotish",
    path: "/accessory/sold",
    type: "accessory",
  },
];

export default function AllSoldList() {
  const navigate = useNavigate();

  const [role] = useRole();

  const { t } = useTranslation();

  const [isClicked, setIsClicked] = useState(false);

  const [page, setPage] = useState(0);

  const [realData, setRealData] = useState([]);

  const titles = [
    { id: 1, title: t("pages.simpleSale") },
    { id: 2, title: t("pages.fiftyFifty") },
    { id: 3, title: t("footer.orders") },
    { id: 4, title: t("pages.accessory") },
  ];

  mockData = mockData.map((el) => {
    el.title = titles.find((it) => it.id === el.id).title;
    return el;
  });

  const [category, setCategory] = useState(mockData[0]);

  const limit = 15;

  const data = useFetch(
    baseUrl + category.path + `?limit=${limit}&offset=${page}`
  );

  useEffect(() => {
    setRealData(Object.entries(data?.data || {}));
  }, [data]);

  const handleModalClick = (e) => {
    if (e.target.id === "modal_wrapper") {
      setIsClicked(false);
    }
  };

  const handleClick = (el, e) => {
    setCategory(el);
    setIsClicked(false);
  };

  const handleOrderClick = (el) => {
    if (category.type === "simple") {
      navigate(`/debtors/simple-sale_${el?.id}_btn`);
    }

    if (category.type === "fifty") {
      navigate(`/debtors/sale5050_${el?.id}_btn`);
    }

    if (category.type === "orders") {
      navigate(`/ordersList/${el?.id}_allSold`);
    }
  };

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/100";
  };
  return (
    <>
      <div
        className="pb-4 px-4 bg-white pt-10 relative"
        style={{ boxShadow: "0px 12px 14px rgba(209, 212, 226, 0.157261)" }}
      >
        <div className="text-center mb-10 relative">
          <span
            className="h-8 w-8 cursor-pointer rounded-full text-lg text-[#354B91]  top-1/2 -translate-y-1/2 left-1 border border-[#354B91] flex justify-center items-center absolute"
            onClick={(e) => {
              navigate(role?.status === 1 ? "/statistics" : "/report");
            }}
          >
            &#10094;
          </span>
          <h1 className="text-xl text-brendColor">
            <span>{t("pages.allSoldList")}</span>
          </h1>

          <img
            className="absolute right-2 top-1.5 cursor-pointer"
            src={Filter}
            alt="Just an icon"
            onClick={() => setIsClicked(!isClicked)}
          />
        </div>
        <p className="text-textDark text-center mt-2 absolute top-1/2 left-1/2 -translate-x-1/2">
          {category?.title}
        </p>
      </div>
      <ul className="bg-[#F8F5F5] px-4 pt-5 flex flex-col gap-y-3.5 pb-7 h-full">
        {realData?.length > 0 ? (
          realData?.map((el, i) => (
            <li key={i}>
              <time className="rounded-main px-3 py-1.5 text-mainText font-medium bg-[#E6E6EA] mb-3.5 block w-max mx-auto">
                {[el[0]]}
              </time>
              <ul className="flex flex-col gap-y-3.5">
                {el[1]?.length > 0 &&
                  el[1].map((item) => (
                    <li
                      className="flex items-center gap-x-3 rounded-main bg-white py-2.5 px-1"
                      style={{
                        boxShadow: "0px 3px 15px rgba(13, 14, 43, 0.05)",
                      }}
                      key={item?.id}
                      onClick={() => handleOrderClick(item)}
                    >
                      <img
                        className="w-20 h-20 rounded-full"
                        src={baseUrl + item?.salon_image}
                        alt="product pic"
                        onError={handleImageError}
                      />
                      <div>
                        {item?.salon_name ? (
                          <p className="font-medium">
                            <span className="text-textSilver">Salon:</span>{" "}
                            <span className="text-textDark">
                              {item?.salon_name}
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {item?.accessory_name ? (
                          <p className="font-medium">
                            <span className="text-textSilver">Aksessuar:</span>{" "}
                            <span className="text-textDark">
                              {item?.accessory_name}
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {item?.accessory_total_in_usd ? (
                          <p className="font-medium">
                            <span className="text-textSilver">
                              Aksessuar narxi (usd):
                            </span>{" "}
                            <span className="text-textDark">
                              {item?.accessory_total_in_usd}
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {item?.bride_name ? (
                          <p className="font-medium">
                            <span className="text-textSilver">Kelin ismi:</span>{" "}
                            <span className="text-textDark">
                              {item?.bride_name}
                            </span>
                          </p>
                        ) : (
                          ""
                        )}
                        <p className="font-medium">
                          <span className="text-textSilver">
                            {t("soldDate")}:
                          </span>{" "}
                          <span className="text-textDark">
                            {getDate(item?.created_at)}
                          </span>
                        </p>
                      </div>
                    </li>
                  ))}
              </ul>
            </li>
          ))
        ) : (
          <p>Hech narsa topilmadi...</p>
        )}
      </ul>

      <div
        className={`${
          isClicked
            ? "top-0 bg-black bg-opacity-20"
            : "top-full bg-white bg-opacity-0"
        } fixed top-0 bottom-0 right-0 left-0 bg-black bg-opacity-30 transition delay-150 duration-300 flex flex-col justify-end`}
        onClick={handleModalClick}
        id="modal_wrapper"
      >
        <ul
          className={`bg-white px-2 flex flex-col py-6 max-w-[500px] w-full mx-auto rounded-t-main ${
            isClicked ? "translate-y-0" : "translate-y-full"
          } transition-all delay-75 ease-in-out`}
        >
          {mockData.map((el) => (
            <li
              className={`${
                el.id === category?.id ? "rounded-main" : ""
              } py-3 border-b border-[#D7E1EA] flex justify-between items-center px-2`}
              key={el?.id}
              style={{
                background:
                  el.id === category?.id ? "rgba(112, 45, 227, 0.1)" : "",
              }}
              onClick={(e) => handleClick(el, e)}
            >
              <p className="text-textDark text-lg">{el?.title}</p>
              <span
                className="w-6 h-6 rounded-full border border-[#AFBACA] flex justify-center items-center"
                style={{
                  borderColor: el?.id === category?.id ? "#702DE3" : "",
                }}
              >
                <span
                  className="block w-4 h-4 rounded-full"
                  style={{
                    backgroundColor: el?.id === category?.id ? "#702DE3" : "",
                  }}
                ></span>
              </span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
