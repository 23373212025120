import React, { useState } from "react";
import Header from "../../components/Header/Header";
import { AddSquare } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { Search } from "../../assets/images";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../baseUrl";
import GipyurCard from "../../components/GipyurCard/GipyurCard";
import { searchData } from "../../utils/SearchData";
import { useTranslation } from "react-i18next";
import useRole from "../../hooks/useRole";

export default function GipyurInfo() {
  const [inputVal, setInputVal] = useState("");

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [role] = useRole();

  const gipyurs = useFetch(baseUrl + "/gipyur/all");

  return (
    <div className="flex flex-col h-screen">
      <div className="relative">
        <Header
          heading={t("pages.gipyurInfos")}
          icon={true}
          navPath="/report"
        ></Header>
        {role?.status === 4 ? (
          <img
            className="absolute top-1/2 -translate-y-1/2 right-9 w-6 h-6"
            src={AddSquare}
            alt="Just an icon"
            onClick={() => navigate("/addGipyur")}
          />
        ) : (
          ""
        )}
      </div>
      <div className="flex-grow bg-[#F8F5F5] px-4 pt-6 pb-7">
        <div className="relative mb-6">
          <input
            className="w-full text-lg py-3 rounded-main pl-12 outline-none border border-[#E6E6EA] text-[#72809D]"
            type="text"
            placeholder={t("gipyurInfo.search")}
            onChange={(e) => setInputVal(e.target.value)}
          />
          <img
            className="absolute top-1/2 -translate-y-1/2 left-4"
            src={Search}
            alt="Just an icon"
          />
        </div>
        <ul className="grid grid-cols-2 gap-4">
          {gipyurs?.length > 0 &&
            searchData(
              inputVal,
              gipyurs.map((el) => {
                el.title = el.gipyur_code;
                return el;
              })
            ).map((el) => (
              <GipyurCard
                key={el?.id}
                id={el?.id}
                code={el?.gipyur_code}
                color={el?.gipyur_color}
                inShop={el?.in_shop}
                metr={el?.gipyur_metr}
                image={el?.gipyur_image}
              ></GipyurCard>
            ))}
        </ul>
      </div>
    </div>
  );
}
